import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import { Stepper, Step } from "react-form-stepper";
import Sidebar from "../common/Sidebar";

import { Tooltip } from "antd";

function Createnewbook() {
  const [validated, setValidated] = useState(false);
  const text = <span>managing collaborators</span>;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}>
              <h2>Create New Book</h2>
              <p>Create a book & start adding chapters and characters</p>
            </div>
            <div className={createnewbookStyle.stepper_section}>
              <Stepper activeStep={1} disabledColor={"#cccccc"}>
                <Step
                  label="Book Details"
                  className={createnewbookStyle.step_active}
                />
                <Step
                  label="Main Characters"
                  className={createnewbookStyle.step_common}
                />
                <Step
                  label="Setting"
                  className={createnewbookStyle.step_common}
                />
                <Step
                  label="Plot Summary"
                  className={createnewbookStyle.step_common}
                />
                <Step
                  label="Writing Preferences"
                  className={createnewbookStyle.step_common}
                />
              </Stepper>
            </div>
            <div className={createnewbookStyle.book_details_from_section}>
              <h2>Book Details</h2>
              <div className={createnewbookStyle.book_details_from}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row
                    className={`mb-12 ${createnewbookStyle.book_details_fild}`}
                  >
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Label>Book title</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter your book title"
                        defaultValue="Enter your book title"
                      />
                    </Form.Group>
                  </Row>
                  <Row
                    className={`mb-12 ${createnewbookStyle.book_details_select}`}
                  >
                    <Col md="6">
                      <Form.Label>Genre</Form.Label>
                      <Form.Select aria-label="Fiction">
                        <option>Fiction</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </Col>
                    <Col md="6">
                      <Form.Label>Target audience</Form.Label>
                      <Form.Select aria-label="Children">
                        <option>Children</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Form.Group
                    className={`mb-12 ${createnewbookStyle.book_details_introductions}`}
                  >
                    <Form.Check
                      required
                      label="Do you need chapter introductions ?"
                      feedbackType="invalid"
                    />
                  </Form.Group>
                  <Form.Group
                    className={`mb-12 ${createnewbookStyle.book_details_introductions}`}
                  >
                    <Form.Check
                      required
                      label="Do you need chapter conclusions with CTA?"
                      feedbackType="invalid"
                    />
                  </Form.Group>
                  <div className={createnewbookStyle.next_cancel_btns}>
                    <Button
                      type="submit"
                      className={createnewbookStyle.next_btn}
                    >
                      Next
                    </Button>
                    <Button
                      type="submit"
                      className={createnewbookStyle.cancel_btn}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Createnewbook;
