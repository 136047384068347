import React, { useEffect, useState } from "react";
import useApi from "../../hook/useApi";
import { useDispatch, useSelector } from "react-redux";
import {
  setLatestBook,
  setSelectedBook,
} from "../../redux/slice/BookListSlice";
import dummyImage from "../../assets/images/dummyImage.png";
import { setviewbooks } from "../../redux/slice/viewbooksSlice";
import { useNavigate } from "react-router-dom";

const Latestebooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { data, error, loading, fetchData } = useApi();
  const latestBooks = useSelector((state) => state?.latestbook?.data?.data);
  const selectedBook = useSelector((state) => state?.latestbook?.selectedBook);
  const [imageError, setImageError] = useState(false); 
 console.log(selectedBook , "selected books home page")
  useEffect(() => {
    fetchData("GET", "api/book-list?page=1&limit=6", "", {
      "content-type": "application/json; charset=utf-8",
    }, setLatestBook);
  }, []);


  const handleImageError = (e) => {
    e.target.src = dummyImage;
    setImageError(true);
  };

  const handleviewbook = async (book, e) => {
    
    e.preventDefault();
    try {
      const bookID = book._id;
      console.log(bookID, "bookID");
      const bookidresponse = await fetchData("GET", `api/book/${bookID}`);
      dispatch(
        setviewbooks({ data: bookidresponse, loading: false, error: null })
      );
      
      navigate(`/viewmorepage/${book._id}`)
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="latestebooks">
      <div className="common-container">
        <div className="latestebook-section">
          <h2>Latest eBooks</h2>
          <div className="latestebook-product">
            {Array.isArray(latestBooks) &&
              latestBooks?.map((book, index) => (
                <div className="latestebook-pro" key={index}>
                  <div className="latestebook-pro-text">
                    <div className="latestebook-img" style={{ width: "200px" }}>
                      <img
                        style={{ borderRadius: "5px", width: "100%" , height:"256px" }}
                        src={book.BookCoverImage || dummyImage}
                        alt="Image"
                        onError={handleImageError}
                      />
                    </div>
                    <h2>
                      <a href="/">{book.title}</a>
                    </h2>
                    <p className="book-description">{book.description}</p>
                    <a
                      className="view-more-btn"
                      href="/"
                      onClick={(e) => handleviewbook(book, e)}
                    >
                      View more
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default Latestebooks;
