import React from "react";
import { useAuth } from "./Auth";
import {Navigate} from 'react-router-dom'

function RequiredAuth({children}){
   const auth = useAuth()
  let user = JSON.parse(localStorage.getItem("userDetails")|| "{}")

    if(auth?.user?.token || user?.token){
        return children
       
    }
    return <Navigate to= "/login" />
}

export default RequiredAuth