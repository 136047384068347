// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aioptions_cards__wc8BC{
    display: flex;
    margin-top: 20px;
    gap: 40px;
}
.aioptions_buttonsdiv__gaiuo{
    margin-top: 30px;
    margin-left: 23px;
   
}
.aioptions_nextbutton__kpHqC{
    width: 89.32px;
height: 44.18px;
border: none;
border-radius: 6px;
background: #F15C5C;
color: whitesmoke;
font-family: "CircularStd";
font-size: 16px;
font-weight: 450;
}
.aioptions_canclebutton__UMH8L{
    width: 89.32px;
height: 44.18px;
font-family: "CircularStd";
font-size: 16px;
font-weight: 450;
margin-left: 10px;
color: #000000;
background-color: whitesmoke;
border-radius: 6px;
border: none;
}
.aioptions_uldiv__NSKXd{
    margin-top: 30px;
}
.aioptions_ul__h7Z-r{
font-family: "CircularStd";
font-size: 16px;
font-weight: 450;
color: #868686;
}
.aioptions_imageparadiv__irRZw{
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    margin-top: 15px;
    gap: 10px;
}
.aioptions_imagecard__RxOwf{
    width: 67.2px;
    height: 60px;
}
.aioptions_paragraph__1WOTo{
    width: 172px;
    height: 11px;
    font-family: "CircularStd";
    font-weight: 500;
    font-size: 16px;
    color: #393939;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/aioptions.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,SAAS;AACb;AACA;IACI,gBAAgB;IAChB,iBAAiB;;AAErB;AACA;IACI,cAAc;AAClB,eAAe;AACf,YAAY;AACZ,kBAAkB;AAClB,mBAAmB;AACnB,iBAAiB;AACjB,0BAA0B;AAC1B,eAAe;AACf,gBAAgB;AAChB;AACA;IACI,cAAc;AAClB,eAAe;AACf,0BAA0B;AAC1B,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,cAAc;AACd,4BAA4B;AAC5B,kBAAkB;AAClB,YAAY;AACZ;AACA;IACI,gBAAgB;AACpB;AACA;AACA,0BAA0B;AAC1B,eAAe;AACf,gBAAgB;AAChB,cAAc;AACd;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;IAChB,SAAS;AACb;AACA;IACI,aAAa;IACb,YAAY;AAChB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".cards{\n    display: flex;\n    margin-top: 20px;\n    gap: 40px;\n}\n.buttonsdiv{\n    margin-top: 30px;\n    margin-left: 23px;\n   \n}\n.nextbutton{\n    width: 89.32px;\nheight: 44.18px;\nborder: none;\nborder-radius: 6px;\nbackground: #F15C5C;\ncolor: whitesmoke;\nfont-family: \"CircularStd\";\nfont-size: 16px;\nfont-weight: 450;\n}\n.canclebutton{\n    width: 89.32px;\nheight: 44.18px;\nfont-family: \"CircularStd\";\nfont-size: 16px;\nfont-weight: 450;\nmargin-left: 10px;\ncolor: #000000;\nbackground-color: whitesmoke;\nborder-radius: 6px;\nborder: none;\n}\n.uldiv{\n    margin-top: 30px;\n}\n.ul{\nfont-family: \"CircularStd\";\nfont-size: 16px;\nfont-weight: 450;\ncolor: #868686;\n}\n.imageparadiv{\n    display: flex;\n    flex-direction: column;\n    margin-left: 60px;\n    margin-top: 15px;\n    gap: 10px;\n}\n.imagecard{\n    width: 67.2px;\n    height: 60px;\n}\n.paragraph{\n    width: 172px;\n    height: 11px;\n    font-family: \"CircularStd\";\n    font-weight: 500;\n    font-size: 16px;\n    color: #393939;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cards": `aioptions_cards__wc8BC`,
	"buttonsdiv": `aioptions_buttonsdiv__gaiuo`,
	"nextbutton": `aioptions_nextbutton__kpHqC`,
	"canclebutton": `aioptions_canclebutton__UMH8L`,
	"uldiv": `aioptions_uldiv__NSKXd`,
	"ul": `aioptions_ul__h7Z-r`,
	"imageparadiv": `aioptions_imageparadiv__irRZw`,
	"imagecard": `aioptions_imagecard__RxOwf`,
	"paragraph": `aioptions_paragraph__1WOTo`
};
export default ___CSS_LOADER_EXPORT___;
