// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chapterformat_changelayout__lx0Yk{
    font-size: 17px;
    font-weight: 500;
    
}
.chapterformat_dropdown__oCa3R{
    font-size: 17px;
    font-weight: 500;
   position: relative;
   right: 17px;
}
.chapterformat_previewbutton__fqegF{
    background-color: rgb(99, 209, 99);
    border-radius: 5rem;
    border-color:  rgb(121, 230, 121);

}
.chapterformat_card__wFgyH{
    position: relative;
}
.chapterformat_icon__lweTl{
    position: absolute;
    bottom: 2px;
    right: 24px;
    font-size: 30px;
    visibility: hidden;
    transition: visibility 02s;
}
.chapterformat_card__wFgyH:hover .chapterformat_icon__lweTl{
    visibility: visible;
}
.chapterformat_icontwo__S4UqH{
    position: absolute;
    bottom: 2px;
    right: 24px;
    font-size: 30px;
    visibility: visible;
    transition: visibility 02s;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/chapterformat.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;;AAEpB;AACA;IACI,eAAe;IACf,gBAAgB;GACjB,kBAAkB;GAClB,WAAW;AACd;AACA;IACI,kCAAkC;IAClC,mBAAmB;IACnB,iCAAiC;;AAErC;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,0BAA0B;AAC9B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,0BAA0B;AAC9B","sourcesContent":[".changelayout{\n    font-size: 17px;\n    font-weight: 500;\n    \n}\n.dropdown{\n    font-size: 17px;\n    font-weight: 500;\n   position: relative;\n   right: 17px;\n}\n.previewbutton{\n    background-color: rgb(99, 209, 99);\n    border-radius: 5rem;\n    border-color:  rgb(121, 230, 121);\n\n}\n.card{\n    position: relative;\n}\n.icon{\n    position: absolute;\n    bottom: 2px;\n    right: 24px;\n    font-size: 30px;\n    visibility: hidden;\n    transition: visibility 02s;\n}\n.card:hover .icon{\n    visibility: visible;\n}\n.icontwo{\n    position: absolute;\n    bottom: 2px;\n    right: 24px;\n    font-size: 30px;\n    visibility: visible;\n    transition: visibility 02s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"changelayout": `chapterformat_changelayout__lx0Yk`,
	"dropdown": `chapterformat_dropdown__oCa3R`,
	"previewbutton": `chapterformat_previewbutton__fqegF`,
	"card": `chapterformat_card__wFgyH`,
	"icon": `chapterformat_icon__lweTl`,
	"icontwo": `chapterformat_icontwo__S4UqH`
};
export default ___CSS_LOADER_EXPORT___;
