import { createSlice } from '@reduxjs/toolkit';


const initialState = {
 ids:null,
  loading: false,
  error: null,
};


export const idSlice = createSlice({
  name: 'id',
  initialState,
  reducers: {
setId: (state, action) => {
      state.ids = action.payload?.ids;
      state.loading = action.payload?.loading;
      state.error =  action.payload?.error;
    },
  },
});


export const { setId } = idSlice.actions;

export default idSlice.reducer;
