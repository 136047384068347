// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editbookfont{
    font-size: 40px;
    font-family:  'Circular Std', sans-serif;
    position: relative;
    font-weight: 450;
    line-height: 20px;
    color: #000000;
   left: 420px;
   top: 30px;
}
.bookuis{
    position: relative;
    left: 420px;
    bottom: 350px;
}
.lablename{
    font-size: 20px;
    font-family:  'Circular Std', sans-serif;
    font-weight: 450;
    line-height: 20px;
    color: #000000;
}
.input{
    border-radius: 10px;
    width: 530px;
    height: 45px;
    background-color: #ffffff;
    cursor: pointer;
    font-family:  'Circular Std', sans-serif;
    color: #000000;
    margin-top: 10px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.button{
    position: relative;
    left: 420px;
    bottom: 160px;
    width: 130px;
    height: 35px;
    border-radius: 10px;
    font-family:  'Circular Std', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/editbookpage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,wCAAwC;IACxC,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;GACf,WAAW;GACX,SAAS;AACZ;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;AACjB;AACA;IACI,eAAe;IACf,wCAAwC;IACxC,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,wCAAwC;IACxC,cAAc;IACd,gBAAgB;IAChB,2DAA2D;AAC/D;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,wCAAwC;AAC5C","sourcesContent":[".editbookfont{\n    font-size: 40px;\n    font-family:  'Circular Std', sans-serif;\n    position: relative;\n    font-weight: 450;\n    line-height: 20px;\n    color: #000000;\n   left: 420px;\n   top: 30px;\n}\n.bookuis{\n    position: relative;\n    left: 420px;\n    bottom: 350px;\n}\n.lablename{\n    font-size: 20px;\n    font-family:  'Circular Std', sans-serif;\n    font-weight: 450;\n    line-height: 20px;\n    color: #000000;\n}\n.input{\n    border-radius: 10px;\n    width: 530px;\n    height: 45px;\n    background-color: #ffffff;\n    cursor: pointer;\n    font-family:  'Circular Std', sans-serif;\n    color: #000000;\n    margin-top: 10px;\n    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */\n}\n\n.button{\n    position: relative;\n    left: 420px;\n    bottom: 160px;\n    width: 130px;\n    height: 35px;\n    border-radius: 10px;\n    font-family:  'Circular Std', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
