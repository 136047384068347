import { createSlice } from '@reduxjs/toolkit';

 const initialState = {
    data: [],
    error : null,
    loading : false
}

export const viewbooksSlice = createSlice({
    name : "viewbooks",
    initialState,
    reducers : {
        setviewbooks : (state , action)=>{
            state.data = action.payload?.data;
            state.error = action.payload?.error;
            state.loading = action.payload?.loading

        }
    }
})
export default viewbooksSlice.reducer
export const {setviewbooks} = viewbooksSlice.actions