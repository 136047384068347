import React, { useState } from "react";
import Sidebar from "../common/Sidebar";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import aioptions from "../../assets/css/aioptions.module.css";
import CommonCard from "../common/CommonCard";
import createbookwithcopilot from "../../assets/css/createBookWithCopilot.module.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import useApi from "../../hook/useApi";
import { setAititleData } from "../../redux/slice/AititleData";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

function CreateBookWithCopilot() {
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [error, setError] = useState();
  const params = useParams();
  const dispatch = useDispatch();
  const { fetchData } = useApi();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({
    own_title_subtitle: false,
    Ai_title_subtitile: false,
  });
  const handlechange = (e) => {
    setText(e.target.value);
  };
  // const handlenext = async()=>{
  //   if(options?.Ai_title_subtitile){
  //     if (!text.trim()) {
  //       setError("Required");
  //       return;
  //     } else {
  //       setError("");
  //     }
  //   }

  //     if(options?. own_title_subtitle){
  //       navigate(`/owncreatetitle`)
  //     }else if( options?. Ai_title_subtitile){
  //       const response = await fetchData("POST" , `api/generate-suggestions`,
  //         {
  //             text :text
  //         }, null , null , ()=>{}, 'POST'
  //       )
  //       console.log(response?.data , "response")
  //       dispatch(setAititleData({data :response?.data , error:null , loading :false}))
  //       navigate(`/addbookdetails`)
  //     }else{
  //       toast.error("Please select one option");
  //     }
  // }
  const handlenext = async () => {
    if (options?.Ai_title_subtitile) {
      if (!text.trim()) {
        setError("Required");
        return;
      } else {
        setError("");
      }
    }

    // Set loading to true before starting the async operation
    setLoading(true);

    try {
      if (options?.own_title_subtitle) {
        navigate(`/owncreatetitle`);
      } else if (options?.Ai_title_subtitile) {
        const response = await fetchData(
          "POST",
          `api/generate-suggestions`,
          {
            text: text,
          },
          null,
          null,
          () => {},
          "POST"
        );
        console.log(response?.data, "response");
        dispatch(
          setAititleData({ data: response?.data, error: null, loading: false })
        );
        navigate(`/addbookdetails`);
      } else {
        toast.error("Please select one option");
      }
    } catch (error) {
      // Handle any errors if needed
      console.error("Error:", error);
    } finally {
      // Set loading to false once the async operation is completed
      setLoading(false);
    }
  };

  const handlecancle = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}>
              <h2>Create Book With Co-pilot</h2>
              <p>
                How would you like to create the title and subtitle for your
                book? Please choose one of the following options:
              </p>
            </div>
            <div className={createbookwithcopilot.maintwoinputfieldsdiv}>
              <CommonCard
                isradioshow={true}
                width="755px"
                height="98px"
                active={options?.own_title_subtitle}
                name="own_title_subtitle"
                callBack={(e, val) => {
                  setOptions({
                    own_title_subtitle: true,
                    Ai_title_subtitile: false,
                  });
                }}
              >
                <div className={createbookwithcopilot.bothtitlediv}>
                  <h5 className={createbookwithcopilot.title}>
                    Enter My Own Book Title and Subtitle
                  </h5>
                  <p className={createbookwithcopilot.subtitle}>
                    "I have specific ideas for the book's title and subtitle.
                    Let me add them."
                  </p>
                </div>
              </CommonCard>
              <CommonCard
                isradioshow={true}
                width="755px"
                height="175px"
                active={options?.Ai_title_subtitile}
                name="Ai_title_subtitile"
                callBack={(e, val) => {
                  setOptions({
                    Ai_title_subtitile: true,
                    own_title_subtitle: false,
                  });
                }}
              >
                <div className={createbookwithcopilot.bothtitledivsecondcard}>
                  <h5 className={createbookwithcopilot.title}>
                    AI-Generated Book Title and Subtitle
                  </h5>
                  <p className={createbookwithcopilot.subtitle}>
                    "I have specific ideas for the book's title and subtitle.
                    Let me add them."
                  </p>
                  <input
                    type="text"
                    className={createbookwithcopilot.inputbox}
                    value={text}
                    onChange={handlechange}
                    placeholder="Enter a brief description or idea about your book..."
                  ></input>
                  {error && (
                    <div className={createbookwithcopilot.error}>{error}</div>
                  )}
                </div>
              </CommonCard>
            </div>
            <div className={aioptions.buttonsdiv}>
              <button
                className={aioptions.nextbutton}
                onClick={() => handlenext()}
              >
                {loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  "Next"
                )}
              </button>

              <button
                className={aioptions.canclebutton}
                onClick={() => handlecancle()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateBookWithCopilot;
