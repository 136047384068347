import React from "react";
import Sidebar from "../common/Sidebar";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import addbookdetails from "../../assets/css/addBookDetailsCopilot.module.css";
import CommonCard from "../common/CommonCard";
import aioptions from "../../assets/css/aioptions.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCreateBook } from "../../redux/slice/createBookSlice";
import { storeChapterDetails } from "../../redux/slice/chapterDetailsSlice";
import { setCreateChapter } from "../../redux/slice/createChapterSlice";
import { toast } from "react-toastify";
import useApi from "../../hook/useApi";

function AddBookDetailsCopilot() {
  const navigate = useNavigate();
  const [active, setActive] = useState();
  const [loading, setLoading] = useState(false);
  const { fetchData } = useApi();
  const dispatch = useDispatch();
  const handlecancle = () => {
    navigate(-1);
  };
  const addbooktitledata = useSelector((state) => state?.AititleData?.data);
  
  const activeindexdata = addbooktitledata[active];
  const createbookwithaidata = useSelector(
    (state) => state?.createnewbookwithai?.data
  );
  const handlechange = (index) => {
    setActive(index);
  };
  const dataapi = { ...activeindexdata, ...createbookwithaidata };
  const handlenext = async (event) => {
    event?.preventDefault();
    try {
      setLoading(true)
      let requestData = dataapi;
       if( !active){
        toast.error("Please select one suggestion");
        return null;
       }
      const response = await fetchData(
        "POST",
        "api/create-book",
        requestData,
        {},
        setCreateBook
      );
      const bookid = response?.data?._id;

      if (bookid) {
        setLoading(false);
        navigate(`/addchaptertitle/${bookid}`);
        dispatch(storeChapterDetails({ data: {} }));
        dispatch(setCreateChapter({ data: {} }));
      }else{
        toast.error("Please select one suggestion");
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
     
    }
  };
 
  
  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}>
              <h2>Add Book Details</h2>
              <p style={{ color: " #5E5E5E" }}>
                Let our AI technology assist you in generating a complete book
                effortlessly. Just provide some initial details, and our AI will
                handle the rest, delivering a ready-to-use book in minutes.
              </p>
            </div>
            <div className={addbookdetails.mainframe}>
              <h4 className={addbookdetails.h4tag}>Book Details</h4>
              {Array.isArray(addbooktitledata) &&
                addbooktitledata?.map((item, index) => (
                  <div className={addbookdetails.cardmapdiv} key={index}>
                    <CommonCard
                      isradioshow={true}
                      width="800px"
                      height="200px"
                      name="index"
                      active={active === index}
                      callBack={() => handlechange(index)}
                    >
                      <div className={addbookdetails.maininputdiv}>
                        <h4 className={addbookdetails.title}>Book title</h4>
                        <input
                          type="text"
                          className={addbookdetails.inputbox}
                          value={item?.title}
                          placeholder='"Whispers of the Forgotten Realm"'
                        ></input>
                        {/* <h4 className={addbookdetails.title} style={{marginTop:"20px"}}>Description</h4>
                    <textarea  className={addbookdetails.inputbox} style={{height:"150px"}} value={item?.description} placeholder='In the hidden corners of our world lies a realm forgotten by time, where ancient secrets and mystical creatures dwell. "Whispers of the Forgotten Realm" follows the journey of Elara, a young scholar who stumbles upon an enchanted artifact that unlocks the gateway to this mysterious land. blur.'></textarea> */}
                        <h4
                          className={addbookdetails.title}
                          style={{ marginTop: "20px" }}
                        >
                          Sub title
                        </h4>
                        <input
                          type="text"
                          className={addbookdetails.inputbox}
                          value={item?.subtitle}
                          placeholder='"Whispers of the Forgotten Realm"  '
                        ></input>
                      </div>
                    </CommonCard>
                  </div>
                ))}

              <div className={aioptions.buttonsdiv}>
                <button
                  className={aioptions.nextbutton}
                  onClick={() => handlenext()}
                >
                   {loading ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              "Next"
            )}
                </button>
                <button
                  className={aioptions.canclebutton}
                  onClick={() => handlecancle()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddBookDetailsCopilot;
