import React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Service from "../components/services/services";

function Services() {
  return (
    <header className="App-header">
      {/* <Header /> */}
      <Service />
      {/* <Footer /> */}
    </header>
  );
}

export default Services;
