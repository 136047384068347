import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import ViewIcon from "../../assets/images/view_icon.png";
import ChangepasswordStyle from "../../assets/css/changepassword.module.css";
import Sidebar from "../common/Sidebar";
import useApi from "../../hook/useApi";
import { useDispatch, useSelector } from "react-redux";

function Changepassword() {
  const [validated, setValidated] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const userid = userData?.user?._id;
  const id = useSelector((state) => state?.login?.users?.user?._id);
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const { fetchData, loading } = useApi();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    const { currentPassword, newPassword, confirmNewPassword } = formData;

    if (newPassword !== confirmNewPassword) {
      console.error("New password and confirm password do not match");
      return;
    }

    try {
      const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");

      const response = await fetchData(
        "PUT",
        `api/update-password/${userid}`,
        formData,
        {
          Authorization: `Bearer ${userData?.token}`,
          "Content-Type": "application/json",
        }
      );

      console.log("Password updated successfully!", response);
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  return (
    <>
      <div className="common-container">
        <div className={ChangepasswordStyle.change_password_page}>
          <Sidebar />
          <div className={ChangepasswordStyle.change_password_right}>
            <div className={ChangepasswordStyle.login_main}>
              <div className={ChangepasswordStyle.login_page}>
                <h2>Change Password</h2>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row
                    className={`mb-12 ${ChangepasswordStyle.contactus_fild}`}
                  >
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Label>Current password*</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Current password"
                        name="currentPassword"
                        value={formData.currentPassword}
                        onChange={handleChange}
                      />
                      <div className={ChangepasswordStyle.email_icon}>
                        <a href="/">
                          <img src={ViewIcon} />
                        </a>
                      </div>
                      <Form.Control.Feedback>
                        Current password is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row
                    className={`mb-12 ${ChangepasswordStyle.contactus_fild}`}
                  >
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Label>New password*</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        placeholder="New password"
                        name="newPassword"
                        value={formData.newPassword}
                        onChange={handleChange}
                      />
                      <div className={ChangepasswordStyle.email_icon}>
                        <a href="/">
                          <img src={ViewIcon} />
                        </a>
                      </div>
                      <Form.Control.Feedback>
                        Current password is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row
                    className={`mb-12 ${ChangepasswordStyle.contactus_fild}`}
                  >
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Label>Confirm new password</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Confirm new password"
                        name="confirmNewPassword"
                        value={formData.confirmNewPassword}
                        onChange={handleChange}
                      />
                      <div className={ChangepasswordStyle.email_icon}>
                        <a href="/">
                          <img src={ViewIcon} />
                        </a>
                      </div>
                      <Form.Control.Feedback>
                        Current password is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <div className={ChangepasswordStyle.login_btn}>
                    <Button type="submit">Submit</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Changepassword;
