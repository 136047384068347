import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'

function AuthLayout({ children }) {
  const location = useLocation();
  const { pathname } = location;
  const excludedPaths =[
    '/createchapter/',
    '/frontmatter',
    '/endmatter',
    '/viewparagraph',
    '/createchapter',
    '/editbookhistory',
    '/frontmatterhistory',
    '/endmatterhistory',
    '/chapterformat'
  ];
 const shouldShowHeaderAndFooter = !excludedPaths.some(path => pathname.startsWith(path));

  return (
    <>
      {shouldShowHeaderAndFooter && <Header />}
      <main>{children}</main>
      {shouldShowHeaderAndFooter && <Footer />}
    </>
  );
}

export default AuthLayout;