import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useApi = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchData = async(
    method,
    endpoint,
    payload = null,
    headers = {
      "Content-Type": "application/json",
    },
    action = null,
    callback = () => {},
    requestType,
    baseUrl = API_BASE_URL
  ) => {
    setLoading(true);
    if (action) {
      dispatch(
        action({
          data: [],
          error: null,
          loading: true,
        })
      );
    }
    setLoading(true);
  try {
      const response = await axios({
        method,
        url: `${baseUrl}/${endpoint}`,
        data: payload,
        headers: headers,
      });
      callback();
      setData(response.data);
      setError(null);
      if (action) {
        dispatch(
          action({
            data: response?.data,
            error: null,
            loading: false,
          })
        );
      }
      if (["POST", "PUT", "DELETE", "PATCH"].includes(requestType)){
        toast.success("Successful");
      }
      return response.data;
    } catch (error) {
      console.log(error, "useApi Error");
      if (action) {
        dispatch(
          action({
            data: [],
            error: error,
            loading: false,
          })
        );
      }
      setError(error);
      if (["POST", "PUT", "DELETE", "PATCH"].includes(requestType)) {
      const errormessage = error?.response ? error?.response?.data?.message : "opps it is an error something!! rong"
      toast.error( errormessage);
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, fetchData };
};

export default useApi;
