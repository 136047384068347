import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Editicon from "../../assets/images/edit_icon.png";
import Deleteicon from "../../assets/images/delete_icon.png";
import Viewsicon from "../../assets/images/views_icon.png";
import Collicon from "../../assets/images/coll_icon.png";
import Exporticon from "../../assets/images/export_icon.png";
import mybookStyle from "../../assets/css/mybook.module.css";
import { Tooltip } from "antd";
import Sidebar from "../common/Sidebar";
import useApi from "../../hook/useApi";
import { setmyBook } from "../../redux/slice/myBookSlice";
import { useDispatch, useSelector } from "react-redux";
import { setClickBook } from "../../redux/slice/bookdetailSlice";
import { useNavigate } from "react-router-dom";
import { setviewbooks } from "../../redux/slice/viewbooksSlice";
import { seteditbook } from "../../redux/slice/editbookSlice";
import dummyImage from "../../assets/images/dummyImage.png";
import coverbooknew from "../../assets/images/newimage.png"

function Mybook() {
  const [bookToDelete, setBookToDelete] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.myBookSlice?.user?.books);
  const userDetailsdata = JSON.parse(
    localStorage.getItem("userDetails") || "{}"
  );
  const userID = userDetailsdata?.user?._id;
  const { fetchData } = useApi();
  const navigate = useNavigate();
  const handleShowDeleteModal = (book) => {
    setBookToDelete(book._id);
    setShowDeleteModal(true);
  };
  const handleEdit = (book) => {
    navigate(`/editbookpage`);
    dispatch(seteditbook(book));
  };
  const handleDelete = (bookId) => {
    const deleteBooks = data?.filter((book) => book._id !== bookId);
    fetchData(
      "DELETE",
      `api/delete-book`,
      { ids: [bookId] },
      null,
      null,
      () => {
        console.log("delete");
      },
      "DELETE"
    );
    dispatch(
      setmyBook({ data: { books: deleteBooks }, loading: false, error: null })
    );
    setShowDeleteModal(false);
  };
  const handledownload = (book) => {
    dispatch(setClickBook(book));
    navigate("/exportformates");
  };

  useEffect(() => {
    const fetchmyBooks = async () => {
      setLoading(true);
      try {
        const data = await fetchData(
          "GET",
          `api/book-list/${userID}?page=1&limit=6`,
          null,
          {
            Authorization:
              'Bearer "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InVuZGVmaW5lZCB1bmRlZmluZWQiLCJzdWIiOiI2NjBlNzc1ZDQ0MTI0MzA1YWNlMDk2YWEiLCJlbWFpbCI6Im5pZGhpMTIyMzRAZ21haWwuY29tIiwiaWF0IjoxNzEyOTI1MTUyLCJleHAiOjE3MTMwMTE1NTJ9.qJQHSZKVWf0zbGKsxU_Lv0KiySlEMnwibqwVtPU63uI",',
          }
        );
        setLoading(false);
        dispatch(setmyBook({ data, loading: false, error: null }));
      } catch (error) {
        dispatch(setmyBook({ data: [], loading: false, error }));
      }
    };
    fetchmyBooks();
  }, []);

  const handleviewbook = async (book) => {
    try {
      const bookID = book._id;
      const bookidresponse = await fetchData("GET", `api/book/${bookID}`);
      dispatch(
        setviewbooks({ data: bookidresponse, loading: false, error: null })
      );
      navigate(`/viewbooks/${book._id}`);
    } catch (error) {
      console.log(error);
    }
  };
  const handledBookCover = (book)=>{
    const bookID = book._id;
     navigate(`/exampleeditor/${bookID}`)
     console.log(bookID  , "bookcover")
  }
  return (
    <>
      <div className="common-container">
        <div className={mybookStyle.change_password_page}>
          <Sidebar />
          <div className={mybookStyle.change_password_right}>
            <div className={mybookStyle.mybook_top_section}>
              <h2>My Books</h2>
              <a
                href="/createbookoptions"
                className={mybookStyle.add_to_new_book}
              >
                Add new book
              </a>
            </div>
            <div className={mybookStyle.mybook_pro_section}>
              {loading && (
                <div
                  className="text-center"
                  style={{ marginTop: "90px", marginLeft: "300px" }}
                >
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              )}

              {data?.length > 0 ? (
                <>
                  {Array.isArray(data) &&
                    data.map((book, index) => (
                      <div
                        className={mybookStyle.mybook_product}
                        key={book._id}
                      >
                        <div className={mybookStyle.mybook_product_content}>
                          <div className={mybookStyle.mybook_pro_img}>
                            <img
                              style={{ borderRadius: "20px", width: "100%" ,maxHeight: '200px'}}
                              src={book.BookCoverImage || dummyImage}
                              alt={`mybook ${index}`}
                            />
                          </div>
                          <div className={mybookStyle.mybook_pro_text}>
                            <span className={mybookStyle.mybook_text01}>
                              Title:
                            </span>
                            <span className={mybookStyle.mybook_text02}>
                              {book.title}
                            </span>
                          </div>
                          <div className={mybookStyle.mybook_pro_text}>
                            <span className={mybookStyle.mybook_text01}>
                              Genre:
                            </span>
                            <span className={mybookStyle.mybook_text02}>
                              {book.genre}
                            </span>
                          </div>

                          <div className={mybookStyle.mybook_tooltip_btns}>
                            <div className={mybookStyle.edit_icon}>
                              <Tooltip placement="top" title="Edit">
                                <Button onClick={() => handleEdit(book)}>
                                  <img src={Editicon} alt="Edit" />
                                </Button>
                              </Tooltip>
                            </div>
                            <div className={mybookStyle.edit_icon}>
                              <Tooltip placement="top" title="Delete">
                                <Button
                                  variant="primary"
                                  onClick={() => handleShowDeleteModal(book)}
                                >
                                  <img src={Deleteicon} alt="Delete" />
                                </Button>
                              </Tooltip>
                            </div>
                            <div className={mybookStyle.edit_icon}>
                              <Tooltip placement="top" title="Views">
                                <Button
                                  onClick={(e) => handleviewbook(book, e)}
                                >
                                  <img src={Viewsicon} alt="Views" />
                                </Button>
                              </Tooltip>
                            </div>
                            <div className={mybookStyle.edit_icon}>
                              <Tooltip placement="top" title="Collaborators">
                                <Button onClick={()=>navigate("/collaborationpage")}>
                                  <img src={Collicon} alt="Collaborators" />
                                </Button>
                              </Tooltip>
                            </div>
                            <div className={mybookStyle.edit_icon}>
                              <Tooltip placement="top" title="Export">
                                <Button onClick={() => handledownload(book)}>
                                  <img src={Exporticon} alt="Export" />
                                </Button>
                              </Tooltip>
                            </div>
                            <div className={mybookStyle.edit_icon}>
                              <Tooltip placement="top" title="EditBookCover">
                                <Button onClick={() => handledBookCover(book)}>
                                  <img style={{height:"25px" , width:"25px"}} src={coverbooknew} alt="Export" />       
                                
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <h1
                  style={{
                    marginTop: "70px",
                    marginLeft: "190px",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  No Book Found
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <div className={mybookStyle.popup_content_box}>
          <div className={mybookStyle.popup_text_content}>
            Are you sure you want to delete?
          </div>
          <div className={mybookStyle.popup_btns}>
            <Button
              className={mybookStyle.popup_ok_btn}
              variant="secondary"
              onClick={() => handleDelete(bookToDelete)}
            >
              Ok
            </Button>
            <Button
              className={mybookStyle.popup_cancel_btn}
              variant="primary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Mybook;
