import {createSlice} from '@reduxjs/toolkit'

 const initialState = {
    users : [],
    error : null,
    loading : false
}

export const myrecentbookSlice = createSlice({
    name : 'myrecentbook',
    initialState,
    reducers : {
        setmyrecentbook : (state , action)=>{
            state.data = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        }
    }
})

export const {setmyrecentbook} = myrecentbookSlice.actions
export default myrecentbookSlice.reducer