import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
import MybookPro from "../../assets/images/collaboration_pro.png";
import Exporticon from "../../assets/images/review_icon.png";
import mybookStyle from "../../assets/css/mybook.module.css";
import { Tooltip } from "antd";
import Sidebar from "../common/Sidebar";
import useApi from "../../hook/useApi";
import { setcollobratorslist } from "../../redux/slice/collobratorslist";
import { useDispatch, useSelector } from "react-redux";

function Collaborations() {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const { fetchData } = useApi();
  const dispatch = useDispatch();
  const userDetailsdata = JSON.parse(
    localStorage.getItem("userDetails") || "{}"
  );
  const userID = userDetailsdata?.user?._id;
  const text = <span>Review</span>;
  const collobratorslistdata = useSelector(
    (state) => state?.collobratorslist?.data?.data
  );

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false){
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };
  useEffect(() => {
    const fetchcollobrators = async () => {
      setLoading(true);
      try {
        const fetchdata = await fetchData(
          "GET",
          `api/collaborators-list/${userID}`
        );
         setLoading(false);
        dispatch(
          setcollobratorslist({ data: fetchdata, loading: false, error: null })
        );
      } catch (error){
        console.log(error);
      }
    };
    fetchcollobrators();
  }, []);

  return (
    <>
      <div className="common-container">
        <div className={mybookStyle.change_password_page}>
          <Sidebar />
          <div className={mybookStyle.change_password_right}>
            <div className={mybookStyle.mybook_top_section}>
              <h2>Collaborations</h2>
              <NavLink
                to="/sentinvitationspage"
                className={mybookStyle.add_to_new_book}
              >
                Send Invitations
              </NavLink>
            </div>
            <div className={mybookStyle.mybook_pro_section}>
              {collobratorslistdata?.map((item) => {
                return item?.books?.map((book) => {
                  return (
                    <div
                      className={mybookStyle.mybook_product}
                      style={{ marginTop: "20px" }}
                    >
                      <div className={mybookStyle.mybook_product_content}>
                        <div className={mybookStyle.mybook_pro_img}>
                          <img src={MybookPro} />
                        </div>
                        <div className={mybookStyle.mybook_pro_text}>
                          <span className={mybookStyle.mybook_text01}>
                            Name:
                          </span>
                          <span className={mybookStyle.mybook_text02}>
                            {item?.collaborator?.FirstName}
                            {item?.collaborator?.LastName}
                          </span>
                        </div>
                        <div className={mybookStyle.mybook_pro_text}>
                          <span className={mybookStyle.mybook_text01}>
                            Book Title:
                          </span>
                          <span className={mybookStyle.mybook_text02}>
                            {book}
                          </span>
                        </div>

                        <div className={mybookStyle.mybook_tooltip_btns}>
                          <div className={mybookStyle.edit_icon}>
                            <Tooltip placement="top" title={text}>
                              <Button>
                                <img src={Exporticon} />
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                });
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Collaborations;
