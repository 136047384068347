// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services_services_top__MbmgK {
  text-align: center;
  padding: 50px 0 20px;
}
.services_services_top__MbmgK h2 {
  font-family: "CircularStd";
  font-size: 42px;
  color: #000;
  padding: 0;
  margin: 0;
}
.services_services_top__MbmgK p {
  display: block;
  font-family: "CircularStd";
  font-size: 16px;
  font-weight: 500;
  color: #000;
  padding-top: 5px;
  margin: 0;
}
.services_services_content__9nOMN h3 {
  font-family: "CircularStd";
  font-size: 28px;
  color: #000;
  padding: 35px 0 10px;
  margin: 0;
}
.services_services_content__9nOMN p {
  font-family: "CircularStd";
  font-size: 16px;
  color: #000;
  line-height: 32px;
  padding: 10px 0;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .services_services_top__MbmgK h2 {
    font-size: 32px;
  }
  .services_services_content__9nOMN h3 {
    font-size: 22px;
    padding-top: 20px;
  }
  .services_services_content__9nOMN p {
    font-size: 15px;
    line-height: 28px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/services.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;AACA;EACE,0BAA0B;EAC1B,eAAe;EACf,WAAW;EACX,UAAU;EACV,SAAS;AACX;AACA;EACE,cAAc;EACd,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,SAAS;AACX;AACA;EACE,0BAA0B;EAC1B,eAAe;EACf,WAAW;EACX,oBAAoB;EACpB,SAAS;AACX;AACA;EACE,0BAA0B;EAC1B,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,eAAe;EACf,SAAS;AACX;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".services_top {\n  text-align: center;\n  padding: 50px 0 20px;\n}\n.services_top h2 {\n  font-family: \"CircularStd\";\n  font-size: 42px;\n  color: #000;\n  padding: 0;\n  margin: 0;\n}\n.services_top p {\n  display: block;\n  font-family: \"CircularStd\";\n  font-size: 16px;\n  font-weight: 500;\n  color: #000;\n  padding-top: 5px;\n  margin: 0;\n}\n.services_content h3 {\n  font-family: \"CircularStd\";\n  font-size: 28px;\n  color: #000;\n  padding: 35px 0 10px;\n  margin: 0;\n}\n.services_content p {\n  font-family: \"CircularStd\";\n  font-size: 16px;\n  color: #000;\n  line-height: 32px;\n  padding: 10px 0;\n  margin: 0;\n}\n\n@media screen and (max-width: 767px) {\n  .services_top h2 {\n    font-size: 32px;\n  }\n  .services_content h3 {\n    font-size: 22px;\n    padding-top: 20px;\n  }\n  .services_content p {\n    font-size: 15px;\n    line-height: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"services_top": `services_services_top__MbmgK`,
	"services_content": `services_services_content__9nOMN`
};
export default ___CSS_LOADER_EXPORT___;
