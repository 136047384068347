
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, FacebookAuthProvider,GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDbhFeiDzB5EdeQxtH2XyjP4TfCeQkrYqE",
  authDomain: "ebook-b0b10.firebaseapp.com",
  projectId: "ebook-b0b10",
  storageBucket: "ebook-b0b10.appspot.com",
  messagingSenderId: "176591244409",
  appId: "1:176591244409:web:c01f034a720806290918a8",
  measurementId: "G-B12FVENLG1"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
export { auth, googleProvider, facebookProvider};