import React, { useState } from "react";
import Sidebar from "../common/Sidebar";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import Form from "react-bootstrap/Form";
import Text from "../../assets/images/text.png";
import { PiWarningCircleBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import useApi from "../../hook/useApi";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { setAisuggetion } from "../../redux/slice/AiSuggetionSlice";
import design from "../../assets/images/design.png";
import Spinner from "react-bootstrap/Spinner";

function AutoPilot(){
  const validationSchema = Yup.object().shape({description: Yup.string().required("Description is required")});
  const [formData, setformData] = useState({ description: '' });
  const [errors, setErrors] = useState({});
  const { fetchData } = useApi();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
 const handleAI = async (event) =>{
    event.preventDefault();
    setValidated(true);
    setLoading(true);
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setValidated(true);
      const response = await fetchData(
        "POST",
        `api/generate-suggestions`,
        { text: formData },
        {},
        setAisuggetion
      );
      setformData(response?.data);
      toast.success(`Successfully generated suggestions`);
      navigate(`/autopilotone`);
    } catch (error){
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
        error.inner.forEach((e) =>{
          toast.error(e.message);
        });
        setValidated(false);
        setLoading(false);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const handleImproveText = async (e) => {
    e.preventDefault();
    setLoading(true); 
    try {
      const response = await fetchData("POST", `api/update-to-lowercase`, {
        paragraphDetail: formData.description,
      });
      setformData({ description: response?.data });
    } finally {
      setLoading(false); 
    }
  };

  const handleback = () =>{
    navigate(`/aioptions`);
  };

  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div>
              <h2>Create Book With AutoPilot</h2>
              <p>
                Let our AI technology assist you a generating a new book
                effortlessly. Just provide some initial details and AI will
                handle the rest, delivering a ready to use book in minutes.
              </p>
            </div>
            <div className={createnewbookStyle.autopilottextarea}>
              <h5>Book Idea</h5>
              <div className={createnewbookStyle.autopilot_textarea}>
                <Form onSubmit={handleAI}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      name="description"
                      placeholder=" Describe your book idea here, this will help our AI generate relevant 
                      content for your book"
                      value={formData.description}
                      onChange={(e) =>
                        setformData({
                          ...formData,
                          description: e.target.value,
                        })
                      }
                      style={{ border: "0px", height: "290px" }}
                      as="textarea"
                      isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <button
                    onClick={handleImproveText}
                    style={{
                      position: "absolute",
                      top: "344px",
                      right: "72px",
                      color: "#4e4e8b",
                    }}
                    className={createnewbookStyle.autopilot_button_cancel}
                    disabled={loading} 
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <>
                        <img src={design} alt="Improve with AI" /> Improve with
                        AI
                      </>
                    )}
                  </button>
                </Form>
              </div>
              <div className={createnewbookStyle.notification}>
                <p style={{ display: "flex" }}>
                  <span style={{ fontSize: "x-large" }}>
                    <PiWarningCircleBold />
                  </span>
                  <span style={{ margin: "13px 0px 0px 9px" }}>
                    <img src={Text} alt="Text" />
                  </span>
                </p>
              </div>
              <div style={{ marginTop: "35px" }}>
              <button
                  onClick={handleAI}
                  className={createnewbookStyle.autopilot_button_next}
                  disabled={loading} 
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Next"
                  )}
                </button>
                <button
                  onClick={handleback}
                  className={createnewbookStyle.autopilot_button_cancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AutoPilot;
