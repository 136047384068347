import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Col, Row } from "react-bootstrap";
import HeaderBar from "../Createbooknavbar/Createbooknavbar";
import SideNavBar from "../Createbooksidebar/Createbooksidebar";
import SidebarStyle from "../../assets/css/Sidebar.module.css";
import manUser from "../../assets/images/man-user.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import useApi from "../../hook/useApi";
import { setendmatters } from "../../redux/slice/endmatters";
import { useParams } from "react-router-dom";
import { setstoreendmattersdetails } from "../../redux/slice/storeendmattersdetails";
import Dropdown from "react-bootstrap/Dropdown";
import notificationImage from "../../assets/images/notification-img.png";
import headerStyle from "../../assets/css/header.module.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hook/Auth";
import { toast } from "react-toastify";
export default function EndMatter() {
  const [validated, setValidated] = useState(false);
  const { fetchData } = useApi();
  const navigate = useNavigate();
  const auth = useAuth();
  const userDetailsdata = JSON.parse(
    localStorage.getItem("userDetails") || "{}"
  );
  const authorid = userDetailsdata?.user?._id;

  const FirstName = userDetailsdata?.user?.FirstName;
  const LastName = userDetailsdata?.user?.LastName;
  const params = useParams();
  const bookId = useSelector((state) => state.createbook.data?.data?._id);
  const details = useSelector((state) => state.storeendmattersdetails);
  console.log(details, "detailssafdsdsfdsfdsfdsf");
  const endmattersdata = useSelector((state) => state?.endmatters?.data);
  const BookId = bookId;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [loadingpage, setLoadingPage] = useState(false);

  const id =
    details && details?.data && details?.data?.length > 0
      ? details?.data[0]?._id
      : null;
  useEffect(() => {
    setLoadingPage(true);
    setTimeout(() => {
      setFormData({
        Title: details?.data[0]?.Title || "",
        description: details?.data[0]?.description || "",
      });
      setLoadingPage(false);
    }, 100);
  }, [details]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (id) {
        const putresponse = await fetchData(
          "PUT",
          `api/update/front-back-matter/${id}`,
          {
            ...formData,
            BookId: params?.id,
            authorId: authorid,
            type: "backmatter",
          },
          {
            "Content-Type": "application/json",
          },
          setendmatters,
          () => {},
          "PUT"
        );
      } else {
        const response = await fetchData(
          "POST",
          "api/front-back/matter",
          {
            ...formData,
            type: "backmatter",
            BookId: params?.id,
            authorId: authorid,
          },
          {
            "Content-Type": "application/json",
          },
          setendmatters,
          () => {},
          "POST"
        );

        if (response?.data?._id) {
          let data = [{ ...response?.data }];
          dispatch(
            setstoreendmattersdetails({
              data: data,
              error: null,
              loading: false,
            })
          );
        }
      }
      const bookdata = await fetchData(
        "GET",
        `api/book/${params?.id}`,
        null,
        {},
        null,
        () => {},
        "GET"
      );

      dispatch(
        setendmatters({
          data: bookdata.backmatter,
          loading: false,
          error: null,
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditorChange = (value) => {
    setFormData({
      ...formData,
      description: value,
    });
  };
  useEffect(() => {
    setLoadingPage(true);
    setTimeout(() => {
      if (details && details.data && details.data.length > 0) {
        const firstEndMatter = details.data[0];
        setFormData({
          Title: firstEndMatter?.Title || "",
          description: firstEndMatter?.description || "",
        });
      }
      setLoadingPage(false);
    }, 100);
  }, [details.data]);
  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };
  const handleeditprofile = () => {
    navigate("/editprofilepage");
  };
  const handleeditbookhistory = (e) => {
    if (!params?.endchapterid) {
      toast.error("please select endmatter");
    } else {
      navigate(`/endmatterhistory/${params?.id}/${params?.endchapterid}`);
    }
  };
  const handleformat = () => {
    navigate(`/chapterformat/${params?.id}`);
  };
  return (
    <div>
      <div style={{ display: "flex" }}>
        <SideNavBar />
        <div
          className={SidebarStyle.book_section}
          style={{ marginLeft: "200px" }}
        >
          <div className={SidebarStyle.nav_section}>
            <div className={SidebarStyle.nav_item}>End Matter</div>
            <div
              className="header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <img src={notificationImage} />
              <div
                className="avatar"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#ccc",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <img src={manUser} />
              </div>

              <div className="user-info" style={{ display: "inline-block" }}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    className={headerStyle.welcome}
                    style={{
                      marginRight: "10px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Welcome, {FirstName} {LastName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                      onClick={handleeditprofile}
                    >
                      Edit Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleLogout}
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="/dashboard"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Delete Account
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {loadingpage ? (
            <div>{"Loading..."}</div>
          ) : (
            <>
              <div className={createnewbookStyle.book_details_from}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row
                    className={`mb-12 ${createnewbookStyle.book_details_select}`}
                  >
                    <Col md="10" style={{ marginLeft: "50px" }}>
                      <h1>
                        {details?.data?.map((endmatter, index) => (
                          <div key={index}>{endmatter?.name}</div>
                        ))}
                      </h1>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter your book title"
                        defaultValue="Enter your book title"
                        name="Title"
                        value={formData.Title}
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>
                  <Row
                    className={`mb-12 ${createnewbookStyle.book_details_select}`}
                  >
                    <Col
                      md="10"
                      style={{ marginLeft: "50px", marginTop: "0px" }}
                    >
                      <ReactQuill
                        theme="snow"
                        value={formData.description}
                        onChange={handleEditorChange}
                        style={{
                          height: 200,
                        }}
                      />
                    </Col>
                  </Row>

                  <div
                    style={{ marginBottom: "50px", marginLeft: "50px" }}
                    className={createnewbookStyle.save_draft_btns_section}
                  >
                    <div className={createnewbookStyle.next_cancel_btns}>
                      <Button
                        type="submit"
                        className={createnewbookStyle.finish_btn}
                        disabled={loading}
                        style={{
                          backgroundColor: loading ? "grey" : "#0d6ef#d",
                          cursor: loading ? "not-allowed" : "pointer",
                          color: loading ? "black" : "white",
                          opacity: loading ? 0.5 : 1,
                        }}
                      >
                        {loading ? (
                          <div className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        ) : (
                          "Save Draft"
                        )}
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          handleeditbookhistory(e);
                        }}
                        type="submit"
                        className={createnewbookStyle.finish_btn}
                      >
                        View Edit History
                      </Button>
                      <Button
                        onClick={handleformat}
                        style={{ backgroundColor: "green", color: "white" }}
                        type="submit"
                        className={createnewbookStyle.finish_btn}
                      >
                        Finish
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
