import React, { useState, useEffect } from "react";
import "../assets/css/viewbooks.css";
import Sidebar from "../components/common/Sidebar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import useApi from "../hook/useApi";
import { useDispatch } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
function ViewMorePage() {
  const { fetchData } = useApi();
  const clickedbook = useSelector((state) => state.Viewbooks?.data?.chapters);
const booktitle = useSelector((state)=>state.createbook?.data?.data?.title)
  console.log(clickedbook,booktitle, "clicked book is here");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  
  const [paragraph, setParagraph] = useState([]);
  useEffect(() => {
    const fetchChapterData = async () => {
      try {
        const response = await fetchData(
          "GET",
          `api/chapter-list/${params?.id}?page=1&limit=5`
        );
        console.log("API Response:", response);
        setParagraph(response?.data?.chapters); 
      } catch (error) {
        console.error("Error fetching chapter data:", error);
      }
    };
  
    if (params?.id) {
      fetchChapterData();
    }
  }, []);
  
  useEffect(() => {
    console.log("Updated paragraph:", paragraph);
  }, [paragraph]);

  return (
    <div>
      <div>
        <h3 className="booktitleviewmore">
          Book Title Name :{booktitle}
        </h3>
      </div>

     
      {Array.isArray(paragraph) &&paragraph?.map((chapteritem,index) => {
            console.log(chapteritem?.FileUrl, "clickedboookkssadasdsadsad");
            return (
              <div className="cointainerviewmore">
                <h6 className="chaptername">
                  Chapter Title Name : {chapteritem?.title}
                </h6>
                <div className="chapter-description">
                  <span className="description-field">
                  <strong>  Chapter Description: </strong>            
                    {chapteritem?.Paragraph?.map((item, idx) => (
                <span className="description-text" key={idx}>
                {item.description || ''}
                </span>
              ))}
              </span>
            </div>
                 
                <h6 className="chapterimage">
                  <div>
                  Chapter Image:<img style={{maxWidth:'80%'}} src={chapteritem?.FileUrl} />
                  </div>
                </h6>
              </div>
            );
          })}
    </div>
  );
}

export default ViewMorePage;

