import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import userReducer from './slice/userSlice';
import editReducer from './slice/edituserSlice'
import loginReducer from './slice/loginSlice';
import signupReducer from './slice/signupSlice';
import signupauthSlice from './slice/signupauthSlice';
import booklistReducer from './slice/BookListSlice';
import myBookSliceReducer from './slice/myBookSlice';
import createBookReducer from './slice/createBookSlice';
import createChapterReducer from './slice/createChapterSlice';
import bookdetailSlice from './slice/bookdetailSlice';
import myrecentbookSlice from './slice/myrecentbookSlice';
import viewbooksSlice from './slice/viewbooksSlice';
import chapterDetailsSlice from './slice/chapterDetailsSlice';
import frontmatterReducer from './slice/frontMatterSlice'
import frontDetailSlice from './slice/frontDetailSlice';
import storeendmattersdetails from './slice/storeendmattersdetails';
import editbookSliceReducer from './slice/editbookSlice';
import endmatters from './slice/endmatters';
import idReducer from "./slice/idSlice"
import collobrators from './slice/collobrators';
import collobratorslist from './slice/collobratorslist';
import editbookhistory from './slice/editbookhistorySlice';
import fronthistorySlice from './slice/fronthistorySlice';
import backHistorySlice from './slice/backHistorySlice';
import Createnewbookwithai from './slice/CreatenewbookwithaiSlice';
import AititleData from './slice/AititleData';
import  aisuggetionSlice from './slice/AiSuggetionSlice';
import ChapterFormatslice from './slice/ChapterFormatslice';
const persistConfig = {
  key: 'root', 
  storage,
  whitelist: ['user', 'bookdetail', 'createbook','createchapter','chapterDetails','editbooks'],
};


const rootReducer = combineReducers({
  user: userReducer,
  edituser:editReducer,
  login: loginReducer,
  signup: signupReducer,
  signupauth: signupauthSlice,
  latestbook: booklistReducer,
  myBookSlice: myBookSliceReducer,
  createbook: createBookReducer,
  createchapter: createChapterReducer,
  bookdetail : bookdetailSlice,
  myrecentbook : myrecentbookSlice,
  Viewbooks : viewbooksSlice,
  chapterDetails:chapterDetailsSlice,
  editbooks: editbookSliceReducer,
  frontmatter:frontmatterReducer,
  frontdetails:frontDetailSlice,  
  storeendmattersdetails : storeendmattersdetails,
  endmatters : endmatters,
  ids:idReducer,
  collobrators :collobrators,
  collobratorslist:collobratorslist,
  editbookhistory:editbookhistory,
  fronthistorySlice:fronthistorySlice ,
  backHistorySlice:backHistorySlice,
  createnewbookwithai:Createnewbookwithai,
  aisuggetionSlice:aisuggetionSlice,
  AititleData: AititleData,
  ChapterFormat : ChapterFormatslice
  
});


const persistedRootReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
  reducer: persistedRootReducer,

});


const persistor = persistStore(store);

export { store, persistor };
