import React, {useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ContinueGoogle from "../../assets/images/continue_google.png";
import ContinueFacebook from "../../assets/images/continue_facebook.png";
import EmailIcon from "../../assets/images/email_icon.png";
import ViewIcon from "../../assets/images/view_icon.png";
import LoginStyle from "../../assets/css/LogIn.module.css";
import { NavLink } from "react-router-dom";
import useApi from "../../hook/useApi";
import { setSignup } from "../../redux/slice/signupSlice";
import { useNavigate } from "react-router-dom";
import { setsignupauth } from "../../redux/slice/signupauthSlice";
import { useDispatch } from "react-redux";
function Signup() {
  const [validated, setValidated] = useState(false);
  const [FirstName, setFisrtName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [eyepasswordone, setEyepasswordone] = useState("password");
  const [eyepasswordtwo, setEyepasswordtwo] = useState("password");
  const { fetchData } = useApi();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstnamevalidationmsg, setFirstnamevalidationmsg] = useState("");
  const [lastnamevalidationmsg, setLastnamevalidationmsg] = useState("");
  const [emailValidationMsg, setEmailValidationMsg] = useState("");
  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [confirmPasswordValidationMsg, setConfirmPasswordValidationMsg] = useState("");

  const validateFirstname = (value) => {
    if (value.length === 0) {
      setFirstnamevalidationmsg("First Name is required");
      return false
    } else {
      setFirstnamevalidationmsg("");
      return true
    }
  };
  const validateLastname = (value) => {
    if (value.length === 0) {
      setLastnamevalidationmsg("Last Name is required");
      return false
    } else {
      setLastnamevalidationmsg("");
      return true
    }
  };
  const validateEmail = (value) => {
    if (value.length === 0) {
      setEmailValidationMsg("Email is required");
      return false
    } else {
      setEmailValidationMsg("");
      return true
    }
  };

  const validatePassword = (value) => {
    if (value.length === 0){
      setPasswordValidationMsg("Password is required");
      return false
    } else {
      setPasswordValidationMsg("");
      return true
    }
  };

  const validateConfirmPassword = (value) => {
    if (value.length === 0) {
      setConfirmPasswordValidationMsg("Confirm password is required");
      return false
    } else {
      setConfirmPasswordValidationMsg("");
      return true
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    validateFirstname(FirstName);
    validateLastname(LastName);
    validateEmail(Email);
    validatePassword(Password);
    validateConfirmPassword(ConfirmPassword);
      
    if (
      firstnamevalidationmsg ||
      lastnamevalidationmsg ||
      emailValidationMsg ||
      passwordValidationMsg ||
      confirmPasswordValidationMsg
    ) {
      setValidated(false);
      return; 
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false){
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    const dataToSend = {
      FirstName,
      LastName,
      Email,
      Password,
      ConfirmPassword,
    };
    setLoading(true);
    if( validateFirstname(FirstName) &&
    validateLastname(LastName)&&
    validateEmail(Email)&&
    validatePassword(Password)&&
    validateConfirmPassword(ConfirmPassword)){
    const responce = await fetchData(
      "POST",
      "api/create-user",
      dataToSend,
      {
        "Content-Type": "application/json",
      },
      setSignup,
      () => {
        console.log("signupp");
      },
      "POST"
    )
      .then((res) => {
        let updateUserData = {
          ...res.data,
          user: res?.data?.createduser,
        };
        delete updateUserData.createduser;
        localStorage.setItem("userDetails", JSON.stringify(updateUserData));
        setLoading(false);
        dispatch(setsignupauth());
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    }else{
      setLoading(false)
    }
  };
 
  
  const handlefirstname = (e) => {
    setFisrtName(e.target.value);
    validateFirstname(e.target.value);
  };
  const handlelastname = (e) => {
    setLastName(e.target.value);
    validateLastname(e.target.value);
  };
  const handleemail = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  const handlepassword = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };

  const handleconfirm = (e) => {
    setConfirmPassword(e.target.value);
    validateConfirmPassword(e.target.value);
  };

  const handleeyepassword = () => {
    setEyepasswordone((prevstate) =>
      prevstate === "password" ? "text" : "password"
    );
  };

  const handleeyeconfirm = () => {
    setEyepasswordtwo((prevstate) =>
      prevstate === "password" ? "text" : "password"
    );
  };
  return (
    <>
      <div className={LoginStyle.login_main}>
        <div className={LoginStyle.login_page}>
          <h2>Sign Up</h2>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className={`mb-12 ${LoginStyle.contactus_fild}`}>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>FirstName*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="FirstName"
                  defaultValue="FirstName"
                  value={FirstName}
                  onChange={handlefirstname}
                />
                <div className={LoginStyle.email_icon}>
                  <a href="/">
                    <img src={EmailIcon} />
                  </a>
                </div>
                <Form.Control.Feedback type="invalid">
                  {firstnamevalidationmsg}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className={`mb-12 ${LoginStyle.contactus_fild}`}>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>LastName*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="LastName"
                  defaultValue="LastName"
                  value={LastName}
                  onChange={handlelastname}
                />
                <div className={LoginStyle.email_icon}>
                  <a href="/">
                    <img src={EmailIcon} />
                  </a>
                </div>
                <Form.Control.Feedback type="invalid">
                  {lastnamevalidationmsg}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className={`mb-12 ${LoginStyle.contactus_fild}`}>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="example@email.com"
                  defaultvalue="example@email.com"
                  value={Email}
                  onChange={handleemail}
                />
                <div className={LoginStyle.email_icon}>
                  <a href="/">
                    <img src={EmailIcon} />
                  </a>
                </div>
                <Form.Control.Feedback type="invalid">
                  {emailValidationMsg}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className={`mb-12 ${LoginStyle.contactus_fild}`}>
              <Form.Group as={Col} md="12" controlId="validationCustom04">
                <Form.Label>Password*</Form.Label>
                <Form.Control
                  type={eyepasswordone}
                  placeholder="Enter your password"
                  required
                  value={Password}
                  onChange={handlepassword}
                />
                <div className={LoginStyle.email_icon}>
                  <img src={ViewIcon} onClick={() => handleeyepassword()} />
                </div>
                <Form.Control.Feedback type="invalid">
                  {passwordValidationMsg}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className={`mb-12 ${LoginStyle.contactus_fild}`}>
              <Form.Group as={Col} md="12" controlId="validationCustom04">
                <Form.Label>Confirm password*</Form.Label>
                <Form.Control
                  type={eyepasswordtwo}
                  placeholder="Confirm your password"
                  required
                  value={ConfirmPassword}
                  onChange={handleconfirm}
                />
                <div className={LoginStyle.email_icon}>
                  <img src={ViewIcon} onClick={() => handleeyeconfirm()} />
                </div>
                <Form.Control.Feedback type="invalid">
                  {confirmPasswordValidationMsg}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <div className={LoginStyle.login_btn}>
              <Button
                type="submit"
                disabled={loading}
                style={{
                  backgroundColor: loading ? "grey" : "#1A3D73",
                  cursor: loading ? "not-allowed" : "pointer",
                  // color: loading ? "white" : "black",
                  opacity: loading ? 0.5 : 1,
                }}
              >
                {loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  "Sign Up"
                )}
              </Button>
            </div>
          </Form>
          <div className={LoginStyle.login_or_text}>
            <span>Or</span>
          </div>
          <div className={LoginStyle.continue_google_facebook}>
            <div className={LoginStyle.continue_google}>
              <img src={ContinueGoogle} />
            </div>
            <div className={LoginStyle.continue_facebook}>
              <img src={ContinueFacebook} />
            </div>
          </div>
          <div className={LoginStyle.have_an_account_text}>
            Have an account? <NavLink to="/Login">Log In</NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
