import React from "react";
import Sidebar from "../common/Sidebar";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import aioptions from "../../assets/css/aioptions.module.css";
import CommonCard from "../common/CommonCard";
import createbookwithcopilot from "../../assets/css/createBookWithCopilot.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useApi from "../../hook/useApi";
import { useDispatch, useSelector } from "react-redux";
import { setAititleData } from "../../redux/slice/AititleData";
import { toast } from "react-toastify";
function AddchaptertitleCopilot() {
  const params = useParams();
  const { fetchData } = useApi();
  const dispatch = useDispatch();
  const createbookwithaidata = useSelector((state) => state?.createnewbookwithai?.data );
  const noofchapters = createbookwithaidata?.preferredNumberOfChapters;
  const noofwords = createbookwithaidata?.numberOfWordsPerChapter;
  const [text, setText] = useState("");
  const [error, setError] = useState()
  const navigate = useNavigate();
  const [options, setOptions] = useState({
    own_chapter_title: false,
    ai_chapter_title: false,
  });
  
  const handlechange = (e) => {
    setText(e.target.value);
  };
  const handlenext = async () => {
    if(options?.ai_chapter_title){
      if (text.trim() === '') {
        setError('Required');
        return;
      } else if (text.length > 200) {
        setError('Description cannot exceed 200 characters');
        return;
      }
    }
    
    if (options?.own_chapter_title) {
      navigate(`/createbookwithcopilotone/${params.id}`);
    } else if (options?.ai_chapter_title) {
    
      const response = await fetchData(
        "POST",
        `api/generate-chapter-suggestion`,
        {
          text: text,
          chapterCount: noofchapters,
          wordCount: noofwords,
        },
        null,
        null,
        () => {},
        "POST"
      );
      dispatch(
        setAititleData({ data: response?.data, error: null, loading: false })
      );
      navigate(`/aicreatechaptertitle/${params.id}`);
    }else{
      toast.error("Please select one option");
    }
  };
  const handlecancel = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}>
              <h2>Add Chapter Titles</h2>
              <p style={{ fontSize: "15px" }}>
                How would you prefer to create the titles and subtitles for the
                chapters of your book? Please choose one of the following
                options:
              </p>
            </div>
            <div className={createbookwithcopilot.maintwoinputfieldsdiv}>
              <CommonCard
                isradioshow={true}
                width="755px"
                height="98px"
                active={options?.own_chapter_title}
                name="own_chapter_title"
                callBack={(e, val) => {
                  setOptions({
                    own_chapter_title: true,
                    ai_chapter_title: false,
                  });
                }}
              >
                <div className={createbookwithcopilot.bothtitlediv}>
                  <h5 className={createbookwithcopilot.title}>
                    Enter My Own Chapter Titles
                  </h5>
                  <p className={createbookwithcopilot.subtitle}>
                    "I have specific titles and subtitles in mind for the
                    chapters. Let me add them."
                  </p>
                </div>
              </CommonCard>
              <CommonCard
                isradioshow={true}
                width="755px"
                height="175px"
                active={options?.ai_chapter_title}
                name="ai_chapter_title"
                callBack={(e, val) => {
                  setOptions({
                    own_chapter_title: false,
                    ai_chapter_title: true,
                  });
                }}
              >
                <div className={createbookwithcopilot.bothtitlediv}>
                  <h5 className={createbookwithcopilot.title}>
                    AI-Generated Chapter Titles
                  </h5>
                  <p className={createbookwithcopilot.subtitle}>
                    "I would like the AI to come up with titles and subtitles
                    for the chapters."
                  </p>
                  <input
                    type="text"
                    className={createbookwithcopilot.inputbox}
                    value={text}
                    onChange={handlechange}
                    placeholder="Enter a brief description or idea about your book..."
                  ></input>
                {error && <p className={createbookwithcopilot.error}>{error}</p>}
                </div>
              </CommonCard>
             
            </div>
            <div className={aioptions.buttonsdiv}>
              <button
                className={aioptions.nextbutton}
                onClick={() => handlenext()}
              >
                Next
              </button>
              <button
                className={aioptions.canclebutton}
                onClick={() => handlecancel()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddchaptertitleCopilot;
