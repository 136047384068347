import React, { useState } from "react";
import Sidebar from "../common/Sidebar";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import aioptions from "../../assets/css/aioptions.module.css";
import CommonCard from "../common/CommonCard";
import { useNavigate } from "react-router-dom";
import vector from "../../assets/images/vector.png";
import backtoschool from "../../assets/images/Back To School Outline Cerated Outline (1).png";
import { toast } from "react-toastify";
function CreateBookOptions() {
  const navigate = useNavigate()
  const [bookType, setBookType] = useState({
    Create_book_with_AI: false,
    Create_book_manually: false,
  });
  const handleNextClick = () => {
    if (bookType.Create_book_with_AI) {
      navigate("/createbookwithai");
    } else if (bookType.Create_book_manually) {
      navigate("/createnewbookpage");
    }else{
      toast.error("Please select one option");
    }
  };
  return(
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}>
              <h2>Create New Book</h2>
              <p>Choose how you want to create your book.</p>
            </div>
            <div className={aioptions.cards}>
              <CommonCard
                isradioshow={true}
                height="192px"
                width="220px"
                active={bookType?.Create_book_with_AI}
                callBack={(e, val) => {
                  setBookType({
                    Create_book_with_AI: true,
                    Create_book_manually: false,
                  });
                }}
                name="Create_book_with_AI"
              >
                <div className={aioptions.imageparadiv}>
                  <img src={vector} className={aioptions.imagecard} />
                  <p
                    className={aioptions.paragraph}
                    style={{ position: "relative", right: "33px", top: "5px" }}
                  >
                  Create Book with AI
                  </p>
                </div>
              </CommonCard>

              <CommonCard
                isradioshow={true}
                height="192px"
                width="220px"
                active={bookType?.Create_book_manually}
                callBack={(e, val) => {
                  setBookType({
                    Create_book_with_AI: false,
                    Create_book_manually: true,
                  });
                }}
                name="Create_book_manually"
              >
                <div className={aioptions.imageparadiv}>
                  <img src={backtoschool} className={aioptions.imagecard} />
                  <p
                    className={aioptions.paragraph}
                    style={{ position: "relative", right: "45px", top: "5px" }}
                  >
                  Create Book Manually
                  </p>
                </div>
              </CommonCard>
            </div>

            <div className={aioptions.buttonsdiv}>
            <button className={aioptions.nextbutton} onClick={handleNextClick}>
              Next
            </button>
            {/* <button className={aioptions.canclebutton} onClick={() => navigate(-1)}>
              Cancel
            </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateBookOptions;
