import {createSlice} from '@reduxjs/toolkit'


const initialState = {
    data : [],
    error : null,
    loading : false
}

export const createBookSlice = createSlice({
    name : "createbook",
    initialState,
    reducers :{
        setCreateBook :(state , action) =>{
            state.data = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        }
    }
})

export const {setCreateBook} = createBookSlice.actions
export default createBookSlice.reducer;