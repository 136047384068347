import React from "react";
import "./exportformates.css";
import Sidebar from "../common/Sidebar";
import pdfimage from "../../assets/images/image-2502.png";
import epub from "../../assets/images/epub.png";
import microsoft from "../../assets/images/microsoft.png";
import mobi from "../../assets/images/mobi.png";
import html from "../../assets/images/html.png";
import { useSelector } from "react-redux";
import useApi from "../../hook/useApi";

function ExportFormates() {
  const data = useSelector((state) => state?.bookdetail?.clickBook?._id);
  const downloadPdf = async (format) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    window.open(`${API_BASE_URL}/api/download-book/${data}?format=${format}`);
  };

  return (
    <>
      <div className="font">
        <p className="export_formats">Export Formats</p>
      </div>
      <Sidebar />
      <div className="block-2">
        <button className="rectangle" onClick={() => downloadPdf("pdf")}>
          <span className="pdf">PDF</span>
          <img src={pdfimage} className="pdfimage" alt="PDF" />
        </button>
        <button className="rectangle" onClick={() => downloadPdf("epub")}>
          <span className="pdf">ePub</span>
          <img src={epub} className="pdfimage" alt="ePub" />
        </button>
        <button className="rectangle" onClick={() => downloadPdf("mobi")}>
          <span className="pdf">Mobi</span>
          <img src={mobi} className="pdfimage" alt="Mobi" />
        </button>
        <button className="rectangle" onClick={() => downloadPdf("microsoft")}>
          <span className="pdf microsoft">Microsoft Word</span>
          <img
            src={microsoft}
            className="pdfimage microsoftimage"
            alt="Microsoft Word"
          />
        </button>
        <button className="rectangle" onClick={() => downloadPdf("html")}>
          <span className="pdf">HTML</span>
          <img src={html} className="pdfimage" alt="HTML" />
        </button>
      </div>
    </>
  );
}

export default ExportFormates;
