import { createSlice} from '@reduxjs/toolkit'

const initialState = {
    isloggin : false,
    user : null ,
}

export const signupauthSlice = createSlice({
    name : "signupauth",
    initialState,
    reducers : {
        setsignupauth : (state , action)=>{
             state.isloggin = true;
             state.user = action.payload
        }
    }
})
export const {setsignupauth} = signupauthSlice.actions;
export const selectAuth = (state) => state.signupauth
export default signupauthSlice.reducer