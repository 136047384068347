// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createBookWithCopilot_maintwoinputfieldsdiv__9xXjX{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}
.createBookWithCopilot_bothtitlediv__ZFyHx{
   position: absolute;
   left: 65px;
   bottom: 10px;
}
.createBookWithCopilot_title__BhfGo{
    font-family: "CircularStd";
    font-size: 18px;
    font-weight: 500;
    color: #393939;
}
.createBookWithCopilot_subtitle__1v0vw{
font-family: "CircularStd";
font-size: 18px;
font-weight: 450;
color: #9F9F9F;
}
.createBookWithCopilot_bothtitledivsecondcard__ArzSA{
    position: absolute;
    left: 65px;
    bottom: 25px;
}
.createBookWithCopilot_inputbox__iCsbU{
    width: 665px;
height: 54px;
border-radius: 10px;
border: 1.3px solid #E5E5E5 ;
}
.createBookWithCopilot_inputbox__iCsbU::placeholder {
    color:#868686;
    font-family: "Open Sans", sans-serif; 
    font-size: 16px;
    font-weight: 400;
    padding: 10px;
 }
 .createBookWithCopilot_error__Nnykw{
    color: #e88484;
    font-size: small
 }
 .createBookWithCopilot_input-error__vCmfA {
    border: 1px solid red;
  }
   `, "",{"version":3,"sources":["webpack://./src/assets/css/createBookWithCopilot.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;AACpB;AACA;GACG,kBAAkB;GAClB,UAAU;GACV,YAAY;AACf;AACA;IACI,0BAA0B;IAC1B,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;AACA,0BAA0B;AAC1B,eAAe;AACf,gBAAgB;AAChB,cAAc;AACd;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;AAChB;AACA;IACI,YAAY;AAChB,YAAY;AACZ,mBAAmB;AACnB,4BAA4B;AAC5B;AACA;IACI,aAAa;IACb,oCAAoC;IACpC,eAAe;IACf,gBAAgB;IAChB,aAAa;CAChB;CACA;IACG,cAAc;IACd;CACH;CACA;IACG,qBAAqB;EACvB","sourcesContent":[".maintwoinputfieldsdiv{\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    margin-top: 20px;\n}\n.bothtitlediv{\n   position: absolute;\n   left: 65px;\n   bottom: 10px;\n}\n.title{\n    font-family: \"CircularStd\";\n    font-size: 18px;\n    font-weight: 500;\n    color: #393939;\n}\n.subtitle{\nfont-family: \"CircularStd\";\nfont-size: 18px;\nfont-weight: 450;\ncolor: #9F9F9F;\n}\n.bothtitledivsecondcard{\n    position: absolute;\n    left: 65px;\n    bottom: 25px;\n}\n.inputbox{\n    width: 665px;\nheight: 54px;\nborder-radius: 10px;\nborder: 1.3px solid #E5E5E5 ;\n}\n.inputbox::placeholder {\n    color:#868686;\n    font-family: \"Open Sans\", sans-serif; \n    font-size: 16px;\n    font-weight: 400;\n    padding: 10px;\n }\n .error{\n    color: #e88484;\n    font-size: small\n }\n .input-error {\n    border: 1px solid red;\n  }\n   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maintwoinputfieldsdiv": `createBookWithCopilot_maintwoinputfieldsdiv__9xXjX`,
	"bothtitlediv": `createBookWithCopilot_bothtitlediv__ZFyHx`,
	"title": `createBookWithCopilot_title__BhfGo`,
	"subtitle": `createBookWithCopilot_subtitle__1v0vw`,
	"bothtitledivsecondcard": `createBookWithCopilot_bothtitledivsecondcard__ArzSA`,
	"inputbox": `createBookWithCopilot_inputbox__iCsbU`,
	"error": `createBookWithCopilot_error__Nnykw`,
	"input-error": `createBookWithCopilot_input-error__vCmfA`
};
export default ___CSS_LOADER_EXPORT___;
