import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SideNavBar from "../Createbooksidebar/Createbooksidebar";
import SidebarStyle from "../../assets/css/Sidebar.module.css";
import manUser from "../../assets/images/man-user.png";
import Dropdown from "react-bootstrap/Dropdown";
import notificationImage from "../../assets/images/notification-img.png";
import headerStyle from "../../assets/css/header.module.css";
import { useAuth } from "../../hook/Auth";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Button from "react-bootstrap/Button";
import ReactQuill from "react-quill";
import { useSelector, useDispatch } from "react-redux";
import useApi from "../../hook/useApi";
import Spinner from "react-bootstrap/Spinner";
import fronthistorySlice, {
  setFronthistory,
} from "../../redux/slice/fronthistorySlice";
import { storeFrontDetails } from "../../redux/slice/frontDetailSlice";
function FrontMatterHistory() {
  const [loading, setLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [accepthistoryIndex, setAcceptHistoryIndex] = useState(false);
  const [historyIndex, setHistoryIndex] = useState(false);
  const chapterId = useSelector((state) => state?.chapterDetails?.data?._id);
  const historyData = useSelector((state) => state?.fronthistorySlice?.data);
  const matterDetails = useSelector((state) => state?.frontdetails?.data || {});

  const { fetchData } = useApi();
  const auth = useAuth();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookdetail = useSelector((state) => state.editbooks?.data);
  const bookid = bookdetail._id;
  localStorage.setItem("bookid", JSON.stringify(bookid));
  const userDetailsdata = JSON.parse(
    localStorage.getItem("userDetails") || "{}"
  );
  const histoyuserid = userDetailsdata?.user?._id;
  const FirstName = userDetailsdata?.user?.FirstName;
  const LastName = userDetailsdata?.user?.LastName;
  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };
  const handleeditprofile = () => {
    navigate("/editprofilepage");
  };
  const handlehistoryback = async () => {
    await navigate(`/frontmatter/${params?.id}/${params?.frontchapterid}`);
  };
  useEffect(() => {
    const edithistory = async () => {
      try {
        setLoading(true);
        const historyresponse = await fetchData(
          "GET",
          `api/frontBack-history/${params?.id}`
        );

        dispatch(
          setFronthistory({
            data: historyresponse,
            loading: false,
            error: null,
          })
        );
        setLoading(false);
      } catch (error) {
        dispatch(
          setFronthistory({
            data: null,
            loading: false,
            error: error.message,
          })
        );
      }
    };

    edithistory();
  }, [params?.id, dispatch]);

  const historyid = historyData?.map((item) => item?._id);
  console.log(historyid, "historyid ");
  const filteredHistoryData = historyData?.filter(
    (item) => item?.updateData?.type === "frontmatter"
  );

  const handleaccepthistory = async (item) => {
    console.log(item, "item");
    setAcceptLoading(true);
    try {
      const acceptresponse = await fetchData(
        "PATCH",
        `api/accept-front-edit/${item?._id}`,
        { userId: histoyuserid },
        null,
        null,
        () => {},
        "PATCH"
      )
        .then((res) => {
          let newData = { ...matterDetails[0] };
          newData.description = item?.updateData?.description;
          dispatch(
            storeFrontDetails({
              data: [newData],
              error: null,
              loading: false,
            })
          );
        })
        .catch((err) => {});
    } catch (error) {
      console.log(error);
    }
    try {
      const updateacceptget = await fetchData(
        "GET",
        `api/frontBack-history/${params?.id}`,
        null,
        {},
        null,
        () => {},
        "GET"
      );
      dispatch(
        setFronthistory({
          data: updateacceptget,
          loading: false,
          error: null,
        })
      );
    } catch (error) {
      setAcceptLoading(false);

      console.log(error);
    }
  };

  const handlerejecthistory = async (item) => {
    setRejectLoading(true);
    try {
      const rejecthistoryresponse = await fetchData(
        "PATCH",
        `api/reject-front-edit/${item?._id}`,
        { userId: histoyuserid },
        null,
        null,
        () => {},
        "PATCH"
      );
    } catch (error) {
      console.log(error);
    }
    try {
      const updaterejectget = await fetchData(
        "GET",
        `api/frontBack-history/${params?.id}`,
        null,
        {},
        null,
        () => {},
        "GET"
      );
      dispatch(
        setFronthistory({
          data: updaterejectget,
          loading: false,
          error: null,
        })
      );
    } catch (error) {
      setRejectLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <div>
        <SideNavBar />
        <div>
          <div
            className={SidebarStyle.nav_section}
            style={{ marginTop: "15px" }}
          >
            <div
              className={SidebarStyle.nav_item}
              style={{ marginLeft: "240px" }}
            >
              FrontMatter
            </div>
            <div
              className="header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <img src={notificationImage} />
              <div
                className="avatar"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#ccc",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <img src={manUser} />
              </div>

              <div className="user-info" style={{ display: "inline-block" }}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    className={headerStyle.welcome}
                    style={{
                      marginRight: "10px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Welcome, {FirstName} {LastName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                      onClick={handleeditprofile}
                    >
                      Edit Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleLogout}
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Delete Account
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          <div onClick={handlehistoryback}>
            <span style={{ marginLeft: "255px", fontSize: "25px" }}>
              <MdOutlineKeyboardArrowLeft />
            </span>
            <span> Editing History</span>
          </div>
          <div>
            {loading ? (
              <div style={{ textAlign: "center", marginTop: "50px" }}>
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              Array.isArray(filteredHistoryData) &&
              filteredHistoryData?.map((item) => {
                return (
                  item?.isAccepted === null && (
                    <>
                      <div style={{ marginLeft: "37px" }}>
                        <div
                          className="quill-container"
                          style={{
                            marginLeft: "230px",
                            marginRight: "161px",
                            marginBottom: "10px",
                            marginTop: "30px",
                            flexWrap: "nowrap",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ color: "red", marginBottom: "0" }}>
                            Edited by {item?.editorId?.FirstName}
                            <span
                              style={{
                                color: "gray",
                                fontSize: "small",
                                fontStyle: "oblique",
                                marginLeft: "10px",
                              }}
                            >
                              ( Last updated{" "}
                              {new Date(item?.timestamp).toLocaleString()} )
                            </span>
                          </p>

                          <Button
                            onClick={async () => {
                              setAcceptHistoryIndex(item._id);
                              await handleaccepthistory(item);
                            }}
                            style={{
                              backgroundColor: "#48c76e",
                              marginLeft: "auto",
                              marginRight: "10px",
                            }}
                          >
                            {" "}
                            {acceptLoading &&
                            item._id === accepthistoryIndex ? (
                              <div className="text-center">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only"></span>
                                </div>
                              </div>
                            ) : (
                              "Accept Edit"
                            )}
                          </Button>
                          <Button
                            onClick={async () => {
                              setHistoryIndex(item._id);
                              await handlerejecthistory(item);
                            }}
                            style={{
                              backgroundColor: "red",
                              marginRight: "214px",
                            }}
                          >
                            {rejectLoading && item._id === historyIndex ? (
                              <div className="text-center">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only"></span>
                                </div>
                              </div>
                            ) : (
                              "Reject Edit"
                            )}
                          </Button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          <div
                            className="quill-container"
                            style={{ marginLeft: "209px", marginRight: "50px" }}
                          >
                            <div>
                              <ReactQuill
                                className="reactquill"
                                theme="snow"
                                style={{
                                  height: 300,
                                  width: "881px",
                                  position: "static",
                                  marginLeft: "18px",
                                }}
                                value={item?.updateData?.description}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FrontMatterHistory;
