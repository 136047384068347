import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import Sidebar from "../common/Sidebar";
import { setCreateBook } from "../../redux/slice/createBookSlice";
import useApi from "../../hook/useApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { storeChapterDetails } from "../../redux/slice/chapterDetailsSlice";
import { setCreateChapter } from "../../redux/slice/createChapterSlice";
import { setId } from "../../redux/slice/idSlice";
import { toast } from "react-toastify";

const adminApiBaseUrl = process.env.REACT_APP_API_BASE_ADMIN_URL;
console.log(adminApiBaseUrl, " adminApiBaseUrl");
function Chapter() {
  const validationSchema = Yup.object().shape({
    language: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    subtitle: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    authorname: Yup.string().required("Required"),
    genre: Yup.string().required("Required"),
    bookSize: Yup.string().required("Required"),
    bindingType: Yup.string().required("Required"),
    bookInterior: Yup.string().required("Required"),
    paperType: Yup.string().required("Required"),
    bookCoverLamination: Yup.string().required(
      "Required"
    ),
    // BookCoverImage: Yup.string().required("File is required"),
  });
  const validationSchematwo = Yup.object().shape({
    language: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    subtitle: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    authorname: Yup.string().required("Required"),
    genre: Yup.string().required("Required"),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { fetchData } = useApi();
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [languageapi, setLanguageapi] = useState("");
  const [bookgenreapi, setBookgenreapi] = useState("");
  const [booksizeapi, setBooksizeapi] = useState("");
  const [selectpapertype, setSelectpapertype] = useState("");
  const params = useParams();
  const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const userid = userData?.user?._id;
  const userDetailsdata = JSON.parse(
    localStorage.getItem("userDetails") || "{}"
  );
  const authorname = userDetailsdata?.user?.FirstName;
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    authorname: authorname,
    genre: "",
    description: "",
    language: "",
    authorId: [],
    bookSize: "",
    bindingType: "",
    bookInterior: "",
    paperType: "",
    bookCoverLamination: "",
    BookCoverImage: "",
  });
  const handleCallback = () => {
    setFormData({
      title: "",
      subtitle: "",
      authorname: authorname,
      genre: "",
      description: "",
      language: "",
      authorId: [],
      bookSize: "",
      bindingType: "",
      bookInterior: "",
      paperType: "",
      bookCoverLamination: "",
      BookCoverImage: "",
    });
  };

  const [step, setStep] = useState(1);
  const handleNext = async () => {
    try {
      if (step === 1) {
        
        await validationSchematwo.validate(formData, { abortEarly: false });
        setErrors({});
        setStep(2);
      } else if (step === 2) {
        handleSubmit();
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
        error.inner.forEach((e) => {
          // toast.error(e.message);
        });
      }
    }
  };

  const handlePrev = () => {
    if (step === 2) {
      setStep(1);
    }
  };

  const details = localStorage.getItem("userDetails");
  const isLoggedIn = !!userid;

  const handlechange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: name === "authorid" ? userid : value,
    });
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setValidated(true);

      if (!isLoggedIn) {
        navigate("/login");
        console.log(
          "User not logged in. Redirect to login page or show message."
        );
        return;
      }

      let requestBody = { ...formData, authorId: [userid] };
      setLoading(true);
      const response = await fetchData(
        "POST",
        "api/create-book",
        requestBody,
        {},
        setCreateBook,
        handleCallback
      );
      const bookid = response?.data?._id;

      if (bookid) {
        setLoading(false);
        navigate(`/createchapter/${bookid}`);
        dispatch(setId({ ids: bookid }));
        dispatch(storeChapterDetails({ data: {} }));
        dispatch(setCreateChapter({ data: {} }));
      } else {
        navigate(`/createchapter`);
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
        error.inner.forEach((e) => {
          // toast.error(e.message);
        });
        setValidated(false);
        setLoading(false);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };
  const getBase64Image = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const imageBase64 = await getBase64Image(file);
        setFormData({
          ...formData,
          BookCoverImage: imageBase64,
        });
      } catch (error) {
        console.error("Error converting image to base64:", error);
      }
    }
  };
  useEffect(() => {
    const handlelangagugesapi = async () => {
      try {
        const languagerespose = await fetchData(
          "GET",
          `all-language?page=1&limit=10`,
          null,
          null,
          null,
          () => {},
          "GET",
          adminApiBaseUrl
        );
        console.log(languagerespose, " languagerespose");
        const langugedata = languagerespose.data;
        console.log(langugedata, "   langugedata");
        setLanguageapi(langugedata);
      } catch (error) {
        console.log(error);
      }
    };
    handlelangagugesapi();
    const handlebookgenreapi = async () => {
      try {
        const bookgenrerespose = await fetchData(
          "GET",
          `all-BookGenre?page=1&limit=10`,
          null,
          null,
          null,
          () => {},
          "GET",
          adminApiBaseUrl
        );
        console.log(bookgenrerespose, "  bookgenrerespose");

        setBookgenreapi(bookgenrerespose.data);
      } catch (error) {
        console.log(error);
      }
    };
    handlebookgenreapi();
    const handlebooksizeapi = async () => {
      try {
        const booksizeresponse = await fetchData(
          "GET",
          `all-BookSize?page=1&limit=10`,
          null,
          null,
          null,
          () => {},
          "GET",
          adminApiBaseUrl
        );
        console.log(booksizeresponse, "  booksizeresponse");
        const booksizedata = booksizeresponse.data;
        console.log(booksizedata, "   booksizedata");
        setBooksizeapi(booksizedata);
      } catch (error) {
        console.log(error);
      }
    };
    handlebooksizeapi();
    const handlepapertypeapi = async () => {
      try {
        const papertyperesponse = await fetchData(
          "GET",
          `all-PaperType?page=1&limit=10`,
          null,
          null,
          null,
          () => {},
          "GET",
          adminApiBaseUrl
        );
        console.log(papertyperesponse, "  papertyperesponse");
        const papertypedata = papertyperesponse?.data;
        console.log(papertypedata, "    papertypedata");
        setSelectpapertype(papertypedata);
      } catch (error) {
        console.log(error);
      }
    };
    handlepapertypeapi();
  }, []);
  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}>
              <h2>Create New Book</h2>
              <p>Create a book & start adding chapters and characters</p>
            </div>

            <div className={createnewbookStyle.chapter_one_section}>
              <div className={createnewbookStyle.mybook_top_section}>
                <h3>Book Details</h3>
              </div>
              <div className={createnewbookStyle.book_details_from}>
                <Form noValidate onSubmit={handleSubmit}>
                  {step === 1 && (
                    <>
                      <Row
                        className={`mb-6 ${createnewbookStyle.book_details_select}`}
                      >
                        <Col md="6">
                          <Form.Label>Language *</Form.Label>
                          <Form.Select
                            required
                            defaultValue=""
                            name="language"
                            value={formData.language}
                            onChange={handlechange}
                            isInvalid={!!errors.language}
                          >
                            <option key={"default"} value="">
                              Select language
                            </option>

                            {Array.isArray(languageapi) &&
                              languageapi?.map((item) => (
                                <option>{item.name}</option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.language}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row
                        className={`mb-12 ${createnewbookStyle.book_details_select}`}
                      >
                        <Col md="12">
                          <Form.Label>Book Title *</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter your book title"
                            name="title"
                            value={formData.title}
                            onChange={handlechange}
                            isInvalid={!!errors.title}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.title}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>

                      <Row
                        className={`mb-12 ${createnewbookStyle.book_details_select}`}
                      >
                        <Col md="12">
                          <Form.Label>Book Subtitle *</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter your book subtitle"
                            name="subtitle"
                            value={formData.subtitle}
                            onChange={handlechange}
                            isInvalid={!!errors.subtitle}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.subtitle}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row
                        className={`mb-12 ${createnewbookStyle.book_details_select}`}
                      >
                        <Col md="12">
                          <Form.Label>Book Description *</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter your book description"
                            name="description"
                            value={formData.description}
                            onChange={handlechange}
                            isInvalid={!!errors.description}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.description}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>

                      <Row
                        className={`mb-12 ${createnewbookStyle.book_details_select}`}
                      >
                        <Col md="12">
                          <Form.Label>Author Name *</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter author name"
                            name="authorname"
                            value={formData.authorname}
                            onChange={handlechange}
                            isInvalid={!!errors.authorname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.authorname}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row
                        className={`mb-12 ${createnewbookStyle.book_details_select}`}
                      >
                        <Col md="6">
                          <Form.Label>Book Genre *</Form.Label>
                          <Form.Select
                            required
                            defaultValue=""
                            name="genre"
                            value={formData.genre}
                            onChange={handlechange}
                            isInvalid={!!errors.genre}
                          >
                            <option value="">Select genre</option>
                            {Array.isArray(bookgenreapi) &&
                              bookgenreapi?.map((genre) => (
                                <option key={genre.id} value={genre.id}>
                                  {genre.BookGenre_title}
                                </option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.genre}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <Row
                        className={`mb-24 ${createnewbookStyle.book_details_select}`}
                      >
                        <Col md="6">
                          <Form.Label>Book Size *</Form.Label>
                          <Form.Select
                            required
                            defaultValue=""
                            name="bookSize"
                            value={formData.bookSize}
                            onChange={handlechange}
                            isInvalid={!!errors.bookSize}
                          >
                            <option value="">Select Size</option>
                            {Array.isArray(booksizeapi) &&
                              booksizeapi?.map((item) => (
                                <option>{item?.description}</option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.bookSize}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row
                        className={`mb-12 } ${createnewbookStyle.book_details_check}`}
                      >
                        <Col md="6">
                          <Form.Label>Binding Type *</Form.Label>
                          <div>
                            <Form.Check
                              inline
                              type="radio"
                              label="SoftBinding"
                              name="bindingType"
                              id="SoftBinding"
                              value="SoftBinding"
                              checked={formData.bindingType === "SoftBinding"}
                              onChange={handlechange}
                              isInvalid={!!errors.bindingType}
                            />
                            <Form.Check
                              type="radio"
                              inline
                              label="HardBinding"
                              name="bindingType"
                              id="HardBinding"
                              value="HardBinding"
                              checked={formData.bindingType === "HardBinding"}
                              onChange={handlechange}
                              isInvalid={!!errors.bindingType}
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {errors.bindingType}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>

                      <Row
                        className={`mb-12 ${createnewbookStyle.book_details_check}`}
                      >
                        <Col md="12">
                          <Form.Label>Book Interior *</Form.Label>
                          <div>
                            <Form.Check
                              inline
                              type="radio"
                              label="Black and White"
                              name="bookInterior"
                              id="bookInterior"
                              value="BlackAndWhite"
                              checked={
                                formData.bookInterior === "BlackAndWhite"
                              }
                              onChange={handlechange}
                              isInvalid={!!errors.bookInterior}
                            />
                            <Form.Check
                              type="radio"
                              inline
                              label="Color"
                              name="bookInterior"
                              id="bookInterior"
                              value="Color"
                              checked={formData.bookInterior === "Color"}
                              onChange={handlechange}
                              isInvalid={!!errors.bookInterior}
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {errors.bookInterior}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row
                        className={`mb-12 ${createnewbookStyle.book_details_select}`}
                      >
                        <Col md="6">
                          <Form.Label>Paper Type *</Form.Label>
                          <Form.Select
                            required
                            defaultValue=""
                            name="paperType"
                            value={formData.paperType}
                            onChange={handlechange}
                            isInvalid={!!errors.paperType}
                          >
                            <option value="">select Paper Type</option>
                            {Array.isArray(selectpapertype) &&
                              selectpapertype?.map((item) => (
                                <option>{item?.PaperType_title}</option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.paperType}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>

                      <Row
                        className={`mb-12  ${createnewbookStyle.book_details_check}`}
                      >
                        <Col md="12">
                          <Form.Label>Cover Lamination *</Form.Label>
                          <div>
                            <Form.Check
                              inline
                              type="radio"
                              label="Matt Finish"
                              name="bookCoverLamination"
                              id="MattFinish"
                              value="MattFinish"
                              checked={
                                formData.bookCoverLamination === "MattFinish"
                              }
                              onChange={handlechange}
                              isInvalid={!!errors.bookCoverLamination}
                            />
                            <Form.Check
                              inline
                              type="radio"
                              label="GlossFinish"
                              name="bookCoverLamination"
                              id="GlossFinish"
                              value="GlossFinish"
                              checked={
                                formData.bookCoverLamination === "GlossFinish"
                              }
                              onChange={handlechange}
                              isInvalid={!!errors.bookCoverLamination}
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {errors.bookCoverLamination}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row
                        className={`mb-12 ${createnewbookStyle.book_details_select}`}
                      >
                        <Col md="10">
                          <Form.Group controlId="formFilesm" className=" mt-3">
                            <Form.Label>File Image</Form.Label>
                            <Form.Control
                              type="file"
                              size="sm"
                              onChange={handleFileChange}
                              // isInvalid={!!errors.BookCoverImage}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.BookCoverImage}
                            </Form.Control.Feedback>
                          </Form.Group>
                          {formData.BookCoverImage && (
                            <div>
                              <img
                                src={formData.BookCoverImage}
                                style={{ maxWidth: "50%", maxHeight: "100px" }}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row
                    className={`mb-12 ${createnewbookStyle.save_draft_btns_section}`}
                  >
                    <Col md="6">
                      {step === 2 && (
                        <Button
                          onClick={handlePrev}
                          className={createnewbookStyle.finish_btn}
                        >
                          Previous
                        </Button>
                      )}
                    </Col>
                    <Col md="6">
                      <Button
                        onClick={(e) =>
                          step === 1 ? handleNext() : handleSubmit(e)
                        }
                        className={createnewbookStyle.finish_btn}
                        disabled={loading}
                        style={{
                          backgroundColor: loading ? "grey" : "#1a73E8",
                          cursor: loading ? "not-allowed" : "pointer",
                          color: loading ? "black" : "white",
                          opacity: loading ? 0.5 : 1,
                        }}
                      >
                        {loading ? (
                          <div className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        ) : step === 1 ? (
                          "Next"
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chapter;
