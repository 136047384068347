import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  error: null,
  loading: false,
};

export const ChapterFormat = createSlice({
  name: "ChapterFormat",
  initialState,
  reducers: {
    setChapterFormat: (state, action) => {
      state.data = action.payload?.data;
      state.loading = action.payload?.loading;
      state.error = action.payload?.error;
    },
  },
});

export const { setChapterFormat } = ChapterFormat.actions;
export default ChapterFormat.reducer;
