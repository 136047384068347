import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import { Stepper, Step } from "react-form-stepper";
import Sidebar from "../common/Sidebar";

import { Tooltip } from "antd";

function Setting() {
  const [validated, setValidated] = useState(false);
  const text = <span>managing collaborators</span>;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}>
              <h2>Create New Book</h2>
              <p>Create a book & start adding chapters and characters</p>
            </div>
            <div className={createnewbookStyle.stepper_section}>
              <Stepper activeStep={1} disabledColor={""}>
                <Step
                  label="Book Details"
                  className={createnewbookStyle.step_active}
                />
                <Step
                  label="Main Characters"
                  className={createnewbookStyle.step_common}
                />
                <Step
                  label="Setting"
                  className={createnewbookStyle.step_common}
                />
                <Step
                  label="Plot Summary"
                  className={createnewbookStyle.step_common}
                />
                <Step
                  label="Writing Preferences"
                  className={createnewbookStyle.step_common}
                />
              </Stepper>
            </div>
            <div className={createnewbookStyle.book_details_from_section}>
              <h2>Setting</h2>
              <div className={createnewbookStyle.book_details_from}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row
                    className={`mb-12 ${createnewbookStyle.book_details_select}`}
                  >
                    <Col md="6">
                      <Form.Label>Time period</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="18th century"
                        defaultValue="18th century"
                      />
                    </Col>
                    <Col md="6">
                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="New York City"
                        defaultValue="New York City"
                      />
                    </Col>
                  </Row>
                  <Row
                    className={`mb-12 ${createnewbookStyle.book_details_select} ${createnewbookStyle.character_name}`}
                  >
                    <Col md="12">
                      <Form.Label>Additional Details</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Describe the setting's atmosphere, culture, and important landmarks"
                        rows={3}
                      />
                    </Col>
                  </Row>
                  <div className={createnewbookStyle.next_cancel_btns_section}>
                    <div className={createnewbookStyle.next_cancel_btns}>
                      <Button
                        type="submit"
                        className={createnewbookStyle.next_btn}
                      >
                        Next
                      </Button>
                      <Button
                        type="submit"
                        className={createnewbookStyle.cancel_btn}
                      >
                        Previous
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Setting;
