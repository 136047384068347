import RequiredAuth from "../hook/RequiredAuth";
import HomePage from "../pages/HomePage";
import Services from "../pages/services";
import ContactUs from "../pages/ContactUs";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import ChangePassword from "../pages/ChangePassword";
import Dashboard from "../pages/Dashboard";
import Mybooks from "../pages/Mybooks";
import CreatenewbookPage from "../pages/CreatenewbookPage";
import CollaborationPage from "../pages/CollaborationPage";
import SubscriptionPlanPage from "../pages/SubscriptionPlanPage";
import CurrentSubscriptionPage from "../pages/CurrentSubscriptionPage";
import BillingHistoryPage from "../pages/BillingHistoryPage";
import SentInvitationsPage from "../pages/SentInvitationsPage";
import PreviewExportPage from "../pages/PreviewExportPage";
import EditprofilePage from "../pages/EditprofilePage";
import AuthorLoginPage from "../pages/AuthorLoginPage";
import CreateChapter from "../components/Createnewbook/CreateChapter";
import ExportFormates from "../components/export formates/ExportFormates";
import Viewbooks from "../pages/Viewbooks";
import Editbookpage from "../pages/Editbookpage";
// import ResetPassword from "../components/ResetPassword/ResetPassword";
import ViewMorePage from "../pages/ViewMorePage";
import FrontMatter from "../components/Createnewbook/FrontMatter";
import ResetPassword from "../components/ResetPassword/ResetPassword"
import EndMatter from "../components/Createnewbook/EndMatter";
import EndMatterHistory from "../components/Createnewbook/EndMatterHistory";
import FrontMatterHistory from "../components/Createnewbook/FrontMatterHistory";
import ViewParagraph from "../components/Createnewbook/ViewParagraph";
import EditBookHistory from "../components/Createnewbook/EditBookHistory";
import Aioption from "../components/Createnewbook/Aioption";
import CreateBookWithCopilot from "../components/Createnewbook/CreateBookWithCopilot";
import CommonCard from "../components/common/CommonCard";
import AddBookDetailsCopilot from "../components/Createnewbook/AddBookDetailsCopilot";
import AddchaptertitleCopilot from "../components/Createnewbook/AddchaptertitleCopilot";
import CreateBookOptions from "../components/Createnewbook/CreateBookOptions";
import Autopilotone from "../components/Createnewbook/Autopilotone";
import CreatnewbookwithAI from "../components/Createnewbook/CreatenewbookwithAi"
import AutoPilot from "../components/Createnewbook/CreateBookwithAutopilot";
import CreateBookWithCopilotOne from "../components/Createnewbook/CreateBookWithCopilotOne";
import OwnCreateTitle from "../components/Createnewbook/OwnCreateTitle";
import Aicreatechaptertitle from "../components/Createnewbook/Aicreatechaptertitle";
import Example from "../components/editor/Example"
import ChapterFormat from "../components/Createnewbook/ChapterFormat";

export const authlayoutRoutes = [
  {
    path: "/",
    element: <HomePage />
  },
  {
        path:"/services",
         element :<RequiredAuth><Services /></RequiredAuth>
     },
  {
    path: "/services",
    element: <RequiredAuth><Services /></RequiredAuth>
  },
  {
    path: "/contactUs",
    element: <ContactUs />
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/signup",
    element: <SignUp />
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />
  },
  {
    path: "/ChangePassword",
    element: <ChangePassword />
  },
  {
    path: "/dashboard",
    element: <RequiredAuth><Dashboard /></RequiredAuth>
  },
  {
    path: "/mybooks",
    element: <RequiredAuth><Mybooks /></RequiredAuth>
  },
  {
    path: "/createnewbookpage",
    element: <RequiredAuth><CreatenewbookPage /></RequiredAuth>
  },
  {
    path: "/collaborationpage",
    element: <RequiredAuth><CollaborationPage /></RequiredAuth>
  },
  {
    path: "/subscriptionplan",
    element: <RequiredAuth><SubscriptionPlanPage /></RequiredAuth>
  },
  {
    path: "/createchapter/:id/",
    element: <RequiredAuth><CreateChapter /></RequiredAuth>
  },
  // {
  //   path: "/createchapter/:id/:chapterId",
  //   element: <RequiredAuth><CreateChapter /></RequiredAuth>
  // },
  {
    path: "/createchapter/:id/:chapterid",
    element: <RequiredAuth><CreateChapter /></RequiredAuth>
  },
  {
    path: "/createchapter",
    element: <RequiredAuth><CreateChapter /></RequiredAuth>
  },
  {
    path: "/reset-password",
    element: <ResetPassword />
  },
  {
    path: "/currentsubscription",
    element: <RequiredAuth><CurrentSubscriptionPage /></RequiredAuth>
  },
  {
    path: "/billinghistorypage",
    element: <RequiredAuth><BillingHistoryPage /></RequiredAuth>
  },
  {
    path: "/sentinvitationspage",
    element: <RequiredAuth><SentInvitationsPage /></RequiredAuth>
  },
  {
    path: "/previewexportpage",
    element: <RequiredAuth><PreviewExportPage /></RequiredAuth>
  },
  {
    path: "/editprofilepage",
    element: <RequiredAuth><EditprofilePage /></RequiredAuth>
  },
  {
    path: "/authorloginpage",
    element: <RequiredAuth><AuthorLoginPage /></RequiredAuth>
  },
  {
    path: "/exportformates",
    element: <RequiredAuth><ExportFormates /></RequiredAuth>
  },
  {
    path: "/viewbooks/:id",
    element: <RequiredAuth><Viewbooks /></RequiredAuth>
  },
  {
    path: "/viewbooks",
    element: <RequiredAuth><Viewbooks /></RequiredAuth>
  },

  {
    path: "/editbookpage",
    element: <RequiredAuth><Editbookpage /></RequiredAuth>
  },
  {
    path: "/frontmatter/:id",
    element: <RequiredAuth><FrontMatter /></RequiredAuth>
  },
  {
    path: "/frontmatter/:id/:frontchapterid",
    element: <RequiredAuth><FrontMatter /></RequiredAuth>
  },
  {
    path: "/viewmorepage/:id",
    element: <RequiredAuth><ViewMorePage /></RequiredAuth>
  },
 
  {
    path:"/viewparagraph/:id/:chapterid",
    element:<RequiredAuth><ViewParagraph/></RequiredAuth>
  },
  {
    path:"/viewparagraph/:id",
    element:<RequiredAuth><ViewParagraph/></RequiredAuth>
  },
  
  {
  path :"/endmatter/:id/:endchapterid",
  element:  <RequiredAuth><EndMatter /></RequiredAuth>
 },

 {
  path :"/endmatter/:id",
  element:  <RequiredAuth><EndMatter /></RequiredAuth>
 },
 {
  path :"/editbookhistory/:id/:chapterid",
  element : <RequiredAuth><EditBookHistory /></RequiredAuth>
 },
 {
  path :"/endmatterhistory/:id/:endchapterid",
  element : <RequiredAuth><EndMatterHistory /></RequiredAuth>
 },
 {
  path :"/frontmatterhistory/:id/:frontchapterid",
  element : <RequiredAuth><FrontMatterHistory /></RequiredAuth>
 },
 {
  path :"/aioptions",
  element : <RequiredAuth><Aioption /></RequiredAuth>
 },
 {
  path:"/createbookwithcopilot",
  element:<RequiredAuth><CreateBookWithCopilot /></RequiredAuth>
 },
 {
  path:"/addbookdetails",
  element:<RequiredAuth><AddBookDetailsCopilot /></RequiredAuth>
 },
 {
  path:"/addchaptertitle/:id",
  element:<RequiredAuth><AddchaptertitleCopilot /></RequiredAuth>
 },
 {
  path:"/autopilotone",
  element:<RequiredAuth><Autopilotone /></RequiredAuth>
 },
 {
  path:"/createbookoptions",
  element:<RequiredAuth><CreateBookOptions /></RequiredAuth>
 },
{
  path :"/autopilot/:id",
  element : <RequiredAuth><AutoPilot /></RequiredAuth>
 },
 {
  path :"/autopilot",
  element : <RequiredAuth><AutoPilot /></RequiredAuth>
 },
 {
  path :"/createbookwithai",
  element:<RequiredAuth><CreatnewbookwithAI /></RequiredAuth>
 },
 {
  path :"/createbookwithcopilotone/:id",
  element:<RequiredAuth><CreateBookWithCopilotOne/></RequiredAuth>
 },
 {
    path :"/owncreatetitle",
    element:<RequiredAuth><OwnCreateTitle /></RequiredAuth>
 },
 {
  path : "/aicreatechaptertitle/:id",
  element : <RequiredAuth><Aicreatechaptertitle /></RequiredAuth>
 },

 {
  path:"/exampleeditor/:id",
  element:<RequiredAuth><Example /></RequiredAuth>
 },
 {
  path :"/chapterformat/:id",
  element:<RequiredAuth><ChapterFormat /></RequiredAuth>
 }

];
