import React, { useState, useEffect } from "react";
import { PinturaEditor } from "@pqina/react-pintura";
import "@pqina/pintura/pintura.css";
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  setPlugins,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_defaults,
  plugin_annotate,
  markup_editor_defaults,
} from "@pqina/pintura";
import {
  LocaleCore,
  LocaleFinetune,
  LocaleFilter,
  LocaleAnnotate,
  LocaleMarkupEditor,
} from "@pqina/pintura/locale/en_GB";
import frontcover from "../../assets/images/Book-front-cover.png";
import backcover from "../../assets/images/Book-back-cover.png";
import exmaplescss from "../../assets/css/Example.module.css";
import useApi from "../../hook/useApi";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { GoPlus } from "react-icons/go";
import bookcoverone from "../../assets/images/cover2.png";
import bookcovertwo from "../../assets/images/cover3.png";
import bookcoverthree from "../../assets/images/cover5.png";
import bookcoverfour from "../../assets/images/cover6.png";
import bookcoverfive from "../../assets/images/cover8.png";

setPlugins(plugin_finetune, plugin_filter, plugin_annotate);

const editorDefaults = {
  utils: ["finetune", "filter", "annotate"],
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...markup_editor_defaults,
  locale: {
    ...LocaleCore,
    ...LocaleFinetune,
    ...LocaleFilter,
    ...LocaleAnnotate,
    ...LocaleMarkupEditor,
  },
};

const convertFileToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(new Error("Failed to convert file to Base64"));
    };
    reader.readAsDataURL(blob);
  });
};

export default function Example() {
  const [result, setResult] = useState("");
  const [combinedImage, setCombinedImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [selectCover, setSelectCover] = useState("");
  const [open, setOpen] = useState(false);
  const [processedImage, setProcessedImage] = useState("");
  console.log(open);
  const [preDefineImages, setPreDefineImages] = useState([
    // bookcoverone,
    // bookcovertwo,
    // bookcoverthree,
    // bookcoverfour,
    // bookcoverfive,
  ]);
  const { fetchData } = useApi();
  const [base64, setBase64] = useState("");
  const params = useParams();

  useEffect(() => {
    const combineImages = async () => {
      const frontImage = new Image();
      const backImage = new Image();

      frontImage.src = frontcover;
      backImage.src = backcover;

      await Promise.all([
        new Promise((resolve) => {
          frontImage.onload = resolve;
        }),
        new Promise((resolve) => {
          backImage.onload = resolve;
        }),
      ]);

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const gap = 60;
      const width = frontImage.width * 2 + gap;
      const height = frontImage.height;

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(frontImage, 0, 0, frontImage.width, frontImage.height);
      ctx.drawImage(
        backImage,
        frontImage.width + gap,
        0,
        frontImage.width,
        frontImage.height
      );
      const combinedImageDataUrl = canvas.toDataURL("image/png");
      setCombinedImage(combinedImageDataUrl);
      setCurrentImage(combinedImageDataUrl);
    };
    combineImages();
  }, []);

  const switchImage = (cover) => {
    if (cover === "front") {
      setCurrentImage(frontcover);
      setSelectCover("front");
    } else if (cover === "back") {
      setCurrentImage(backcover);
      setSelectCover("back");
    }
  };

  const handlePlusClick = async () => {
    try {
      const [fileHandle] = await window.showOpenFilePicker({
        types: [
          {
            description: "Images",
            accept: { "image/*": [".png", ".gif", ".jpeg", ".jpg", ".webp"] },
          },
        ],
        excludeAcceptAllOption: true,
        multiple: false,
      });

      const file = await fileHandle.getFile();
      const newImageUrl = URL.createObjectURL(file);
      // setCurrentImage(newImageUrl);
      setPreDefineImages([...preDefineImages,   { imageType: "newImage", url: newImageUrl }]);
    } catch (err) {
      console.error(err);
    }
  };
  const handlemodal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const resizeCanvas = (canvas, width, height) => {
    const tmpCanvas = document.createElement("canvas");
    const tmpCtx = tmpCanvas.getContext("2d");
    tmpCanvas.width = width;
    tmpCanvas.height = height;
    tmpCtx.drawImage(canvas, 0, 0, width, height);
    return tmpCanvas;
  };
  function dataURLtoBlob(dataURL) {
    const [header, data] = dataURL.split(",");
    const mime = header.match(/:(.*?);/)[1];
    const binStr = atob(data);
    const len = binStr.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binStr.charCodeAt(i);
    }

    return new Blob([bytes], { type: mime });
  }
  const handleProcess = ({ dest }) => {
    const img = new Image();
    img.src = URL.createObjectURL(dest);

    img.onload = async () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const dpi = 300;
      const width = 4 * dpi;
      const height = 6 * dpi;
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      const resizedCanvas = resizeCanvas(canvas, width, height);
      const resizedImageDataUrl = resizedCanvas.toDataURL("image/png");
      setResult(resizedImageDataUrl);
      const base64Data = await convertFileToBase64(
        await dataURLtoBlob(resizedImageDataUrl)
      );
      setBase64(base64Data);
      setProcessedImage(resizedImageDataUrl);
      console.log(base64Data, "base64");
    };
  };
  const handleimageupload = (image) => {
    setCurrentImage(image);
    handleCloseModal();
  };
  const handleImageCoverSave = async () => {
    try {
      const coverResponse = await fetchData(
        "PUT",
        `api/${params?.id}/cover`,
        {
          ...(selectCover === "front" && { frontCoverImage: base64 }),
          ...(selectCover === "back" && { backCoverImage: base64 }),
        },
        {},
        null,
        () => {},
        "PUT"
      );
    } catch (error) {
      console.log(error);
    }
  };
   useEffect(()=>{
    const bookcover = async()=>{
      try{
      const imagecoverresponse = await fetchData("GET" , "get-all-bookcovertemplate" , null , null , null , ()=>{}, "GET",
    "https://ebook-admin.indapoint.org")   
    const imagedata = imagecoverresponse?.data
    console.log( imagedata , "imagecoverresponse")
    const allCoverImages = imagedata?.flatMap(item => [
      { imageType: 'frontCoverImage', url: item.frontCoverImage },
      { imageType: 'backCoverImage', url: item.backCoverImage }
    ]);
    setPreDefineImages(allCoverImages)
   
   
      }catch(error){
        console.log(error)
      }
    }
    bookcover();
   },[])

  return (
    <>
      <div>
        <div className={exmaplescss.mainbuttondiv}>
          <button
            onClick={() => {
              setResult("");
              switchImage("front");
            }}
            className={`${exmaplescss.frontbutton} ${exmaplescss.button} ${
              selectCover === "front" ? exmaplescss.selected : ""
            }`}
          >
            Front Cover
          </button>
          <button
            onClick={() => {
              setResult("");
              switchImage("back");
            }}
            className={`${exmaplescss.backbutton} ${exmaplescss.button} ${
              selectCover === "back" ? exmaplescss.selected : ""
            }`}
          >
            Back Cover
          </button>
          {currentImage !== combinedImage && (
            <button onClick={handlemodal} className={exmaplescss.importbutton}>
              Import
            </button>
          )}
          {processedImage && (
            <button
              className={exmaplescss.savebutton}
              onClick={handleImageCoverSave}
            >
              Save
            </button>
          )}
        </div>
        <div style={{ height: "70vh", marginTop: "20px" }}>
          {currentImage && (
            <PinturaEditor
              {...editorDefaults}
              src={currentImage}
              onLoad={(res) => console.log("load image", res)}
              onProcess={handleProcess}
            />
          )}
        </div>
        {!!result.length && (
          <p>
            <img src={result} alt="Edited result" />
          </p>
        )}
      </div>
      {open && (
        <Modal show={open} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Images</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={exmaplescss.predefineimages}>
              {preDefineImages.map((image, index) => (
               
                <img
                  src={image?.url}
                  style={{ width: "30%", cursor: "pointer" }}
                  alt={`Predefined ${index}`}
                  onClick={() => handleimageupload(image?.url)}
                />
            
              
              ))}
              <div style={{ fontSize: "90px" }} onClick={handlePlusClick}>
                <GoPlus />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

