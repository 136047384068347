import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    users : [],
    error : null,
    loading : false
}

export const loginSlice = createSlice({
    name : "login",
    initialState,
    reducers :{
        setLogin :(state , action) =>{
            state.users = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        }
    }
})

export const {setLogin} = loginSlice.actions
export default loginSlice.reducer;