import React from "react";
import Sidebar from "../common/Sidebar";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { storeChapterDetails } from "../../redux/slice/chapterDetailsSlice";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import AiLogo from "../../assets/images/Ai-logo.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../../hook/useApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
function CreateBookWithCopilotOne(){
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
   subtitle: Yup.string().required("Subtitle is required"),
  });
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chapters, setChapters] = useState([{ title: "", subtitle: "" }]);
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const userid = userData?.user?._id;
  const dispatch = useDispatch();
  const params = useParams();
  const { fetchData } = useApi();
  const handlecancel = () => {
    navigate(-1);
  };
  const handleadd = () => {
    setChapters([...chapters, { title: "", subtitle: "" }]);
  };
  const handlechange = (index, field, value) => {
    const newchapters = [...chapters];
    newchapters[index][field] = value;
    setChapters(newchapters);
  };

  const handlepostok = async () => {
    let payload = [];
    chapters?.map((item) => {
      payload.push({
        title: item?.title,
        subtitle: item?.subtitle,
        description: "",
        FileUrl: "",
        authorId: userid,
        bookId: params?.id,
      });
    });
    try {
      await validationSchema.validate(...payload, { abortEarly: false });
      setValidated(true);
      const responsepost = await fetchData(
        "POST",
        `api/create-chapter`,
        {
          chapters: [...payload],
        },
        null,
        null,
        () => {},
        "POST"
      );
      dispatch(storeChapterDetails());
      navigate("/mybooks");
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
        error.inner.forEach((e) => {
          toast.error(e.message);
        });
        setValidated(false);
        setLoading(false);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };
  const payload = [];
  chapters.map((item) => {
    payload.push({
      title: item?.title,
      subtitle: item?.subtitle,
    });
  });
  const handlelogo = async (value, index, name) => {
    const imporovedata = await fetchData(
      "POST",
      `api/update-to-lowercase`,
      { paragraphDetail: value },
      null,
      null,
      () => {},
      "POST"
    );
    let newChapter = [...chapters];
    newChapter[index][name] = imporovedata?.data;
    setChapters(newChapter);
  };
  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div>
              <h2>Add Chapter Titles</h2>
              <p style={{ color: "grey" }}>
                Let our AI technology assist you in generating a complete book
                effortlessly. Just provide some initial details, and our AI will
                handle the rest, delivering a ready-to-use book in minutes.
              </p>
            </div>
            <div className={createnewbookStyle.chapterdetails_section}>
              <div>
                <h3>Chapter Details</h3>
                {Array.isArray(chapters) &&
                  chapters?.map((chapter, index) => (
                    <div key={index}>
                      <Row
                        className={`mb-12 ${createnewbookStyle.book_details_select}`}
                      >
                        <Col md="12">
                          <Form.Label>Chapter Title</Form.Label>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #e5e5e5",
                              borderRadius: "9px",
                            }}
                          >
                            <Form.Control
                              required
                              type="text"
                              placeholder=""
                              name="title"
                              value={chapter.title}
                              isInvalid={!!errors.title}
                              onChange={(e) =>
                                handlechange(index, "title", e.target.value)
                              }
                              className={createnewbookStyle.customformcontrol}
                            />
                            <button
                              className={createnewbookStyle.custombutton}
                              onClick={() =>
                                handlelogo(chapter?.title, index, "title")
                              }
                            >
                              <img src={AiLogo} alt="AI Logo" />
                            </button>
                          </div>

                          <Form.Control.Feedback type="invalid">
                            {errors.title}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row
                        className={`mb-12 ${createnewbookStyle.book_details_select}`}
                      >
                        <Col md="12">
                          <Form.Label>Subtitle</Form.Label>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #e5e5e5",
                              borderRadius: "9px",
                            }}
                          >
                            <Form.Control
                              required
                              type="text"
                              placeholder=""
                              name="subtitle"
                              value={chapter.subtitle}
                              isInvalid={!!errors.subtitle}
                              onChange={(e) =>
                                handlechange(index, "subtitle", e.target.value)
                              }
                              className={createnewbookStyle.customformcontrol}
                            />
                            <button
                              className={createnewbookStyle.custombutton}
                              onClick={() =>
                                handlelogo(chapter?.subtitle, index, "subtitle")
                              }
                            >
                              <img src={AiLogo} alt="AI Logo" />
                            </button>
                          </div>

                          <Form.Control.Feedback type="invalid">
                            {errors.subtitle}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </div>
                  ))}
                <Row
                  className={`mb-12 ${createnewbookStyle.book_details_select}`}
                >
                  <Col md-6 style={{ display: "flex", justifyContent: "end" }}>
                    <button
                      className={createnewbookStyle.button}
                      onClick={() => handleadd()}
                    >
                      <span style={{ fontSize: "x-large", marginRight: "4px" }}>
                        +
                      </span>
                      <span>Add Chapters</span>{" "}
                    </button>
                  </Col>
                </Row>
                <Row
                  className={`mb-12 ${createnewbookStyle.book_details_select}`}
                >
                  <Col md-6>
                    <button
                      onClick={() => handlepostok()}
                      className={createnewbookStyle.autopilot_button_next}
                    >
                      Submit
                    </button>
                    <button
                      className={createnewbookStyle.autopilot_button_cancel}
                      onClick={() => handlecancel()}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateBookWithCopilotOne;
