import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import SidebarStyle from "../../assets/css/Sidebar.module.css";
import { useSelector, useDispatch } from "react-redux";
import { setCreateChapter } from "../../redux/slice/createChapterSlice";
import vector from "../../assets/images/dropdown.png";
import layer from "../../assets/images/Layer.png";
import Delete from "../../assets/images/Delete.png";
import useApi from "../../hook/useApi";
import { Button, Modal } from "react-bootstrap";
import mybookStyle from "../../assets/css/mybook.module.css";
import { storeChapterDetails } from "../../redux/slice/chapterDetailsSlice";
import { useNavigate } from "react-router-dom";
import { setFrontMatter } from "../../redux/slice/frontMatterSlice";
import { storeFrontDetails } from "../../redux/slice/frontDetailSlice";
import { useParams } from "react-router-dom";
import { setendmatters } from "../../redux/slice/endmatters";
import { setstoreendmattersdetails } from "../../redux/slice/storeendmattersdetails";

const SideNavBar = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [endmatters, setEndmatters] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [ idloading,setidLoading] = useState(false);
  const bookId = useSelector((state) => state.createbook.data?.data?._id);
  const BookId = bookId;
  const details = useSelector((state) => state.storeendmattersdetails);
  const updateendmatter = useSelector((state) => state.endmatters);
  const id2 =
    details && details?.data && details?.data?.length > 0
      ? details?.data[0]?._id
      : null;
  const {  fetchData } = useApi();
  const params = useParams()
  console.log( params  , "params")
  
  const chapters = useSelector((state) => state?.createchapter?.data);
  console.log( chapters ," chapters")
  const initialChapters = Array.isArray(chapters)
    ? chapters
    : chapters
    ? [chapters]
    : [];
  const [chaptersArray, setChaptersArray] = useState(initialChapters);
  const selectedChapter = useSelector((state) => state?.chapterDetails?.data);
  const chapterId = useSelector((state) => state?.chapterDetails?.data?._id);
console.log(selectedChapter?._id,"chapid")
 
  const [openSections, setOpenSections] = useState({
    frontMatter: false,
    chapters: true,
    endMatters: false,
  });

  const [chaptersIndex, setChapterIndex] = useState(null);
  const selectedChapters = useSelector(
    (state) => state?.chapterDetails?.data?.chapter || {}
  );
 

  const handleChapterClick = async (chapter) => {
    dispatch(
      storeChapterDetails({
        data: { ...chapter, title: "", description: "" },
        error: null,
        loading: false,
      })
    );

    try {
      const response = await fetchData("GET", `api/chapter/${chapter._id}`);
      const paragraphs = response.data;
      let { title } = response.data;
      if (paragraphs) {
        const paragraph = paragraphs?.Paragraph;
        const descriptions = paragraph.map(
          (paragraph) => paragraph.description
        );
        const joinedDescriptions = descriptions.join("</br>");

        dispatch(
          storeChapterDetails({
            data: { ...chapter, title, description: joinedDescriptions },
            error: null,
            loading: false,
          })
        );
  
      } else {
      }
    } catch (error) {
      console.error("Error fetching paragraph data:", error);
    }
  };
  

  const addChapter = () => {
    setChaptersArray([...chaptersArray, {}]);
  };


  useEffect(() => {
    const bookdetails = async () => {
      try {
        const bookdata = await fetchData(
          "GET",
          `api/book/${params?.id}`,
          null,
          {},
          null,
          () => {},
          "GET"
        );
        console.log(bookdata.chapters, "bookdata");
        // this is for chapters
        dispatch(
          setCreateChapter({
            data: bookdata.chapters,
            loading: false,
            error: null,
          })
        );
        dispatch(
          setFrontMatter({
            data: bookdata.frontmatter,
            loading: false,
            error: null,
          })
        );
        // This is for End matter
        dispatch(
          setendmatters({
            data: bookdata.backmatter,
            loading: false,
            error: null,
          })
        );
        if (bookdata && Array.isArray(bookdata.backmatter)) {
          setEndmatters(bookdata.backmatter);
        } else {
          setEndmatters([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    bookdetails();
  }, []);

  useEffect(() => {
    setChaptersArray(
      Array.isArray(chapters) ? chapters : chapters ? [chapters] : []
    );
  }, [chapters]);

  const handleDeleteChapter = (chapterId) => {
    let notSaveExistChapter = [...chaptersArray];
    let deleteChapter = notSaveExistChapter[chaptersIndex];
    if (deleteChapter?._id) {
      fetchData("DELETE", `api/delete-chapter`, {
        chapterIds: deleteChapter?._id,
      })
        .then(() => {
          notSaveExistChapter.splice(chaptersIndex, 1);
          setChaptersArray(notSaveExistChapter);
          dispatch(
            setCreateChapter({
              data: notSaveExistChapter,
              loading: false,
              error: null,
            })
          );
          dispatch(
            storeChapterDetails({
              data: [notSaveExistChapter[chaptersIndex - 1]],
              loading: false,
              error: null,
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      notSaveExistChapter.splice(chaptersIndex, 1);
      setChaptersArray(notSaveExistChapter);
    }
  };
  const handleclose = (chapter) => {
    handleDeleteChapter(chapter?._id);
    setOpen(false);
  };

 
  const [frontmatteropen, setFrontmatteropen] = useState(false);
  const frontmatter = useSelector((state) => state?.frontmatter?.data);
  const initialMatter = Array.isArray(frontmatter)
    ? frontmatter
    : frontmatter
    ? [frontmatter]
    : [];
  const [matterArray, setMatterArray] = useState(initialMatter);

  useEffect(() => {
    setMatterArray(frontmatter);
  }, [frontmatter]);

  const handleMatterClick = (matter) => {
    dispatch(
      storeFrontDetails({
        data: [matter],
        error: null,
        loading: false,
      })
    );
  };
  const [matterIndex, setmatterIndex] = useState(null);
  const matterIds = useSelector((state) => state?.frontdetails?.data?._id);
  const handleDeleteMatter = (matterIds) => {
    let notSavedExistMatter = [...matterArray];
    let deleteMatter = notSavedExistMatter[matterIndex];

    if (deleteMatter?._id) {
      fetchData("DELETE", `api/delete/front-back-matter`, {
        frontMatterIds: [deleteMatter?._id],
      })
        .then(() => {
          notSavedExistMatter.splice(matterIndex, 1);
          setMatterArray(notSavedExistMatter);
          dispatch(
            setFrontMatter({
              data: notSavedExistMatter,
              loading: false,
              error: null,
            })
          );
        })
        .catch((error) => {
          console.error("Error deleting matter:", error);
        });
    } else {
      notSavedExistMatter.splice(matterIndex, 1);
      setMatterArray(notSavedExistMatter);
    }
    setFrontmatteropen(false);
  };

  const handleSectionToggle = async (section) => {
    await setOpenSections({
      ...openSections,
      [section]: !openSections[section],
    });
  };

  const addmatter = (e) => {
    e.preventDefault();
    setMatterArray([...matterArray, {}]);
  };

  useEffect(() => {
    setMatterArray(
      Array.isArray(frontmatter)
        ? frontmatter
        : frontmatter
        ? [frontmatter]
        : []
    );
  }, [frontmatter]);

  const handleclosematter = (matter) => {
    handleDeleteMatter(matter?._id);
    setFrontmatteropen(false);
  };
  const handlefrontmatter = async () => {
    await navigate(`/frontmatter/${params?.id}`);
  };

  const handlefrontmatterDetails = async (matter) => {
    
    await navigate(`/frontmatter/${params?.id}/${matter?._id?matter?._id:""}`);
  };

   const handlecreatechapter = async() => {
   await  navigate(`/createchapter/${params.id}`);
  };
  const handlecreatechapterdetails = async(chapter) => {
   await navigate(`/createchapter/${params.id}/${chapter?._id?chapter?._id:""} `);
  };
  const handleendmatterpath = async () => {
    await navigate(`/endmatter/${params?.id}`);
  };
  const handleendmatterpathdetails =async (endmatter) => {
   
   await navigate(`/endmatter/${params?.id}/${endmatter?._id?endmatter?._id:""}`);
  };


  const addEndmatter = () => {
    setEndmatters([
      ...endmatters,
      { name: `End Matter ${endmatters?.length + 1}` },
    ]);

    setOpenSections({
      ...openSections,
      endMatters: true,
    });
  };

  const handledeleteendmatter = async (index) => {
    try {
      const deleterespose = await fetchData(
        "DELETE",
        `api/delete/front-back-matter`,
        {
          frontMatterIds: [id2],
        },
        {
          "Content-Type": "application/json",
        },
        null,
        () => {},
        "DELETE"
      );
      dispatch(
        setstoreendmattersdetails({
          data: [],
          loading: false,
          error: null,
        })
      );
      const updatedEndMatters = [...endmatters];

      updatedEndMatters.splice(index, 1);

      dispatch(
        setstoreendmattersdetails({
          data: [updatedEndMatters[index - 1]],
          loading: false,
          error: null,
        })
      );
      setEndmatters(updatedEndMatters);
      setFrontmatteropen(false);
      setDeleteIndex(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEndMatter = (index) => {
    dispatch(
      setstoreendmattersdetails({
        data: [endmatters[index]],
        error: null,
        loading: false,
      })
    );
  };

 
  useEffect(() => {
    setEndmatters(updateendmatter?.data);
  }, [updateendmatter?.data]);
  const handleBothFunctions = () => {
    handleclosematter(matterIds);
    handledeleteendmatter(deleteIndex);
  };
  const handleBothFunctionsfront = async () => {
    await handleSectionToggle("frontMatter");
    await handlefrontmatter();
  };
  return (
    <>
      <div className={SidebarStyle.sidebar}>
        <Nav defaultActiveKey="/dashboard" className="flex-column">
          <Nav.Link className={SidebarStyle.nav_link} href="/dashboard">
            <span>
              <span>
                <img style={{ marginRight: "5px" }} src={layer} />
              </span>
              Back To Dashboard
            </span>
          </Nav.Link>

          <Nav.Link
            className={SidebarStyle.nav_link}
            href="#"
            onClick={() => {
              handleBothFunctionsfront();
            }}
          >
            <span>
              <img style={{ marginRight: "5px" }} src={vector} />
              <span style={{ paddingTop: "4px" }}>Front Matter</span>
            </span>

            <span style={{ fontSize: "20px" }} onClick={addmatter}>
              +
            </span>
          </Nav.Link>
          {openSections.frontMatter && (
            <>
              {matterArray.map((matter, index) => (
                <Nav.Link
                  key={index}
                  className={SidebarStyle.nav_link}
                  href=""
                  onClick={() => {
                    handleMatterClick(matter);
                    handlefrontmatterDetails(matter);
                  }}
                >
                  {matter?.Title ? (
                    <div>
                      {" "}
                      {matter?.Title.length > 10
                        ? matter?.Title.substring(0, 10) + "..."
                        : matter?.Title}
                    </div>
                  ) : (
                    <div>Front Matter </div>
                  )}

                  <span>
                    <img
                      style={{ marginRight: "5px" }}
                      src={Delete}
                      alt="delete"
                      onClick={() => {
                        setmatterIndex(index);
                        setFrontmatteropen(true);
                      }}
                    />
                  </span>
                </Nav.Link>
              ))}
            </>
          )}

          <Nav.Link
            className={SidebarStyle.nav_link}
            href="#"
            onClick={() => {
              handleSectionToggle("chapters");
              handlecreatechapter();
            }}
          >
            <span
              onClick={() => {
                handleSectionToggle("chapters");
              }}
            >
              <span
                onClick={() => {
                  handleSectionToggle("chapters");
                }}
              >
                <img style={{ marginRight: "5px" }} src={vector} />
              </span>
              <span>Chapters</span>
            </span>
            <span style={{ fontSize: "20px" }} onClick={addChapter}>
              +
            </span>
          </Nav.Link>
          {openSections.chapters && (
            <>
              {chaptersArray.map((chapter, index) => (
                <Nav.Link
                  key={index}
                  className={SidebarStyle.nav_link}
                  href=""
                  onClick={() => {
                    handleChapterClick(chapter);
                    handlecreatechapterdetails(chapter);
                  }}
                >
                  {chapter?.title ? (
                    <div>
                      {chapter?.title.length > 10
                        ? chapter?.title.substring(0, 10) + "..."
                        : chapter?.title}
                    </div>
                  ) : (
                    <div>Chapter </div>
                  )}

                  <span>
                    <img
                      style={{ marginRight: "5px" }}
                      src={Delete}
                      alt="delete"
                      onClick={() => {
                        setChapterIndex(index);
                        setOpen(true);
                      }}
                    />
                  </span>
                </Nav.Link>
              ))}
            </>
          )}
          <Nav.Link
            className={SidebarStyle.nav_link}
            onClick={() => {
              handleSectionToggle("endMatters");
              handleendmatterpath();
            }}
            href="#"
          >
            <span>
              <img style={{ marginRight: "5px" }} src={vector} />
              <span> End Matter</span>
            </span>
            <span
              onClick={(e) => {
                e.stopPropagation();
                addEndmatter();
              }}
              style={{ fontSize: "20px" }}
            >
              {"+"}
            </span>
          </Nav.Link>
          {openSections.endMatters && (
            <>
              {Array.isArray(endmatters) &&
                endmatters.map((endmatter, index) => (
                  <Nav.Link
                    key={index}
                    className={SidebarStyle.nav_link}
                    href=""
                    onClick={() => {
                      handleEndMatter(index);
                      handleendmatterpathdetails(endmatter);
                    }}
                  >
                    {endmatter?.Title ? (
                      <div>
                        {endmatter?.Title.length > 10
                          ? endmatter?.Title.substring(0, 10) + "..."
                          : endmatter?.Title}
                      </div>
                    ) : (
                      <div>End Matter </div>
                    )}
                    <span>
                      <img
                        style={{ marginRight: "5px" }}
                        src={Delete}
                        alt="delete"
                        onClick={() => {
                          setDeleteIndex(index);
                          setFrontmatteropen(true);
                        }}
                      />
                    </span>
                  </Nav.Link>
                ))}
            </>
          )}
        </Nav>
      </div>

      <Modal show={open} onHide={() => setOpen(false)} centered>
        <div className={mybookStyle.popup_content_box}>
          <div className={mybookStyle.popup_text_content}>
            Are you sure you want to delete?
          </div>
          <div className={mybookStyle.popup_btns}>
            <Button
              className={mybookStyle.popup_ok_btn}
              variant="secondary"
              onClick={() => handleclose(chapterId)}
            >
              {" "}
              Ok
            </Button>
            <Button
              className={mybookStyle.popup_cancel_btn}
              variant="primary"
              onClick={() => setOpen(false)}
            >
              {" "}
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        show={frontmatteropen}
        onHide={() => setFrontmatteropen(false)}
        centered
      >
        <div className={mybookStyle.popup_content_box}>
          <div className={mybookStyle.popup_text_content}>
            Are you sure you want to delete?
          </div>
          <div className={mybookStyle.popup_btns}>
            <Button
              className={mybookStyle.popup_ok_btn}
              variant="secondary"
              onClick={handleBothFunctions}
            >
              Ok
            </Button>
            <Button
              className={mybookStyle.popup_cancel_btn}
              variant="primary"
              onClick={() => setFrontmatteropen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SideNavBar;
