import { useState, useEffect } from "react";
import DashboardStyle from "../../assets/css/Dashboard.module.css";
import Table from "react-bootstrap/Table";
import Chart from "../common/Chart";
import Sidebar from "../common/Sidebar";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setmyrecentbook } from "../../redux/slice/myrecentbookSlice";
import useApi from "../../hook/useApi";
import dummyImage from "../../assets/images/dummyImage.png";
import { setcollobrators } from "../../redux/slice/collobrators";

function Dashboard() {
  const [validated, setValidated] = useState(false);
  const collobratorsdata = useSelector((state) => state?.collobrators?.data?.notifications);
   const recentbook = useSelector((state) => state.myrecentbook?.data?.books);
  const userDetailsdata = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const userID = userDetailsdata?.user?._id;
  const { fetchData } = useApi();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false){
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };
  useEffect(() => {
     const fetchRecentBooks = async () => {
      setLoading(true);
      try {
        const bookData = await fetchData(
          "GET",
          `api/book-list/${userID}?page=1&limit=5`
        );
        setLoading(false);
        dispatch(
          setmyrecentbook({ data: bookData, loading: false, error: null })
        );
      } catch (error) {
        dispatch(setmyrecentbook({ data: [], loading: false, error }));
      }
    };
    if (userID) {
      fetchRecentBooks();
    }
  }, []);
  useEffect(() => {
    const fetchcollabrators = async () =>{
      setLoading(true);
      try {
        const collobatorsdata = await fetchData(
          "GET",
          `api/notification/${userID.trim()}`
        );
        setLoading(false);
         dispatch(
            setcollobrators({
              data: collobatorsdata,
              loading: false,
              error: null,
            })
          );
        dispatch(
          setcollobrators({
            data: collobatorsdata,
            loading: false,
            error: null,
          })
        );
      } catch (error) {
        dispatch(setcollobrators({ data: [], loading: false, error }));
      }
    };
    if (userID) {
      fetchcollabrators();
    }
  }, []);

  const handleaccept = async (data) => {
    const acceptcollobators = async () => {
      setLoading(true);
      try {
        const collobratoraccept = await fetchData(
          "POST",
          `api/handle-invitation/${userID}`,
          {
            bookId: `${data?.body?.bookId}`,
            action: "accept",
          }
        );
      setLoading(false);
      } catch (error){
        console.log(error);
      }
    };
   await acceptcollobators();
      
      const updategetnotifications = async (data)=>{
        setLoading(true);
        try{
          const updatenotifications = await fetchData("GET" , `api/notification/${userID}`)
          setLoading(false);
          dispatch(
              setcollobrators({
                data: updatenotifications,
                loading: false,
                error: null,
              })
            );
         }catch(error){
          console.log(error)
        }
      }
   await  updategetnotifications();
      }
  

  const handlereject = async(data) => {
    const rejectcollobators = async () => {
      setLoading(true);
      try {
        const collobratorreject = await fetchData(
          "POST",
          `api/handle-invitation/${userID}`,
          {
            bookId: `${data?.body?.bookId}`,
            action: "reject",
          }
        );
        setLoading(false);
      } catch (error){
        console.log(error);
      }
    };
  await  rejectcollobators();
    const updategetnotifications = async (data)=>{
      setLoading(true);
      try{
        const updatenotifications = await fetchData("GET" , `api/notification/${userID}`)
        setLoading(false);
        dispatch(
            setcollobrators({
              data: updatenotifications,
              loading: false,
              error: null,
            })
          );
       }catch(error){
        console.log(error)
      }
    }
    await updategetnotifications();
  };
  return (
    <>
      <div className="common-container">
        <div className={DashboardStyle.change_password_page}>
          <Sidebar />
          <div className={DashboardStyle.change_password_right}>
            <div className={DashboardStyle.dashboard_page_content}>
              <div className={DashboardStyle.dashboard_top_section}>
                <div className={DashboardStyle.dashboard_top_box}>
                  <div
                    className={`${DashboardStyle.dashboard_top_box_text} box_text01`}
                  >
                    <strong>150</strong>
                    <span>Books in progress</span>
                  </div>
                </div>
                <div className={DashboardStyle.dashboard_top_box}>
                  <div
                    className={`${DashboardStyle.dashboard_top_box_text} ${DashboardStyle.box_text02} `}
                  >
                    <strong>180</strong>
                    <span>Completed / Published books</span>
                  </div>
                </div>
                <div className={DashboardStyle.dashboard_top_box}>
                  <div
                    className={`${DashboardStyle.dashboard_top_box_text} ${DashboardStyle.box_text03} `}
                  >
                    <strong>140</strong>
                    <span>Authors</span>
                  </div>
                </div>
              </div>
              <div className={DashboardStyle.create_view_all_btn}>
                <NavLink
                  to="/createbookoptions"
                  className={DashboardStyle.create_newbook}
                >
                  Create a new book
                </NavLink>
                <a href="/mybooks" className={DashboardStyle.view_all_btn}>
                  View all books
                </a>
              </div>
              <div className={DashboardStyle.dashboard_recent_book}>
                <h2>My recent book</h2>
                <div className={DashboardStyle.dashboard_recent_section}>
                  {loading && (
                    <div
                      className="text-center"
                      style={{ marginTop: "90px", marginLeft: "300px" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                  )}
                  {recentbook?.length > 0 ?(
                    <>
                      {Array.isArray(recentbook) &&
                        recentbook.map((book) => (
                          <div className={DashboardStyle.recent_book_box}>
                            <div
                              className={DashboardStyle.recent_book_text_box}
                            >
                              <div
                                className={DashboardStyle.recent_book_text_img}
                              >
                                <img
                                  style={{ borderRadius: "5px", width: "100%", height:"164px" }}
                                  src={book.BookCoverImage || dummyImage}
                                  alt="Recent Book"
                                />
                              </div>
                              <span>{book.title}</span>
                            </div>
                          </div>
                        ))}
                    </>
                  ):(
                    <h1 style={{ marginLeft: "180px", fontSize: "20px" }}>
                     No Book Found
                    </h1>
                  )}
                </div>
              </div>

              <div className={DashboardStyle.dashboard_recent_book}>
                <h2>My Package</h2>
                <div className={DashboardStyle.dashboard_table_main}>
                  <div className={DashboardStyle.dashboard_table}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Amount</th>
                          <th>Package Type</th>
                          <th>Start date</th>
                          <th>End date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Gold</td>
                          <td>400</td>
                          <td>Paid</td>
                          <td>20/05/23</td>
                          <td>30/05/23</td>
                        </tr>
                        <tr>
                          <td>Gold</td>
                          <td>400</td>
                          <td>Paid</td>
                          <td>20/05/23</td>
                          <td>30/05/23</td>
                        </tr>
                        <tr>
                          <td>Gold</td>
                          <td>400</td>
                          <td>Paid</td>
                          <td>20/05/23</td>
                          <td>30/05/23</td>
                        </tr>
                        <tr>
                          <td>Gold</td>
                          <td>400</td>
                          <td>Paid</td>
                          <td>20/05/23</td>
                          <td>30/05/23</td>
                        </tr>
                        <tr>
                          <td>Gold</td>
                          <td>400</td>
                          <td>Paid</td>
                          <td>20/05/23</td>
                          <td>30/05/23</td>
                        </tr>
                        <tr>
                          <td>Gold</td>
                          <td>400</td>
                          <td>Paid</td>
                          <td>20/05/23</td>
                          <td>30/05/23</td>
                        </tr>
                        <tr>
                          <td>Gold</td>
                          <td>400</td>
                          <td>Paid</td>
                          <td>20/05/23</td>
                          <td>30/05/23</td>
                        </tr>
                        <tr>
                          <td>Gold</td>
                          <td>400</td>
                          <td>Paid</td>
                          <td>20/05/23</td>
                          <td>30/05/23</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className={DashboardStyle.dashboard_recent_book}>
                <h2>Notifications</h2>
                <div className={DashboardStyle.dashboard_table_main}>
                  <div className={DashboardStyle.dashboard_table}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Message</th>
                          <th colSpan={2}>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(collobratorsdata) &&
                          collobratorsdata.map((colo) => {
                            return !colo?.notificationAction && (
                              <tr>
                                <td>{colo.body.message}</td>
                                <>
                                 <td>
                                    <span
                                      className={DashboardStyle.hoverableaccept}
                                      style={{
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                      onClick={()=>handleaccept(colo)}
                                    >
                                      Accept
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={DashboardStyle.hoverablereject}
                                      style={{
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handlereject(colo)}
                                    >
                                      Reject
                                    </span>
                                  </td>
                                </>
                              </tr>
                            )
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className={DashboardStyle.dashboard_recent_book}>
                <h2>Recent Activity</h2>
                <div className={DashboardStyle.dashboard_table_main}>
                  <div className={DashboardStyle.dashboard_table}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Action</th>
                          <th>Date & Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Worlds Together</td>
                          <td className={DashboardStyle.wdit_delete_btn}>
                            <a href="/" className={DashboardStyle.edit_btn}>
                              Edit
                            </a>
                            <a href="/" className={DashboardStyle.delete_btn}>
                              Delete
                            </a>
                          </td>
                          <td className={DashboardStyle.date_month}>
                            <span>20/05/23</span>
                            <span>10:38 PM</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Worlds Together</td>
                          <td className={DashboardStyle.wdit_delete_btn}>
                            <a href="/" className={DashboardStyle.edit_btn}>
                              Edit
                            </a>
                            <a href="/" className={DashboardStyle.delete_btn}>
                              Delete
                            </a>
                          </td>
                          <td className={DashboardStyle.date_month}>
                            <span>20/05/23</span>
                            <span>10:38 PM</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Worlds Together</td>
                          <td className={DashboardStyle.wdit_delete_btn}>
                            <a href="/" className={DashboardStyle.edit_btn}>
                              Edit
                            </a>
                            <a href="/" className={DashboardStyle.delete_btn}>
                              Delete
                            </a>
                          </td>
                          <td className={DashboardStyle.date_month}>
                            <span>20/05/23</span>
                            <span>10:38 PM</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Worlds Together</td>
                          <td className={DashboardStyle.wdit_delete_btn}>
                            <a href="/" className={DashboardStyle.edit_btn}>
                              Edit
                            </a>
                            <a href="/" className={DashboardStyle.delete_btn}>
                              Delete
                            </a>
                          </td>
                          <td className={DashboardStyle.date_month}>
                            <span>20/05/23</span>
                            <span>10:38 PM</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Worlds Together</td>
                          <td className={DashboardStyle.wdit_delete_btn}>
                            <a href="/" className={DashboardStyle.edit_btn}>
                              Edit
                            </a>
                            <a href="/" className={DashboardStyle.delete_btn}>
                              Delete
                            </a>
                          </td>
                          <td className={DashboardStyle.date_month}>
                            <span>20/05/23</span>
                            <span>10:38 PM</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Worlds Together</td>
                          <td className={DashboardStyle.wdit_delete_btn}>
                            <a href="/" className={DashboardStyle.edit_btn}>
                              Edit
                            </a>
                            <a href="/" className={DashboardStyle.delete_btn}>
                              Delete
                            </a>
                          </td>
                          <td className={DashboardStyle.date_month}>
                            <span>20/05/23</span>
                            <span>10:38 PM</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Worlds Together</td>
                          <td className={DashboardStyle.wdit_delete_btn}>
                            <a href="/" className={DashboardStyle.edit_btn}>
                              Edit
                            </a>
                            <a href="/" className={DashboardStyle.delete_btn}>
                              Delete
                            </a>
                          </td>
                          <td className={DashboardStyle.date_month}>
                            <span>20/05/23</span>
                            <span>10:38 PM</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Worlds Together</td>
                          <td className={DashboardStyle.wdit_delete_btn}>
                            <a href="/" className={DashboardStyle.edit_btn}>
                              Edit
                            </a>
                            <a href="/" className={DashboardStyle.delete_btn}>
                              Delete
                            </a>
                          </td>
                          <td className={DashboardStyle.date_month}>
                            <span>20/05/23</span>
                            <span>10:38 PM</span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className={DashboardStyle.dashboard_recent_book}>
                <h2>Progress Tracker</h2>
                <div className={DashboardStyle.dashboard_chart_section}>
                  <div className={DashboardStyle.dashboard_chart_left}>
                    <div className={DashboardStyle.chart_text}>
                      <span className={DashboardStyle.chart_icon}></span>
                      <div className={DashboardStyle.text}>
                        <span>Completed</span>
                        <span>40</span>
                      </div>
                    </div>
                    <div className={DashboardStyle.chart_text}>
                      <span className={DashboardStyle.chart_icon2}></span>
                      <div className={DashboardStyle.text}>
                        <span>Pending</span>
                        <span>30</span>
                      </div>
                    </div>
                    <div className={DashboardStyle.chart_text}>
                      <span className={DashboardStyle.chart_icon3}></span>
                      <div className={DashboardStyle.text}>
                        <span>In progress</span>
                        <span>30</span>
                      </div>
                    </div>
                  </div>
                  <div className={DashboardStyle.dashboard_chart_right}>
                    <Chart />
                  </div>
                </div>
              </div>
              <div className={DashboardStyle.dashboard_recent_book}>
                <h2>Quick Links</h2>
                <div className={DashboardStyle.quick_links_section}>
                  <ul>
                    <li>
                      <a href="/">Account settings</a>
                    </li>
                    <li>
                      <a href="/">Subscription management</a>
                    </li>
                    <li>
                      <a href="/">Help and Support</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
