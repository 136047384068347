import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  error: null,
  loading: false
};

export const editbookSlice = createSlice({
  name: "editbooks",
  initialState,
  reducers: {
    seteditbook: (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.loading = false;
    }
  }
});

export const { seteditbook } = editbookSlice.actions;

export default editbookSlice.reducer;
