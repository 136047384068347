import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    users : [],
    error : null,
    loading : false
}

export const signupSlice = createSlice({
    name : "signup",
    initialState,
    reducers :{
        setSignup :(state , action) =>{
            state.users = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        }
    }
})

export const {setSignup } = signupSlice.actions;
export default signupSlice.reducer;