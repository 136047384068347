import { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import Sidebar from "../common/Sidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { setcreatenewbookwithai } from "../../redux/slice/CreatenewbookwithaiSlice";
import useApi from "../../hook/useApi";
import Spinner from "react-bootstrap/Spinner";

function CreatnewbookwithAI() {
  const validationSchema = Yup.object().shape({
    language: Yup.string().required(" Required"),
    authorname: Yup.string().required(" Required"),
    genre: Yup.string().required(" Required"),
    bookSize: Yup.string().required("Required"),
    bindingType: Yup.string().required("Required"),
    bookInterior: Yup.string().required("Required"),
    paperType: Yup.string().required("Required"),
    bookCoverLamination: Yup.string().required(" Required"),
    targetAudience: Yup.string().required(" Required "),
    writingStyling: Yup.string().required(" Required"),
    preferredNumberOfChapters: Yup.number()
      .required("Only numeric values are allowed")
      .typeError("Only numeric values are allowed"),
    numberOfWordsPerChapter: Yup.number()
      .required("Only numeric values are allowed")
      .typeError("Only numeric values are allowed"),
    userMail: Yup.string().required(" Required"),
  });
  const adminApiBaseUrl = process.env.REACT_APP_API_BASE_ADMIN_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchData } = useApi();
  const [languageapi, setLanguageapi] = useState("");
  const [bookgenreapi, setBookgenreapi] = useState("");
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const userid = userData?.user?._id;
  const userDetailsdata = JSON.parse(
    localStorage.getItem("userDetails") || "{}"
  );

  const authorname = userDetailsdata?.user?.FirstName;
  const userMail = userDetailsdata?.user?.Email;

  const [formData, setFormData] = useState({
    authorname: authorname,
    genre: "",
    language: "",
    authorId: [],
    bookSize: "A1",
    bindingType: "SoftBinding",
    bookInterior: "BlackAndWhite",
    paperType: "Glossy",
    bookCoverLamination: "MattFinish",
    BookCoverImage: "",
    targetAudience: "",
    writingStyling: "",
    preferredNumberOfChapters: "",
    numberOfWordsPerChapter: "",
    userMail: userMail,
  });
  const isLoggedIn = !!userid;

  const handlechange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: name === "authorid" ? userid : value,
    });
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setValidated(true);

      if (!isLoggedIn) {
        navigate("/login");
        console.log(
          "User not logged in. Redirect to login page or show message."
        );
        return;
      }

      let requestBody = { ...formData, authorId: [userid] };
      setLoading(true);
      dispatch(
        setcreatenewbookwithai({
          data: requestBody,
          error: null,
          loading: false,
        })
      );
      navigate("/aioptions");
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
        error.inner.forEach((e) => {
          // toast.error(e.message);
        });
        setValidated(false);
        setLoading(false);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const getBase64Image = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const imageBase64 = await getBase64Image(file);
        setFormData({
          ...formData,
          BookCoverImage: imageBase64,
        });
      } catch (error) {
        console.error("Error converting image to base64:", error);
      }
    }
  };

  const editorRef = useRef(null);
  useEffect(() => {
    const editorInstance = editorRef.current?.getInstance();
    const onLoad = () => {
      if (editorInstance) {
        editorInstance.addText("Hello, World!", {
          styles: {
            fill: "#000",
            fontSize: 20,
            fontWeight: "bold",
          },
        });
      }
    };
    if (editorInstance) {
      editorInstance.on("loadImage", onLoad);
    }
    return () => {
      if (editorInstance) {
        editorInstance.off("loadImage", onLoad);
      }
    };
  }, []);
  useEffect(() => {
    const handlelangagugesapi = async () => {
      try {
        setLoading(true);
        const languagerespose = await fetchData(
          "GET",
          `all-language?page=1&limit=10`,
          null,
          null,
          null,
          () => {},
          "GET",
          adminApiBaseUrl
        );
        console.log(languagerespose, " languagerespose");
        const langugedata = languagerespose.data;
        console.log(langugedata, "   langugedata");
        setLanguageapi(langugedata);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    handlelangagugesapi();
    const handlebookgenreapi = async () => {
      try {
        setLoading(true);
        const bookgenrerespose = await fetchData(
          "GET",
          `all-BookGenre?page=1&limit=10`,
          null,
          null,
          null,
          () => {},
          "GET",
          adminApiBaseUrl
        );
        console.log(bookgenrerespose, "  bookgenrerespose");

        setBookgenreapi(bookgenrerespose?.data);
        setLoading(true);
      } catch (error) {
        console.log(error);
      }
    };
    handlebookgenreapi();
  }, []);

  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}>
              <h2>Create New Book</h2>
              <p>Create a book & start adding chapters and characters</p>
            </div>

            <div className={createnewbookStyle.chapter_one_section}>
              <div className={createnewbookStyle.mybook_top_section}>
                <h3>Book Details</h3>
              </div>
              <div className={createnewbookStyle.book_details_from}>
                <Form noValidate onSubmit={handleSubmit}>
                  <>
                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="12">
                        <Form.Label>Author Name *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter author name"
                          name="authorname"
                          value={formData.authorname}
                          onChange={handlechange}
                          isInvalid={!!errors.authorname}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.authorname}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    <Row
                      className={`mb-6 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="6">
                        <Form.Label>Language *</Form.Label>
                        <Form.Select
                          required
                          defaultValue=""
                          name="language"
                          value={formData.language}
                          onChange={handlechange}
                          isInvalid={!!errors.language}
                        >
                          <option value="" disabled>
                            Select language
                          </option>
                        
                            {Array.isArray(languageapi) &&
                            languageapi?.map((item) => (
                              <option>{item.name}</option>
                            ))
                          }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.language}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    <Row
                      className={`mb-6 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="6">
                        <Form.Label>Target Audience *</Form.Label>
                        <Form.Select
                          required
                          defaultValue=""
                          name="targetAudience"
                          value={formData.targetAudience}
                          onChange={handlechange}
                          isInvalid={!!errors.targetAudience}
                        >
                          <option value="" disabled>
                            Audience
                          </option>
                          <option value="18">18 year</option>
                          <option value="24">24 year</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.language}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="6">
                        <Form.Label>Book Genre *</Form.Label>
                        <Form.Select
                          required
                          defaultValue=""
                          name="genre"
                          value={formData.genre}
                          onChange={handlechange}
                          isInvalid={!!errors.genre}
                        >
                          <option value="" disabled>
                            Select genre
                          </option>
                       
                           { Array.isArray(bookgenreapi) &&
                            bookgenreapi?.map((item) => (
                              <option>{item?.BookGenre_title}</option>
                            ))
                          }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.genre}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>

                    <Row
                      className={`mb-6 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="6">
                        <Form.Label>Writing Style *</Form.Label>
                        <Form.Select
                          required
                          defaultValue=""
                          name="writingStyling"
                          value={formData.writingStyling}
                          onChange={handlechange}
                          isInvalid={!!errors.writingStyling}
                        >
                          <option value="" disabled>
                            Select writing style
                          </option>
                          <option value="narrative">Narrative</option>
                          <option value="expository">Expository</option>
                          <option value="descriptive">Descriptive</option>
                          <option value="persuasive">Persuasive</option>
                          <option value="creative">Creative</option>
                          <option value="technical">Technical</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.writingStyling}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="12">
                        <Form.Label>Preferred no. of Chapters*</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder=""
                          name="preferredNumberOfChapters"
                          value={formData.preferredNumberOfChapters}
                          onChange={handlechange}
                          isInvalid={!!errors.preferredNumberOfChapters}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.preferredNumberOfChapters}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>

                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="12">
                        <Form.Label> Chapter Length (in Words)*</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder=""
                          name="numberOfWordsPerChapter"
                          value={formData.numberOfWordsPerChapter}
                          onChange={handlechange}
                          isInvalid={!!errors.numberOfWordsPerChapter}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.numberOfWordsPerChapter}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="12">
                        <Form.Label>Email for Updates*</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="user@example.com"
                          name="userMail"
                          value={formData.userMail}
                          onChange={handlechange}
                          isInvalid={!!errors.userMail}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.userMail}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </>

                  <Row
                    className={`mb-12 ${createnewbookStyle.save_draft_btns_section}`}
                  >
                    <Col md="6">
                      <Button
                        onClick={handleSubmit}
                        className={createnewbookStyle.finish_btn}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatnewbookwithAI;
