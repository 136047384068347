import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: [],
    error: null,
    loading: false
}

export const myBookSlice = createSlice({
    name: "myBookSlice",
    initialState,
    reducers: {
        setmyBook: (state, action) => {
            state.user = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error = action.payload?.error;
        }
    }
});

export const { setmyBook } = myBookSlice.actions;
export default myBookSlice.reducer;
