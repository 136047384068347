import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  error: null,
  loading: false,
  paragraph:{}
};

export const chapterDetailsSlice = createSlice({
  name: 'chapterDetails',
  initialState,
  reducers: {
    storeChapterDetails: (state, action) => {
      state.data = action.payload?.data;
      state.loading = action.payload?.loading;
      state.error = action.payload?.error;
    },
    storeParagraphDetails: (state, action) => {
      state.paragraph = action.payload?.paragraph;
      state.loading = action.payload?.loading;
      state.error = action.payload?.error;
    },
    getstoreChapterDetails:(state,action)=>{
      state.data = action.payload?.data;
      state.loading = action.payload?.loading;
      state.error = action.payload?.error;
    }
  }
});

export const { storeChapterDetails, storeParagraphDetails ,getstoreChapterDetails} = chapterDetailsSlice.actions;
export default chapterDetailsSlice.reducer;
