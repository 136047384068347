import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../hook/Auth';
import AuthLayout from '../hook/authlayout';
import { authlayoutRoutes } from '../utils/constants';
const AllRoutes = () =>{
  return (
    <BrowserRouter>
      <AuthProvider>
        <AuthLayout>
          <Routes>
            {authlayoutRoutes.map((item, index) =>(
              <Route key={index} path={item.path} element={item.element} />
            ))}
          </Routes>
        </AuthLayout>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default AllRoutes;
