import React, { useEffect } from "react";
import { useState } from "react";
import SideNavBar from "../Createbooksidebar/Createbooksidebar";
import SidebarStyle from "../../assets/css/Sidebar.module.css";
import manUser from "../../assets/images/man-user.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import {
  addChapter,
  setCreateChapter,
} from "../../redux/slice/createChapterSlice";
import useApi from "../../hook/useApi";
import { storeChapterDetails } from "../../redux/slice/chapterDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import notificationImage from "../../assets/images/notification-img.png";
import headerStyle from "../../assets/css/header.module.css";
import { useAuth } from "../../hook/Auth";

export default function CreateChapter() {
  const userDetailsdata = JSON.parse(
    localStorage.getItem("userDetails") || "{}"
  );
  const auth = useAuth();
  const navigate = useNavigate();
  const editedchapterdata = useSelector(
    (state) => state?.chapterDetails?.data?.updateData
  );
  const params = useParams();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const chaptertitle = useSelector(
    (state) => state?.chapterDetails?.data?.title
  );
  const chapterdescription = useSelector(
    (state) => state?.chapterDetails?.data?.description
  );

  const selectedChapters = useSelector(
    (state) => state?.chapterDetails?.data || {}
  );

  const selectedChapter = useSelector((state) => state?.chapterDetails?.data);
  const idupdate = selectedChapter[0]?._id;
  const bookid = useSelector((state) => state?.createbook?.data?.data?._id);
  const [loadingpage, setLoadingPage] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const userid = userData?.user?._id;
  const chapterId = useSelector((state) => state?.chapterDetails?.data?._id);
  const { fetchData } = useApi();
  const dispatch = useDispatch();
  const [paragraph, setParagraph] = useState("");
  const FirstName = userDetailsdata?.user?.FirstName;
  const LastName = userDetailsdata?.user?.LastName;
  const [formData, setFormData] = useState([
    {
      title: selectedChapters?.title || "" || chaptertitle,
      description:
        paragraph || selectedChapters?.description || chapterdescription,
      fileUrl: selectedChapters?.fileUrl || "",
      authorId: userid,
      bookId: bookid,
    },
  ]);
  const chapteridforfinish = selectedChapter?._id;
  useEffect(() => {
    setLoadingPage(true);
    setTimeout(() => {
      setFormData({
        title:
          selectedChapters?.title ||
          "" ||
          chaptertitle ||
          editedchapterdata?.title,
        description:
          selectedChapters?.description || chapterdescription || paragraph,
        fileUrl: selectedChapters?.fileUrl || "",
        authorId: userid,
        bookId: bookid,
      });
      setLoadingPage(false);
    }, 100);
  }, [
    selectedChapters?.title,
    selectedChapters?.description,
    selectedChapters?.fileUrl,
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    try {
      setLoading(true);

      const chapterData = {
        title: formData.title || selectedChapters.title,
        description: formData.description,
        FileUrl: formData.fileUrl,
        authorId: userid,
        bookId: params.id,
      };

      const payload = {
        chapters: [chapterData],
      };

      let response;
      if (params?.chapterid || selectedChapter?._id) {
        response = await fetchData(
          "PATCH",
          `api/update-chapter/${params?.chapterid || selectedChapter?._id}`,
          {
            ...formData,
            authorId: userid,
            bookId: params?.id,
          },
          {
            "Content-Type": "application/json",
          },
          setCreateChapter
        );
      } else {
        response = await fetchData(
          "POST",
          "api/create-chapter",
          payload,
          null,
          null,
          () => {
            console.log("done");
          },
          "POST"
        );
      }

      if (response && response.data) {
        let newChapter = {
          ...response?.data,
          title: formData.title,
          description: formData.description,
        };

        dispatch(
          storeChapterDetails({
            data: { ...newChapter },
            error: null,
            loading: false,
          })
        );

        setFormData({
          title: "",
          description: "",
          fileUrl: "",
          authorId: "",
          bookId: "",
        });
        setValidated(false);

        const bookdata = await fetchData(
          "GET",
          `api/book/${params?.id}`,
          null,
          {},
          null,
          () => {},
          "GET"
        );

        dispatch(
          setCreateChapter({
            data: bookdata.chapters,
            loading: false,
            error: null,
          })
        );
      }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result?.toString().split(",")[1];
        setFormData({
          ...formData,
          fileUrl: base64String,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handlechange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleparagraph = (chapter) => {
    if (!chapterId) {
      toast.error("please select chapter");
    } else {
      const response = fetchData("GET", `api/paragraph/${chapterId}`);
      if (response?.data) {
        const paragraphs = response.data.Paragraph;
        const descriptions = paragraphs
          .map((para) => para.description)
          .join("\n");
        setFormData(descriptions);
      }
      setParagraph(response?.data?.Paragraph);
      navigate(`/viewparagraph/${chapterId}`);
    }
  };
  const details = useSelector((state) => state?.createchapter?.data);

  useEffect(() => {
    try {
      const response = fetchData("GET", `api/chapter/${selectedChapter?._id}`)
        .then((res) => {
          const paragraphs = res.data;
          let { title } = res.data;
          if (paragraphs) {
            const paragraph = paragraphs?.Paragraph;
            const descriptions = paragraph.map(
              (paragraph) => paragraph.description
            );
            const joinedDescriptions = descriptions.join("<br/>");

            dispatch(
              storeChapterDetails({
                data: {
                  _id: selectedChapter?._id,
                  title,
                  description: joinedDescriptions,
                },
                error: null,
                loading: false,
              })
            );
          } else {
          }
        })
        .catch((err) => {});
    } catch (error) {
      console.error("Error fetching paragraph data:", error);
    }
  }, []);
  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };
  const handleeditprofile = () => {
    navigate("/editprofilepage");
  };

  const handleeditbookhistory = (e) => {
    if (!chapterId) {
      toast.error("please select chapter");
    } else {
      navigate(`/editbookhistory/${params?.id}/${params?.chapterid}`);
    }
  };
  const handleformat = () => {
    navigate(`/chapterformat/${params?.id}`);
  };
  return (
    <>
      <div>
        <SideNavBar />
        <div className={SidebarStyle.book_section}>
          <div className={SidebarStyle.nav_section}>
            <div className={SidebarStyle.nav_item}>Chapters</div>

            <div
              className="header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <img src={notificationImage} />
              <div
                className="avatar"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#ccc",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <img src={manUser} />
              </div>

              <div className="user-info" style={{ display: "inline-block" }}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    className={headerStyle.welcome}
                    style={{
                      marginRight: "10px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Welcome, {FirstName} {LastName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                      onClick={handleeditprofile}
                    >
                      Edit Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleLogout}
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="/dashboard"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Delete Account
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          {loadingpage ? (
            <div>{"Loading..."}</div>
          ) : (
            <>
              <div className={createnewbookStyle.book_details_from}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row
                    className={`mb-12 ${createnewbookStyle.book_details_select}`}
                  >
                    <Col md="10" style={{ marginLeft: "50px" }}>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter your book title"
                        name="title"
                        value={formData.title}
                        onChange={handlechange}
                      />
                    </Col>
                  </Row>
                  <Row
                    className={`mb-12 ${createnewbookStyle.book_details_select}`}
                  >
                    <Col
                      md="10"
                      style={{ marginLeft: "50px", marginTop: "0px" }}
                    >
                      <ReactQuill
                        theme="snow"
                        value={
                          formData.description ||
                          chapterdescription ||
                          paragraph ||
                          " "
                        }
                        onChange={(value) =>
                          setFormData({ ...formData, description: value })
                        }
                        style={{
                          height: 200,
                        }}
                      />
                    </Col>
                  </Row>
                  {/* <Row
                    className={`mb-12 ${createnewbookStyle.book_details_select}`}
                  >
                    <Col md="10" style={{ marginLeft: "50px" }}>
                      <Form.Group controlId="formFilesm" className=" mt-3">
                        <Form.Label> file Image</Form.Label>
                        <Form.Control
                          type="file"
                          size="sm"
                          onChange={handleFileChange}
                        />
                      </Form.Group>
                      {formData.fileUrl && (
                        <div>
                          <img
                            src={`data:image/jpeg;base64,${formData.fileUrl}`}
                            alt="Preview"
                            style={{ maxWidth: "50%", maxHeight: "100px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row> */}
                  <div
                    style={{ marginBottom: "50px", marginLeft: "50px" }}
                    className={createnewbookStyle.save_draft_btns_section}
                  >
                    <div className={createnewbookStyle.next_cancel_btns}>
                      <Button
                        type="submit"
                        className={createnewbookStyle.finish_btn}
                        disabled={loading}
                        style={{
                          cursor: loading ? "not-allowed" : "pointer",
                          opacity: loading ? 0.5 : 1,
                        }}
                      >
                        {loading ? (
                          <div className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        ) : (
                          "Save Draft"
                        )}
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleparagraph();
                        }}
                        type="submit"
                        className={createnewbookStyle.finish_btn}
                      >
                        Paragragh view
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          handleeditbookhistory(e);
                        }}
                        type="submit"
                        className={createnewbookStyle.finish_btn}
                      >
                        View Edit History
                      </Button>
                      {(idupdate || chapteridforfinish) && (
                        <Button
                          onClick={handleformat}
                          style={{ backgroundColor: "green", color: "white" }}
                          type="button"
                          className={createnewbookStyle.finish_btn}
                        >
                          Finish
                        </Button>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
