import {createSlice} from '@reduxjs/toolkit'


const initialState = {
    data : [ ],
    error : null,
    loading : false
}

export const editbookhistorySlice = createSlice({
    name : "editbookhistory",
    initialState,
    reducers :{
        seteditbookhistory :(state , action) =>{
            state.data = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        },
        
    }
})

export const {seteditbookhistory} = editbookhistorySlice .actions
export default editbookhistorySlice .reducer;