import React, { useEffect } from "react";
import "../assets/css/editbookpage.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import createnewbookStyle from "../../src/assets/css/createnewbook.module.css";
import Sidebar from "../components/common/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import useApi from "../hook/useApi";
import { useNavigate } from "react-router-dom";
import { setCreateChapter } from "../redux/slice/createChapterSlice";
import { storeChapterDetails } from "../redux/slice/chapterDetailsSlice";
import { setId } from "../redux/slice/idSlice";
function Editbookpage(){
  const { fetchData } = useApi();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    description: "",
    genre: "",
    bookSize: "",
    bindingType: "",
    bookInterior: "",
    paperType: "",
    bookCoverLamination: "",
  });
  const bookdetail = useSelector((state) => state.editbooks?.data);
  const bookid = bookdetail._id;
  localStorage.setItem("bookid", JSON.stringify(bookid));
  console.log( bookid  , "bookidlocalstorage")
  useEffect(() => {
    if (bookdetail){
      setFormData({
        title: bookdetail.title,
        subtitle: bookdetail.subtitle,
        description: bookdetail.description,
        genre: bookdetail.genre,
        bookSize: bookdetail.bookSize,
        bindingType: bookdetail.bindingType,
        bookInterior: bookdetail.bookInterior,
        paperType: bookdetail.paperType,
        bookCoverLamination: bookdetail.bookCoverLamination,
      });
    }
  }, []);
  const handleInputChange = (e)=>{
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleeditbooks = async (e) =>{
    e.preventDefault();
    setLoading(true);
    try {
      const response = fetchData(
        "PUT",
        `api/update-book/${bookid}`,
        {
          title: formData.title,
          subtitle: formData.subtitle,
          description: formData.description,
          genre: formData.genre,
          bookSize: formData.bookSize,
          bindingType: formData.bindingType,
          bookInterior: formData.bookInterior,
          paperType: formData.paperType,
          bookCoverLamination: formData.bookCoverLamination,
        },
        null,
        null,
        () => {
          console.log("edited");
        },
        "PUT"
      );
      await response;
      setLoading(false);
      navigate("/mybooks");
    }catch (error){
      console.log(error, "error");
    }
  };
  const handleback = (e) => {
    e.preventDefault();
    navigate("/mybooks");
  };
  const handleeditchapter = () => {
    navigate(`/createchapter/${bookid}`);
    dispatch(setId({ ids: bookid }));
    dispatch(storeChapterDetails({ data: {} }));
    dispatch(setCreateChapter({ data: {} }));
  };
  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}></div>

            <div className={createnewbookStyle.chapter_one_section}>
              <div className={createnewbookStyle.mybook_top_section}>
                <h3>Edit Book</h3>
              </div>
              <div className={createnewbookStyle.book_details_from}>
                <Form noValidate onSubmit={handleeditbooks}>
                  <>
                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="12">
                        <Form.Label>Book Title</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter your book title"
                          defaultValue="Enter your book title"
                          name="title"
                          value={formData.title}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>

                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="12">
                        <Form.Label>Book Subtitle</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter your book subtitle"
                          defaultValue="Enter your book subtitle"
                          name="subtitle"
                          value={formData.subtitle}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="12">
                        <Form.Label>Book Description</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter your book description"
                          defaultValue="Enter your book description"
                          name="description"
                          value={formData.description}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>

                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="6">
                        <Form.Label>Book Genre</Form.Label>
                        <Form.Select
                          required
                          defaultValue=""
                          name="genre"
                          value={formData.genre}
                          onChange={handleInputChange}
                        >
                          <option value="Fiction">Fiction</option>
                          <option value="Non-Fiction">Non-Fiction</option>
                          <option value="Educational">Educational</option>
                          <option value="Biography">Biography</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </>

                  <>
                    <Row
                      className={`mb-24 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="6">
                        <Form.Label>Book Size</Form.Label>
                        <Form.Select
                          required
                          defaultValue=""
                          name="bookSize"
                          value={formData.bookSize}
                          onChange={handleInputChange}
                        >
                          <option value="A1">A1</option>
                          <option value="A2">A2</option>
                          <option value="A3">A3</option>
                          <option value="A4">A4</option>
                          <option value="A5">A5</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="6">
                        <Form.Label>binding Type</Form.Label>
                        <Form.Select
                          required
                          defaultValue=""
                          name="bindingType"
                          value={formData.bindingType}
                          onChange={handleInputChange}
                        >
                          <option value="Glossy">SoftBinding</option>
                          <option value="Matte">HardBinding</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="6">
                        <Form.Label>bookInterior</Form.Label>
                        <Form.Select
                          required
                          defaultValue=""
                          name="bookInterior"
                          value={formData.bookInterior}
                          onChange={handleInputChange}
                        >
                          <option value="Glossy">BlackAndWhite</option>
                          <option value="Matte">Color</option>
                        </Form.Select>
                      </Col>
                    </Row>

                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="6">
                        <Form.Label>Paper Type</Form.Label>
                        <Form.Select
                          required
                          defaultValue=""
                          name="paperType"
                          value={formData.paperType}
                          onChange={handleInputChange}
                        >
                          <option value="Glossy">Glossy Paper</option>
                          <option value="Matte">Matte Paper</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row
                      className={`mb-12 ${createnewbookStyle.book_details_select}`}
                    >
                      <Col md="6">
                        <Form.Label>bookCoverLamination</Form.Label>
                        <Form.Select
                          required
                          defaultValue=""
                          name="bookCoverLamination"
                          value={formData.bookCoverLamination}
                          onChange={handleInputChange}
                        >
                          <option value="Glossy">GlossFinish</option>
                          <option value="Matte">MattFinish</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </>

                  <Row
                    className={`mb-12 ${createnewbookStyle.save_draft_btns_section}`}
                  >
                    <Col md="6">
                      <Button
                        type="submit"
                        className={createnewbookStyle.finish_btn}
                        disabled={loading}
                        style={{
                          backgroundColor: loading ? "grey" : "0d6ef#d",
                          cursor: loading ? "not-allowed" : "pointer",
                          color: loading ? "white" : "black",
                          opacity: loading ? 0.5 : 1,
                          marginRight: "20px",
                        }}
                      >
                        {loading ? (
                          <div className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </Button>

                      <Button
                        style={{ marginRight: "20px" }}
                        onClick={handleeditchapter}
                        className={createnewbookStyle.finish_btn}
                      >
                        Edit Chapters
                      </Button>

                      <Button
                        onClick={handleback}
                        className={createnewbookStyle.finish_btn}
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Editbookpage;
