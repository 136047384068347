import {createSlice} from '@reduxjs/toolkit'


const initialState = {
    data : [],
    error : null,
    loading : false,
    chapterDescription:{}
}

export const createChapterSlice = createSlice({
    name : "createchapter",
    initialState,
    reducers :{
        setCreateChapter :(state , action) =>{
            state.data = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        },
        addChapter:(state,action)=>{
            state.data = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        },
        setCreatefromBook :(state , action) =>{
            state.data = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        },
        setDescription:(state , action) =>{
            state.chapterDescription = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        },
        removeChapter: (state, action) => {
            const chapterIdToRemove = action.payload;
           
            state.data = state.data.filter(chapter => chapter._id !== chapterIdToRemove);
        
    },
    resetCreateChapter(state) {
        state.data = [];
        state.loading = false;
        state.error = null;
      },
    }
})

export const {setCreateChapter,addChapter,removeChapter,setCreatefromBook,setDescription,resetCreateChapter} = createChapterSlice.actions
export default createChapterSlice.reducer;