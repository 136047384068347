import {createSlice} from '@reduxjs/toolkit'


const initialState = {
    data : [
        
    ],
    error : null,
    loading : false
}

export const frontDetailsSlice = createSlice({
    name : "frontdetails",
    initialState,
    reducers :{
        storeFrontDetails :(state , action) =>{
            state.data = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        },
        
    }
})

export const {storeFrontDetails} = frontDetailsSlice.actions
export default frontDetailsSlice.reducer;