import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import EmailIcon from "../../assets/images/email_icon.png";
import ForgotpasswordStyle from "../../assets/css/LogIn.module.css";
import { NavLink } from "react-router-dom";
import useApi from "../../hook/useApi";


function Forgotpassword() {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const { fetchData, error } = useApi();
  const [loading , setLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setLoading(true)
    try {
      await fetchData("POST", "api/forgot-password", { Email: email },null , null , ()=>{
        console.log("successfully")
      },'POST');
      setLoading(false)
    } catch (error) {
      console.log("error 404", error.message);
    }

    setValidated(true);
  };

  return (
    <>
      <div className={ForgotpasswordStyle.login_main}>
        <div className={ForgotpasswordStyle.login_page}>
          <h2>Forgot Password?</h2>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className={`mb-12 ${ForgotpasswordStyle.contactus_fild}`}>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="email"
                  placeholder="example@email.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className={ForgotpasswordStyle.email_icon}>
                  <a href="/">
                    <img src={EmailIcon} />
                  </a>
                </div>
                <Form.Control.Feedback>Email is required</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <div className={ForgotpasswordStyle.login_btn}>
              <Button type="submit"  disabled={loading} style={{ backgroundColor:loading?'grey':'0d6ef#d' ,
                    cursor:loading?'not-allowed':'pointer',
                    color : loading?'white':'black',
                    opacity:loading? 0.5 : 1
            }}>{loading?(  <div className="text-center">
           <div className="spinner-border" role="status">
           <span className="sr-only"></span>
           </div>
          </div>):("Forgot Password")}</Button>
            </div>
            <div className={ForgotpasswordStyle.have_an_account_text}>
              Have an account? <NavLink to="/Login">Log In</NavLink>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Forgotpassword;
