import {createSlice} from '@reduxjs/toolkit'


const initialState = {
    data : [],
    error : null,
    loading : false
}

export const frontmatterSlice = createSlice({
    name : "frontmatter",
    initialState,
    reducers :{
        setFrontMatter :(state , action) =>{
            state.data = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        },
        
    }
})

export const {setFrontMatter} = frontmatterSlice.actions
export default frontmatterSlice.reducer;



