import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../assets/css/viewbooks.css";
import Sidebar from "../components/common/Sidebar";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import useApi from "../hook/useApi";
import mybookStyle from "../assets/css/mybook.module.css";
import getstoreChapterDetails from "../redux/slice/chapterDetailsSlice";
import { useDispatch } from "react-redux";
function Viewbooks() {
  const { fetchData } = useApi();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const clickedbook = useSelector((state) => state.Viewbooks?.data?.title);
  console.log(clickedbook, "clickedbook");
  const params = useParams();
  const navigate = useNavigate();
  const [paragraph, setParagraph] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
useEffect(() => {
    const fetchChapterData = async () => {
      try {
        const response = await fetchData(
          "GET",
          `api/chapter-list/${params?.id}?page=1&limit=5`
        );
        console.log("API Response:", response);
        setParagraph(response?.data?.chapters);
        const allDescriptions = paragraph.flatMap((chapter) =>
          chapter.Paragraph.map((paragraph) => paragraph.description)
        );
        setDescriptions(allDescriptions);
        dispatch(getstoreChapterDetails(response));
      } catch (error) {
        console.error("Error fetching chapter data:", error);
      }
    };
    fetchChapterData();
  }, []);
  useEffect(() => {
    console.log("Updated paragraph:", paragraph);
  }, [paragraph]);

  const des = paragraph?.flatMap((item) => {
    return item?.Paragraph?.map((val) => val?.description);
  });
  return (
    <>
      <div
        style={{ display: "flex", marginTop: "20px" }}
        className="common-container"
      >
        <Sidebar />
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 className="booktitleviewmore">{clickedbook}</h3>
            <div className={mybookStyle.mybook_top_section}>
              <button
                onClick={() => navigate(`/mybooks`)}
                className={mybookStyle.add_to_new_book}
              >
                Back
              </button>
            </div>
          </div>
          {Array.isArray(paragraph) &&
            paragraph?.map((chapteritem, index) => {
              return (
                <div className="cointainerviewmore">
                  <h6 className="chaptername">{chapteritem?.title}</h6>
                  <div className="chapter-description">
                    <span className="description-field">
                      {chapteritem?.Paragraph?.map((item, idx) => (
                        <span className="description-text" key={idx}>
                          {item.description || " "}
                        </span>
                      ))}
                    </span>
                  </div>

                  <h6 className="chapterimage">
                    <div>
                      <img
                        style={{ maxWidth: "80%" }}
                        src={chapteritem?.FileUrl}
                      />
                    </div>
                  </h6>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default Viewbooks;
