import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import chapterformat from "../../assets/css/chapterformat.module.css";
import mybookStyle from "../../assets/css/mybook.module.css";
import { Tooltip } from "antd";
import { Button } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import download from "../../assets/images/downloadarrow.png";
import right from "../../assets/images/right.png";
import Card from "react-bootstrap/Card";
import { IoMdArrowDropdown } from "react-icons/io";
import useApi from "../../hook/useApi";
import { useDispatch, useSelector } from "react-redux";
import { setChapterFormat } from "../../redux/slice/ChapterFormatslice";
import { FcApproval } from "react-icons/fc";
import SideNavBar from "../Createbooksidebar/Createbooksidebar";
import SidebarStyle from "../../assets/css/Sidebar.module.css";
import { useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import notificationImage from "../../assets/images/notification-img.png";
import headerStyle from "../../assets/css/header.module.css";
import { useAuth } from "../../hook/Auth";
import manUser from "../../assets/images/man-user.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

function ChapterFormat() {
  const [opencarousel, setOpencarousel] = useState(false);
  const [selectstyle , setSelectstyle] = useState("")
  console.log(selectstyle , "selectstyle")
  const styledata = useSelector((state) => state?.ChapterFormat?.data);
  const { fetchData } = useApi();
  const dispatch = useDispatch();
 const params = useParams()
 const[loading, setLoading]=useState(false)
 const userDetailsdata = JSON.parse(
  localStorage.getItem("userDetails") || "{}"
);
const FirstName = userDetailsdata?.user?.FirstName;
  const LastName = userDetailsdata?.user?.LastName;
const auth = useAuth();
const navigate = useNavigate();
 console.log(params , "paramsbook")
  const handlecarousel = () => {
    setOpencarousel(!opencarousel);
  };
  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };
  const handleeditprofile = () => {
    navigate("/editprofilepage");
  };

  useEffect(() => {
    const chapterformat = async () => {
      setLoading(true)
      try {
        const formatresponse = await fetchData(
          "GET",
          "get-all-bookstyle",
          null,
          {
            "Content-Type": "application/json",
          },
          null,
          () => {},
          "GET",
          "https://ebook-admin.indapoint.org"
        );
        dispatch(
          setChapterFormat({
            data: formatresponse?.data,
            error: null,
            loading: false,
          })
        );
        setLoading(false)
      } catch (error) {
       
        console.log(error);
      }
    };
    chapterformat();
  }, []);
  
  const centerSlidePercentage = styledata ? 100 / styledata.length : 100;
  const handlestyle = (style)=>{
     setSelectstyle(style)
  }
  const handlesave = ()=>{
      const savestyle = async ( )=>{
        try{
          const response = await fetchData("PUT" , `api/${params?.id}/style`,

            {
                styleId: selectstyle?._id
            }, {}, null , ()=>{} , "PUT")
          
          console.log(response , "selectstyleresponse")
        }catch(error){
          console.log(error)
        }
      }
      savestyle();
  }
  const handlepreview = async (format) => {
     if(selectstyle){
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    window.open(`${API_BASE_URL}/api/download-book/${params?.id}?format=${format}`);
     }else{
      toast.error("please select style")
     }
  };
 
  return (
    <>
      <SideNavBar />
      <div className={SidebarStyle.book_section}>
          <div className={SidebarStyle.nav_section}>
            <div className={SidebarStyle.nav_item}>Styles</div>

            <div
              className="header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <img src={notificationImage} />
              <div
                className="avatar"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#ccc",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <img src={manUser} />
              </div>

              <div className="user-info" style={{ display: "inline-block" }}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    className={headerStyle.welcome}
                    style={{
                      marginRight: "10px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Welcome, {FirstName} {LastName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                      onClick={handleeditprofile}
                    >
                      Edit Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleLogout}
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Delete Account
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          
            <div>
    
        <Navbar variant="white" bg="white" expand="lg">
          <Container fluid style={{ marginLeft: "700px" , marginTop:"20px" }}>
            <Navbar.Brand className={chapterformat.changelayout}>
              <span style={{ color: "red", backgroundColor: "orange" }}>
                CHANGE
              </span>
              LAYOUT :
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-dark-example" />
            <Navbar.Collapse id="navbar-dark-example">
              <Nav>
                <Navbar.Brand
                
                  className={chapterformat.changelayout}
                >
                  BLANCO{" "}
                  <span onClick={handlecarousel}>
                    <IoMdArrowDropdown />
                  </span>
                </Navbar.Brand>

                <div className={mybookStyle.edit_icon}>
                  <Tooltip placement="top" title="Views">
                    <Button>
                      <img
                        src={download}
                        style={{ height: "25px", width: "25px" }}
                        alt="Download"
                      />
                    </Button>
                  </Tooltip>
                </div>
                <div className={mybookStyle.edit_icon} >
                  <Tooltip placement="top" title="Views">
                    <Button onClick={handlesave}>
                      <img
                        src={right}
                        style={{ height: "25px", width: "25px" }}
                        alt="Done"
                      />
                    </Button>
                  </Tooltip>
                </div>
                <Button
                  style={{
                    borderRadius: "5rem",
                    backgroundColor: "rgb(99, 209, 99)",
                    borderColor: "rgb(99, 209, 99)",
                  }}
                  onClick={()=>handlepreview("pdf")}
                >
                  PREVIEW & FINISH
                </Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      {loading ? (
              <div style={{ textAlign: "center", marginTop: "50px" }}>
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
      opencarousel && (
        <div style={{ width: "100%"  }}>
          <Carousel
            showArrows={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            infiniteLoop={false}
            emulateTouch={true}
            centerMode={true}
            centerSlidePercentage={centerSlidePercentage}
            selectedItem={0}
            interval={3000}
            transitionTime={500}
            swipeable={true}
            dynamicHeight={false}
            className="carousel-custom"
          >
            {styledata &&
              styledata.map((style, index) => (
                <Card
                  key={index}
                  style={{ width: "160px", height: "250px", marginTop: "30px" }}
                  className={chapterformat.card}
                  onClick={()=>handlestyle(style)}
                >
                  <Card.Body>
                    <Card.Title
                      className="ms-2 mt-1 text-muted"
                      style={{
                        fontFamily: style.cover_title_font,
                        fontSize: style.cover_title_size,
                        color: style.cover_title_color,
                      }}
                    >
                      Loren Ipsum
                    </Card.Title>
                    <Card.Text
                      className="ms-1 text-muted card-text"
                      style={{
                        textAlign: "justify",
                        fontFamily: style.body_font,
                        fontSize: style.body_size,
                        color: style.body_color,
                      }}
                    >
                      In the heart of the ancient city of Aramore, historian
                      Elara discovers a hidden manuscript revealing the secret
                      of the lost language of Luminar. As Elara deciphers the
                      cryptic text, she finds herself pursued by shadowy figures
                      intent on exploiting its secrets. Teaming up with rogue
                      archaeologist Kai, Elara embarks on a perilous journey
                      through forgotten temples and treacherous landscapes.
                    </Card.Text>
                    <span className={`${chapterformat.icon} ${style?._id === selectstyle?._id ? chapterformat.icontwo:""}`}>
                      <FcApproval />
                    </span>
                  </Card.Body>
                </Card>
              ))}
          </Carousel>
        </div>
      ))}
   
        </div>
   
   
    </>
  );
}

export default ChapterFormat;