import React, { useState } from "react";
import Sidebar from "../common/Sidebar";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import addbookdetails from "../../assets/css/addBookDetailsCopilot.module.css";
import aioptions from "../../assets/css/aioptions.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { storeChapterDetails } from "../../redux/slice/chapterDetailsSlice";
import useApi from "../../hook/useApi";
import { toast } from "react-toastify";
function CopilotChapterDetails() {
  const [chapters, setChapters] = useState([{ title: "", description: "", subtitle: "" }]);
  const { fetchData } = useApi();
  const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const userid = userData?.user?._id;
  const dispatch = useDispatch();
  const addbooktitledata = useSelector((state) => state?.AititleData?.data);
  const navigate = useNavigate();
  const params = useParams();
  const handleAddChapter = () => {
    setChapters([...chapters, { title: "", description: "", subtitle: "" }]) };
  const handleChange = (index, field, value) =>{
    const newChapters = [...chapters];
    newChapters[index][field] = value;
    setChapters(newChapters);
  };
  const handlemybook = async () => {
    let payload = [];
    addbooktitledata?.map((item) => {
      payload.push({
        title: item?.title,
        subtitle: item?.subtitle,
        description: item?.description,
        FileUrl: "",
        authorId: userid,
        bookId: params?.id,
      });
    });
    const responsepost = await fetchData(
      "POST",
      `api/create-chapter`,
      {
        chapters: [...payload],
      },
      null,
      null,
      () => {},
      "POST"
    );
    dispatch(storeChapterDetails());
    navigate("/mybooks");
  };
  const handlecancel = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}>
              <h2>Create Book with Co-pilot</h2>
              <p style={{ color: " #5E5E5E" }}>
                Let our AI technology assist you in generating a complete book
                effortlessly. Just provide some initial details, and our AI will
                handle the rest, delivering a ready-to-use book in minutes.
              </p>
            </div>
            <div className={addbookdetails.main_div}>
              <h3>Chapter Details</h3>
              {chapters.map((chapter, index) => (
                <div key={index} className={addbookdetails.cardmap_div}>
                  {Array.isArray(addbooktitledata) &&
                    addbooktitledata.map((item, index) => (
                      <div
                        className={addbookdetails.main_input_div}
                        style={{ marginTop: "30px" }}
                      >
                        <h4 className={addbookdetails.title}>
                          Chapter title {index + 1}
                        </h4>
                        <input
                          value={`${chapter.title} - ${item.title}`}
                          onChange={(e) =>
                            handleChange(index, "title", e.target.value)
                          }
                          type="text"
                          className={addbookdetails.input_box}
                          placeholder='"The Art of Resilience"'
                          readOnly
                        />
                        <h4
                          className={addbookdetails.title}
                          style={{ marginTop: "20px" }}
                        >
                          Description {index + 1}
                        </h4>
                        <textarea
                          className={addbookdetails.inputbox}
                          style={{ height: "150px" }}
                          value={`${chapter.description} - ${item?.description}`}
                          type="textarea"
                          onChange={(e) =>
                            handleChange(index, "description", e.target.value)
                          }
                          readOnly
                          placeholder='In the hidden corners of our world lies a realm forgotten by time, where ancient secrets and mystical creatures dwell. "Whispers of the Forgotten Realm" follows the journey of Elara, a young scholar who stumbles upon an enchanted artifact that unlocks the gateway to this mysterious land. blur.'
                        ></textarea>
                        <h4
                          className={addbookdetails.title}
                          style={{ marginTop: "20px" }}
                        >
                          Sub title {index + 1}
                        </h4>
                        <input
                          value={`${chapter.subtitle} - ${item.subtitle}`}
                          onChange={(e) =>
                            handleChange(index, "subtitle", e.target.value)
                          }
                          type="text"
                          className={addbookdetails.input_box}
                          placeholder='"Thriving in Adversity and Embracing Change"'
                          readOnly
                        />
                      </div>
                    ))}
                </div>
              ))}
              <Row
                className={`mb-12 ${createnewbookStyle.book_details_select}`}
              >
                <Col md-6 style={{ display: "flex", justifyContent: "end" }}>
                  <button
                    className={createnewbookStyle.button}
                    onClick={handleAddChapter}
                  >
                    <span style={{ fontSize: "x-large", marginRight: "4px" }}>
                      +
                    </span>
                    <span>Add Chapters</span>
                  </button>
                </Col>
              </Row>
              <div className={aioptions.buttonsdiv}>
                <button onClick={handlemybook} className={aioptions.nextbutton}>
                  Submit
                </button>
                <button
                  className={aioptions.canclebutton}
                  onClick={handlecancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CopilotChapterDetails;
