import React, { useState } from "react";
import Sidebar from "../common/Sidebar";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import AiLogo from "../../assets/images/Ai-logo.png";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setCreateBook } from "../../redux/slice/createBookSlice";
import { storeChapterDetails } from "../../redux/slice/chapterDetailsSlice";
import { setCreateChapter } from "../../redux/slice/createChapterSlice";
import useApi from "../../hook/useApi";
function OwnCreateTitle() {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    subtitle: Yup.string().required("Subtitle is required"),
  });
  const createbookwithaidata = useSelector(
    (state) => state?.createnewbookwithai?.data
  );
  const { fetchData } = useApi();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    subtitle: "",
  });
  const navigate = useNavigate();
  const params = useParams();
  const handlecancle = () => {
    navigate(-1);
  };

  const handlelogo = async (key) => {
    const imporovedata = await fetchData(
      "POST",
      `api/update-to-lowercase`,
      { paragraphDetail: formData[key] },
      null,
      null,
      () => {},
      "POST"
    );
    const splitData = imporovedata?.data;
    console.log(splitData, "splitData");

    setFormData({ ...formData, [key]: splitData });
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handlenext = async (event) => {
    event.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setValidated(true);
      let requestData = { ...formData, ...createbookwithaidata };
      const response = await fetchData(
        "POST",
        "api/create-book",
        requestData,
        {},
        setCreateBook
      );
      const bookid = response?.data?._id;

      if (bookid) {
        setLoading(false);
        navigate(`/addchaptertitle/${bookid}`);
        dispatch(storeChapterDetails({ data: {} }));
        dispatch(setCreateChapter({ data: {} }));
      } else {
        navigate(`/createchapter`);
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
        error.inner.forEach((e) => {
          toast.error(e.message);
        });
        setValidated(false);
        setLoading(false);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };
  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div>
              <h2>Add Book Details</h2>
              <p style={{ color: "grey" }}>
                Let our AI technology assist you in generating a complete book
                effortlessly. Just provide some initial details, and our AI will
                handle the rest, delivering a ready-to-use book in minutes.
              </p>
            </div>
            <div className={createnewbookStyle.chapterdetails_section}>
              <div>
                <h3>Book Details</h3>

                <div>
                  <Row
                    className={`mb-12 ${createnewbookStyle.book_details_select}`}
                  >
                    <Col md="12">
                      <Form.Label>Book Title</Form.Label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #e5e5e5",
                          borderRadius: "9px",
                        }}
                      >
                        <Form.Control
                          required
                          type="text"
                          placeholder=""
                          name="title"
                          value={formData.title}
                          onChange={handlechange}
                          className={createnewbookStyle.customformcontrol}
                          isInvalid={!!errors.title}
                        />
                        <button
                          className={createnewbookStyle.custombutton}
                          onClick={() => handlelogo("title")}
                        >
                          <img src={AiLogo} alt="AI Logo" />
                        </button>
                      </div>

                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row
                    className={`mb-12 ${createnewbookStyle.book_details_select}`}
                  >
                    <Col md="12">
                      <Form.Label>Description</Form.Label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #e5e5e5",
                          borderRadius: "9px",
                        }}
                      >
                        <Form.Control
                          required
                          as="textarea"
                          type="text"
                          rows={7}
                          style={{ border: "0px", height: "225px" }}
                          placeholder=""
                          name="description"
                          value={formData.description}
                          onChange={handlechange}
                          className={createnewbookStyle.customformcontrol}
                          isInvalid={!!errors.description}
                        />

                        <button
                          className={createnewbookStyle.custombutton}
                          style={{ marginBottom: "170px" }}
                          onClick={() => handlelogo("description")}
                        >
                          <img src={AiLogo} alt="AI Logo" />
                        </button>
                      </div>

                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row
                    className={`mb-12 ${createnewbookStyle.book_details_select}`}
                  >
                    <Col md="12">
                      <Form.Label>Subtitle</Form.Label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #e5e5e5",
                          borderRadius: "9px",
                        }}
                      >
                        <Form.Control
                          required
                          type="text"
                          placeholder=""
                          name="subtitle"
                          value={formData.subtitle}
                          onChange={handlechange}
                          className={createnewbookStyle.customformcontrol}
                          isInvalid={!!errors.subtitle}
                        />
                        <button
                          className={createnewbookStyle.custombutton}
                          onClick={() => handlelogo("subtitle")}
                        >
                          <img src={AiLogo} alt="AI Logo" />
                        </button>
                      </div>

                      <Form.Control.Feedback type="invalid">
                        {errors.subtitle}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </div>

                <Row
                  className={`mb-12 ${createnewbookStyle.book_details_select}`}
                >
                  <Col md-6>
                    <button
                      className={createnewbookStyle.autopilot_button_next}
                      onClick={handlenext}
                    >
                      Next
                    </button>
                    <button
                      className={createnewbookStyle.autopilot_button_cancel}
                      onClick={() => handlecancle()}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OwnCreateTitle;
