import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import contactStyle from "../../assets/css/contact.module.css";
import AddressIcon from "../../assets/images/address_icon.png";
import Contactinfo from "../../assets/images/contact_info.png";
import Livesupport from "../../assets/images/live_support.png";

function Contactus() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <>
      <div className={contactStyle.contact_map}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14687.734743642492!2d72.55596305!3d23.026206950000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1689231830862!5m2!1sen!2sin"
          width="100%"
          height="500"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className={contactStyle.contactus_container}>
        <div className={contactStyle.contact_top_section}>
          <h2>Get in touch</h2>
          <div className={contactStyle.contact_address}>
            <div className={contactStyle.contact_address_text}>
              <div className={contactStyle.address_icon}>
                <img src={AddressIcon} />
              </div>
              <strong>Our Address</strong>
              <p>
                1012 Peb Parkway, Mirpur 2 <br></br>Dhaka, Bangladesh
              </p>
            </div>
            <div className={contactStyle.contact_address_text}>
              <div className={contactStyle.address_icon}>
                <img src={Contactinfo} />
              </div>
              <strong>Contact Info</strong>
              <p>
                Open a chat or give us call at<br></br>{" "}
                <a href="/">310.841.5500</a>
              </p>
            </div>
            <div className={contactStyle.contact_address_text}>
              <div className={contactStyle.address_icon}>
                <img src={Livesupport} />
              </div>
              <strong>Live support</strong>
              <p>
                live chat service<br></br> <a href="/">example@email.com</a>
              </p>
            </div>
          </div>
        </div>
        <div className={contactStyle.contactus_form}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className={`mb-12 ${contactStyle.contactus_fild}`}>
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Name"
                  defaultValue="Name"
                />
                <Form.Control.Feedback>Name is required</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="example@email.com"
                  defaultValue="example@email.com"
                />
                <Form.Control.Feedback>Email is required</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className={`mb-12 ${contactStyle.contactus_fild}`}>
              <Form.Group as={Col} md="12" controlId="validationCustomSubject">
                <Form.Label>Subject (optional)</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Write about the subject here.."
                    aria-describedby="Write about the subject here.."
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose a Subject.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className={`mb-12 ${contactStyle.contactus_fild}`}>
              <Form.Group as={Col} md="12">
                <Form.Control
                  as="textarea"
                  placeholder="Your Message"
                  rows={3}
                />
              </Form.Group>
            </Row>
            <div className={contactStyle.send_message_btn}>
              <Button className="send_btn" type="submit">
                Send Message
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Contactus;
