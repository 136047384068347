// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.quill-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.reactquill {
  width: calc(50% - 20px); 
  margin-bottom: 50px; 
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 86%;
  margin: 0px;
  position: relative;
  border-radius: 0px 0px 5px 5px;
}
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  border-radius: 5px 5px 0px 0px;
}
.view-input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 21px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
 
  .reactquill {
    width: 100%; 
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/viewparagraph.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;AAChC;;;AAGA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;AAC1C;AACA;EACE,sBAAsB;EACtB,yCAAyC;EACzC,eAAe;EACf,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,8BAA8B;AAChC;AACA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,+DAA+D;EAC/D,YAAY;EACZ,8BAA8B;AAChC;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,oBAAoB;EACpB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,wCAAwC;AAC1C;;AAEA;;EAEE;IACE,WAAW;EACb;AACF","sourcesContent":["\n.quill-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n\n.reactquill {\n  width: calc(50% - 20px); \n  margin-bottom: 50px; \n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n.ql-container {\n  box-sizing: border-box;\n  font-family: Helvetica, Arial, sans-serif;\n  font-size: 13px;\n  height: 86%;\n  margin: 0px;\n  position: relative;\n  border-radius: 0px 0px 5px 5px;\n}\n.ql-toolbar.ql-snow {\n  border: 1px solid #ccc;\n  box-sizing: border-box;\n  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;\n  padding: 8px;\n  border-radius: 5px 5px 0px 0px;\n}\n.view-input {\n  position: relative;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: stretch;\n  width: 100%;\n  height: 40px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  margin-left: 21px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n@media (max-width: 768px) {\n \n  .reactquill {\n    width: 100%; \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
