import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/images/common-logo.png";
import headerStyle from "../../assets/css/header.module.css";
import footerStyle from "../../assets/css/footer.module.css";
import Spinner from "react-bootstrap/Spinner";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/slice/signupauthSlice";
import manUser from "../../assets/images/man-user.png";
import notificationImage from "../../assets/images/notification-img.png";
import vector from "../../assets/images/vector.png";
import { useAuth } from "../../hook/Auth";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [showDropdown, setShowDropdown] = useState(false);
  const authstate = useSelector(selectAuth);
  const userDetailsdata = JSON.parse(
    localStorage.getItem("userDetails") || "{}"
  );
  const FirstName = userDetailsdata?.user?.FirstName;
  
  const LastName = userDetailsdata?.user?.LastName;
  console.log(userDetailsdata, "userdetailsdata");
  const username = userDetailsdata?.user?.Email;
  console.log(username, "usernamedata");
  const auth = useAuth();
  const navigate = useNavigate();
  const { isloggin, user } = authstate;

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };
  const handleeditprofile = () => {
    navigate("/editprofilepage");
  };
  const isHomePage = location.pathname === "/";

  return (
    <div className={headerStyle.common_header}>
      <div className={headerStyle.header_container}>
        <div className={headerStyle.header_main}>
          <div className={headerStyle.logo}>
            <NavLink to="/dashboard">
              <img src={logo} />
            </NavLink>
          </div>
          <div className={headerStyle.header_menu}>
            <Navbar expand="lg" className={headerStyle.bg_body_tertiary}>
              <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className={`${headerStyle.link_wrapper} "me-auto" `}>
                    <NavLink to="/dashboard">Home</NavLink>
                    <NavLink to="/services">Services</NavLink>
                    <NavLink to="/dashboard">Packages</NavLink>
                    <NavLink to="/">About Us</NavLink>
                    <NavLink to="/ContactUs">Contact Us</NavLink>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
          {userDetailsdata?.token ? (
            <div
              className="header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <img src={notificationImage} />
              <div
                className="avatar"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#ccc",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <img src={manUser} />
              </div>

              <div className="user-info" style={{ display: "inline-block" }}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    className={headerStyle.welcome}
                    style={{
                      marginRight: "10px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Welcome, {FirstName}  {LastName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                      onClick={handleeditprofile}
                    >
                      Edit Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleLogout}
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="/dashboard"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Delete Account
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ) : (
            <div className={headerStyle.header_login_singup}>
              <NavLink to="/Login">Login</NavLink>
              <NavLink to="/SignUp">/Sign up</NavLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
