// import React from "react";
// import { Navbar, Nav } from "react-bootstrap";
// import createnewbookStyle from "../../assets/css/createnewbook.module.css";


// class HeaderBar extends React.Component {
//   render() {
//     return (
//       <div className={createnewbookStyle.topnav}>
//         <Navbar
//           fixed="top"
//           expand="lg"
//           bg="dark"
//           variant="light"
//           className="topnav"
//         >
//           <Navbar.Brand href=""></Navbar.Brand>
//         </Navbar>
//       </div>
//     );
//   }
// }

// export default HeaderBar;
import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom"; // Assuming you are using React Router for navigation
import createnewbookStyle from "../../assets/css/createnewbook.module.css";

function HeaderBar(){
    return (
      <div className={createnewbookStyle.topnav}>
        <Navbar expand="lg" bg="light" variant="light">
         
            
          <Navbar.Brand href="#">My Website</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href="#">Home</Nav.Link>
          <Nav.Link href="#">About</Nav.Link>
          <Nav.Link href="#">Services</Nav.Link>
          <Nav.Link href="#">Contact</Nav.Link>
        </Nav>
        </Navbar>
      </div>
    );
  }


export default HeaderBar;
