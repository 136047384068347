import {createSlice} from '@reduxjs/toolkit'


const initialState = {
    data : [],
    error : null,
    loading : false
}

export const editUserSlice = createSlice({
    name : "edituser",
    initialState,
    reducers :{
        setEditeUser :(state , action) =>{
            state.data = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        }
    }
})

export const {setEditeUser} = editUserSlice.actions
export default editUserSlice.reducer;