import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import TooltipIcon from "../../assets/images/tooltip_icon.png";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import { Stepper, Step } from "react-form-stepper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Sidebar from "../common/Sidebar";

function WritingInterface() {
  const [validated, setValidated] = useState(false);
  const [value, setValue] = useState("");
  const text = <span>Plot Summary</span>;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}>
              <h2>Create New Book</h2>
              <p>Create a book & start adding chapters and characters</p>
            </div>
            <div className={createnewbookStyle.stepper_section}>
              <Stepper activeStep={1} disabledColor={""}>
                <Step
                  label="Book Details"
                  className={createnewbookStyle.step_active}
                />
                <Step
                  label="Main Characters"
                  className={createnewbookStyle.step_common}
                />
                <Step
                  label="Setting"
                  className={createnewbookStyle.step_common}
                />
                <Step
                  label="Plot Summary"
                  className={createnewbookStyle.step_common}
                />
                <Step
                  label="Writing Preferences"
                  className={createnewbookStyle.step_common}
                />
              </Stepper>
            </div>
            <div className={createnewbookStyle.book_details_from_section}>
              <h2>Writing Interface</h2>
              <div className={createnewbookStyle.book_details_from}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row
                    className={`mb-12 ${createnewbookStyle.book_details_select}`}
                  >
                    <Col md="12">
                      <Form.Label>Editing Tools</Form.Label>
                      <ReactQuill
                        theme="snow"
                        value={value}
                        onChange={setValue}
                        style={{
                          height: 120,
                        }}
                      />
                    </Col>
                  </Row>
                  <div className={createnewbookStyle.save_draft_btns_section}>
                    <div className={createnewbookStyle.next_cancel_btns}>
                      <Button
                        type="submit"
                        className={createnewbookStyle.finish_btn}
                      >
                        Save Draft
                      </Button>
                      <Button
                        type="submit"
                        className={createnewbookStyle.finish_btn}
                      >
                        Export as PDF
                      </Button>
                      <Button
                        type="submit"
                        className={createnewbookStyle.finish_btn}
                      >
                        Export as ePub
                      </Button>
                      <Button
                        type="submit"
                        className={createnewbookStyle.next_btn}
                      >
                        Export as docx
                      </Button>
                      <Button
                        type="submit"
                        className={createnewbookStyle.cancel_btn}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WritingInterface;
