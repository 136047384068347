import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import editprofileStyle from "../../assets/css/editprofile.module.css";
import { useSelector } from "react-redux";
import Sidebar from "../common/Sidebar";
import useApi from "../../hook/useApi";
import { setEditeUser } from "../../redux/slice/edituserSlice";
import { useNavigate } from "react-router-dom";
function Editprofile() {
  const {  fetchData } = useApi();
  const navigate = useNavigate("")
  const user = useSelector((state) => state?.login?.users?.data?.user);
 const userDetailsdata = JSON.parse(
    localStorage.getItem("userDetails") || "{}"
  );
  const FirstNames = userDetailsdata?.user?.FirstName;
  const LastNames = userDetailsdata?.user?.LastName;
  const Emails = userDetailsdata?.user?.Email;
  const userid = userDetailsdata?.user?._id;
  const [loading , setLoading] = useState(false)
  const [form, setForm] = useState({
    firstName: FirstNames,
    lastName: LastNames,
    email: Emails,
    // Description: "",
  });

  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

 

  const handleSubmit = async (event) => {
    event.preventDefault();

  
    setLoading(true)
    const response = await fetchData( 
      "PUT",
      `api/update-user/${userid}`,
      {
        FirstName:form?.firstName,
        LastName:form?.lastName,
        Email:form?.email,
      
      },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetailsdata.token}`
          
        
      },
      setEditeUser,
      ()=>{
        console.log("sucessfull")
      }, 'PUT'
    );

    console.log(response, "response");
    let oldUserData = JSON.parse(localStorage.getItem("userDetails")||"{}")
    if(response?.data){
      let newUserData = {...oldUserData}
      newUserData.user = response?.data
      localStorage.setItem("userDetails", JSON.stringify(newUserData || {}))
    }

    setValidated(true);
    setLoading(false)
  };
  const handlecancle = ()=>{
    navigate("/dashboard")
  }

  return (
    <>
      <div className="common-container">
        <div className={editprofileStyle.change_password_page}>
          <Sidebar />
          <div className={editprofileStyle.change_password_right}>
            <div className={editprofileStyle.mybook_top_section}>
              <h2>Edit Profile</h2>
            </div>
            <div className={editprofileStyle.editprofile_from}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className={`mb-12 ${editprofileStyle.book_details_fild}`}>
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                     
                      value={form.firstName}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      First Name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                    
                      value={form.lastName}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Last Name is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className={`mb-12 ${editprofileStyle.book_details_fild}`}>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustomSubject"
                  >
                    <Form.Label>Email</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name="email"
                        type="text"
                        placeholder="example@email.com"
                        value={form.email}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please choose an Email.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className={`mb-12 ${editprofileStyle.book_details_fild}`}>
                  <Form.Label>Description</Form.Label>
                  <Form.Group as={Col} md="12">
                    <Form.Control
                      name="description"
                      as="textarea"
                      placeholder="Enter text here..."
                      value={user?.description ? user.description : ""}
                      onChange={handleChange}
                      rows={3}
                    />
                  </Form.Group>
                </Row>
                <div className={editprofileStyle.submit_cancel_btn}>
                  <Button className="submit_btn" type="submit"  disabled={loading} 
                  style={{ backgroundColor: loading?'grey':'#1A3D73' ,
                    cursor: loading?'not-allowed':'pointer',
                    color : loading?'white':'black',
                    opacity:loading? 0.5 : 1
                    }}>
                  {loading?(  <div className="text-center">
           <div className="spinner-border" role="status">
           <span className="sr-only"></span>
           </div>
          </div>):("Save")}
                  </Button>
                  <Button className="cancel_btn" type="button" onClick={handlecancle}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Editprofile;
