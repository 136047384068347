import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  users: [],
  loading: false,
  error: null,
};


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload?.data;
      state.loading = action.payload?.loading;
      state.error =  action.payload?.error;
    },
  },
});


export const { setUsers } = userSlice.actions;

export default userSlice.reducer;
