import { useState, createContext, useContext, useEffect } from "react";
import useApi from "./useApi";
import { setLogin } from "../redux/slice/loginSlice";
import { setsignupauth } from "../redux/slice/signupauthSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({ });
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  console.log(user, "userss");
  const { fetchData, data, error, loading } = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const data = localStorage.getItem("userDetails");

    if (data) {
      const userdata = JSON.parse(data || "{}");
      setUser(userdata);
    }
  }, []);

  var login = (userData, setLoading) => {
    setLoading(true);
    const response = fetchData(
      "POST",
      "api/login-user",
      userData,
      {
        "Content-Type": "application/json",
      },
      setLogin,
      () => {},
      "POST"
    )
      .then((res) => {
        localStorage.setItem("userDetails", JSON.stringify(res.data));
        setUser(res.data);
        console.log(res.data, "response loginn");
        setLoading(false);
        dispatch(setsignupauth());
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err, "errrr");
        setLoading(false);
        if (err?.response) {
          const data = err?.response;
          const errmessage = data?.data?.message;
        } else {
          console.log(err);
        }
      });
  };

  const logout = () => {
    localStorage.removeItem("userDetails");
    console.log("logout....");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  return useContext(AuthContext);
};
