import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import "../../assets/css/viewparagraph.css";
import useApi from "../../hook/useApi";
import SideNavBar from "../Createbooksidebar/Createbooksidebar";
import { storeChapterDetails } from "../../redux/slice/chapterDetailsSlice";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import mybookStyle from "../../assets/css/mybook.module.css";
import Spinner from "react-bootstrap/Spinner";

export default function ViewParagraph() {
  const dispatch = useDispatch();
  const selectedChapter = useSelector((state) => state?.chapterDetails?.data);
  const chapterId = useSelector((state) => state?.chapterDetails?.data?._id);
  const userData = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const userid = userData?.user?._id;
  const bookdetail = useSelector((state) => state.editbooks?.data);
  const bookid = bookdetail._id;
  localStorage.setItem("bookid", JSON.stringify(bookid));
  const storedBookId = localStorage.getItem("bookid");
  const parsedBookId = storedBookId ? JSON.parse(storedBookId) : null;
  const [paragraph, setParagraph] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const { fetchData } = useApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imloading, setImloading] = useState(false);
  const [deloading, setDeloading] = useState(false);
  const [editors, setEditors] = useState([""]);
  const bookids = useSelector((state) => state?.ids?.ids);
  const para = useSelector((state) => state?.chapterDetails?.data?.description);
  const [open, setOpen] = useState(false);
  const[deleteIndex,setDeleteIndex]=useState(null)
  const [improveIndex, setimproveIndex]=useState(null)
  const [paragraphIndex, setParagraphIndex] = useState(null);
  const handlechange = (e, index) => {
    const newEditors = [...editors];
    newEditors[index] = e?.target?.value;
    setEditors(newEditors);
  };

  useEffect(() => {
    const fetchChapterData = async () => {
      setLoading(true)
      try {
        const response = await fetchData("GET", `api/paragraph/${chapterId}`);
        console.log("Fetched chapter data:", response);
        setParagraph(response.data.data);
        console.log("id", response?.data?.data?._id);
        console.log("paragraph", paragraph);
      } catch (error) {
        setLoading(false)
        console.error("Error fetching chapter data:", error);
      }
    };

    fetchChapterData();
  }, []);

  const handleSubmit = async (index) => {
    if (editors[index]) {
      if (currentIndex < paragraph.length) {
        try {
          setImloading(true);
          const currentParagraph = paragraph[index];
          const response = await fetchData("POST", `api/update-to-lowercase`, {
            paragraphDetail: currentParagraph.description?.ops[0]?.insert,
          });
          toast.success(`Paragraph ${index} edited successfully`);
          setCurrentIndex(index);
          const updatedParagraphs = [...paragraph];
          let currentDescription = {
            _id: currentParagraph?._id,
            description: currentParagraph?.description,
          };
          currentDescription.description.ops[0].insert = response.data;
          updatedParagraphs[index] = currentDescription;
          setParagraph(updatedParagraphs);
          let oldSelectedChapters = { ...selectedChapter };
          dispatch(
            storeChapterDetails({
              data: {
                ...oldSelectedChapters,
                description: updatedParagraphs,
              },
              error: null,
              loading: false,
            })
          );
          setEditors("");
          setImloading(false);
        } catch (error) {
          console.error("Error updating paragraph:", error);
          if (error.response) {
            console.log("Error response:", error.response.data);
          }
          console.log(`Failed to update paragraph ${index}`);
        }
      }
    } else {
      toast.error(`please type request in input box`);
    }
  };

 

  const handleUpdate = async (index) => {
    if (currentIndex < paragraph.length) {
      try {
        setLoading(true);
        console.log(paragraph, "paragraphadasadasdasasd");

        const currentParagraph = paragraph[index];
        console.log(currentParagraph, "currentParagraph");
        const response = await fetchData(
          "PUT",
          `api/update-paragraph/${currentParagraph._id}`,
          {
            description: currentParagraph.description?.ops[0]?.insert,
            chapterId: chapterId,
            authorId: userid,
          }
        );
        console.log(`Paragraph ${currentIndex} updated successfully`);
        toast.success(`Paragraph ${index} updated successfully`);
        setCurrentIndex(currentIndex);
        const updatedParagraphs = [...paragraph];
        updatedParagraphs[currentIndex] = response.data;
        setParagraph(updatedParagraphs);

        let oldSelectedChapters = { ...selectedChapter };
        let des = paragraph?.flatMap((para) => {
          const { description } = para || {};
          const { ops } = description || {};
          const { insert } = ops?.[0] || {};
          return insert ? [insert] : [];
        });
        console.log(des, "dessssssssssssssssssssss");
        dispatch(
          storeChapterDetails({
            data: {
              ...oldSelectedChapters,
              description: des?.join("\n"),
            },
            error: null,
            loading: false,
          })
        );
        setOpen(false);
        console.log(`update paragraph ${index}`);
        setLoading(false);
      } catch (error) {
        console.error("Error updating paragraph:", error);
        if (error.response) {
          console.log("Error response:", error.response.data);
        }
        console.log(`Failed to update paragraph ${index}`);
      }
    }
  };
  const handleDelete = async (paragraphId) => {
    setDeloading(true);
    try {
      setDeloading(true);
      const response = await fetchData("DELETE", `api/delete-paragraph`, {
        paragraphIds: [paragraphId],
      });
      console.log("Paragraph deleted successfully", response);

      const updatedParagraphs = paragraph.filter((p) => p._id !== paragraphId);
      setParagraph(updatedParagraphs);

      await fetchChapterData();
      toast.success(`Paragraph deleted successfully`);
      setDeloading(false);
    } catch (error) {
      console.error("Error deleting paragraph:", error);
    } finally {
      setDeloading(false);
    }
  };

  const fetchChapterData = async () => {
    try {
      const response = await fetchData("GET", `api/paragraph/${chapterId}`);
      console.log("Fetched chapter data:", response);
      setParagraph(response.data.data);

      const descriptions = response.data.data.map(
        (para) => para.description?.ops[0]?.insert
      );
      let oldSelectedChapters = { ...selectedChapter };

      dispatch(
        storeChapterDetails({
          data: {
            ...oldSelectedChapters,
            description: descriptions.join("\n"),
          },
          error: null,
          loading: false,
        })
      );
    } catch (error) {
      console.error("Error fetching chapter data:", error);
    }
  };

  const handleback = () => {
    if (Array.isArray(para)) {
      setOpen(true);
    } else {
      const bookIdToUse = bookids || parsedBookId;
      navigate(`/createchapter/${bookIdToUse}/${chapterId}`);
    }
  };
  const closeModal = () => {
    fetchChapterData();
    const oldSelectedChapters = { ...selectedChapter };

    const des = para.map((para) => para?.description);
    dispatch(
      storeChapterDetails({
        data: {
          ...oldSelectedChapters,
          description: des?.join("\n"),
        },
      })
    );
    setOpen(false);
  };

  return (
    <>
      <div>
        <SideNavBar />
        <Button
          style={{
            position: "sticky",
            top: "2px",
            left: "1164px",
            margin: "10px",
            zIndex: "1000",
          }}
          onClick={handleback}
        >
          Back
        </Button>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div
            className="quill-container"
            style={{ marginLeft: "209px", marginRight: "50px" }}
          >
            {Array.isArray(paragraph) &&
              paragraph?.length > 0 &&
              paragraph?.map((chapteritem, index) => (
                <div key={index}>
                  <ReactQuill
                    className="reactquill"
                    theme="snow"
                    style={{ height: 300, width: "881px" }}
                    value={chapteritem?.description}
                    onChange={(content, delta, source, editor) => {
                      setParagraph((prevParagraphs) => {
                        const updatedParagraphs = [...prevParagraphs];
                        updatedParagraphs[index] = {
                          ...updatedParagraphs[index],
                          description: editor.getContents(),
                        };
                        return updatedParagraphs;
                      });
                    }}
                  />
                  <div style={{ display: "flex" }}>
                    <input
                      className="view-input"
                      value={[editors[index]]}
                      onChange={(e) => handlechange(e, index)}
                    />
                    <Button
                      onClick={() => {
                        setimproveIndex(chapteritem._id);
                        handleSubmit(index);
                      }}
                      style={{ margin: "0px -22px 0px 22px" }}
                      className="ml-5"
                    >
                      {imloading && chapteritem._id === improveIndex ? (
                        <div className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                          </div>
                        </div>
                      ) : (
                        "Improve"
                      )}
                    </Button>
                  </div>
                  <Button
                    style={{ marginLeft: "23px", marginTop: "10px" }}
                    onClick={() => {
                      setParagraphIndex(chapteritem._id);
                      handleUpdate(index);
                    }}
                  >
                    {loading && chapteritem._id === paragraphIndex ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>
                    ) : (
                      "Save"
                    )}
                  </Button>
                  <Button
                    style={{ marginLeft: "23px", marginTop: "10px" }}
                    onClick={() => {
                      setDeleteIndex(chapteritem._id);
                      handleDelete(chapteritem._id);
                    }}
                  >
                    {deloading && chapteritem._id === deleteIndex ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>
                    ) : (
                      "Delete"
                    )}
                  </Button>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Modal show={open} onHide={() => setOpen(false)} centered>
        <div className={mybookStyle.popup_content_box}>
          <div className={mybookStyle.popup_text_content}>
            Are you sure you want to save or discard the changes?
          </div>
          <div className={mybookStyle.popup_btns}>
            <Button
              className={mybookStyle.popup_ok_btn}
              variant="secondary"
              onClick={(e) => handleUpdate(currentIndex)}
            >
              {" "}
              {loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              ) : (
                "Save"
              )}
            </Button>
            <Button
              className={mybookStyle.popup_cancel_btn}
              variant="primary"
              onClick={closeModal}
            >
              {" "}
              Discard
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
