import Card from "react-bootstrap/Card";
import React, { useState } from "react";
import commoncard from "../../assets/css/Commoncard.module.css";

function CommonCard({ isradioshow, height, width,marginBottom,children,active, callBack=()=>{}}, name) {
  const [isChecked, setIsChecked] = useState(false);
 
  const handleMouseEnter = () => {
    setIsChecked(true);
  };

  const handleMouseLeave = () => {
    setIsChecked(false);
  };
  return (
    <>
      <Card
        className={`${commoncard.cardone} ${active ? commoncard.cardone_active: ""}`}
        style={{ width: width, height: height,marginBottom:marginBottom }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={(e, name)=>callBack(e, name)}
      >
        <Card.Body>
          {isradioshow && (
            <Card.Title>
              <input
                type="radio"
                className={commoncard.radio}
                checked={active || isChecked}
              />
            </Card.Title>
          )}
          <Card.Text>
          
            {children}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default CommonCard;
