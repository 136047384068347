import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const Collobratorslist = createSlice({
  name: 'collobratorslist',
  initialState,
  reducers: {
    setcollobratorslist: (state, action) => {
      state.data = action.payload?.data; 
      state.loading = action.payload?.loading;
      state.error = action.payload?.error; 
    },
  },
});

export default Collobratorslist.reducer;
export const { setcollobratorslist } = Collobratorslist.actions