import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    data : [],
    loading : false,
    error : null,
}

export const storeendmattersdetails = createSlice({
    name : "storeendmattersdetails",
    initialState,
    reducers:{
        setstoreendmattersdetails : (state , action)=>{
            state.data = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error = action.payload?.error;
        },
    }
    
})

export default storeendmattersdetails.reducer
export const { setstoreendmattersdetails , deleteEndMatter} = storeendmattersdetails.actions