import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import ContinueGoogle from "../../assets/images/continue_google.png";
import ContinueFacebook from "../../assets/images/continue_facebook.png";
import EmailIcon from "../../assets/images/email_icon.png";
import ViewIcon from "../../assets/images/view_icon.png";
import LoginStyle from "../../assets/css/LogIn.module.css";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../hook/Auth";
import { googleProvider, facebookProvider } from "../../firebaseAuth/firebaseconfig";
import { toast } from "react-toastify";
import { getAuth, signInWithPopup } from "firebase/auth";

function Login() {
  const [validated, setValidated] = useState({ email: false, password: false });
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [eyepassword, setEyepassword] = useState("password");
  const [touchedFields, setTouchedFields] = useState({ email: false, password: false });
  
  const auth = useAuth();
  const firbaseAuth = getAuth();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setTouchedFields({ ...touchedFields, email: true });
    setValidated({ ...validated, email: e.target.value !== "" });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setTouchedFields({ ...touchedFields, password: true });
    setValidated({ ...validated, password: e.target.value !== "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validated.email || !validated.password) {
      return;
    }

    await auth?.login({ Email, Password }, setLoading);
    
  };

  const handleEyePassword = () => {
   
    setEyepassword((prevstate) => (prevstate === "password" ? "text" : "password"));

   
  };

  const loginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(firbaseAuth, googleProvider);
      localStorage.setItem("email", result.user.email);
      auth.setUser(result);
      console.log(result, " google result");
      // navigate("/dashboard");
      console.log("navigate");
      return result;
    } catch (error) {
      console.log(error, "error google");
    }
  };
  
  const loginWithFacebook = () => {
    return signInWithPopup(firbaseAuth, facebookProvider)
      .then((result) => {
        localStorage.setItem("email", result.user.email);
        auth.setUser(result);
        // navigate("/dashboard");
        console.log(result , "facebook result");
        return result;
        
      })
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential'){
          toast.error('Account already exists with different credential');
        } else {
          console.log(error.message);
        }
        return error.message;
      });
  };

  return (
    <>
      <div className={LoginStyle.login_main}>
        <div className={LoginStyle.login_page}>
          <h2>Log In</h2>
          <Form noValidate validated={false} onSubmit={handleSubmit}>
            <Row className={`mb-12 ${LoginStyle.contactus_fild}`}>
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="example@email.com"
                  defaultValue="example@email.com"
                  name="email"
                  value={Email}
                  onChange={handleEmailChange}
                  isInvalid={touchedFields.email && !validated.email}
                />
                <Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className={`mb-12 ${LoginStyle.contactus_fild}`}>
              <Form.Group as={Col} md="12" controlId="validationCustom04">
                <Form.Label>Password*</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={eyepassword}
                    placeholder="Enter your password"
                    required
                    name="password"
                    value={Password}
                    onChange={handlePasswordChange}
                    isInvalid={touchedFields.password && !validated.password}
                  />
                  <Button onClick={()=>handleEyePassword()} variant="outline-secondary" id="button-addon2">
                    <img src={ViewIcon} alt="View Password" />
                  </Button>
                  <Form.Control.Feedback type="invalid">Password is required</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            <div className={LoginStyle.login_btn}>
              <Button
                type="submit"
                disabled={loading}
                style={{
                  backgroundColor: loading ? "grey" : "#1A3D73",
                  cursor: loading ? "not-allowed" : "pointer",
                  // color: loading ? "white" : "black",
                  opacity: loading ? 0.5 : 1,
                }}
              >
                {loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  "Login"
                )}
              </Button>
            </div>
          </Form>
          <div className={LoginStyle.login_or_text}>
            <span>Or</span>
          </div>
          <div className={LoginStyle.continue_google_facebook}>
            <div className={LoginStyle.continue_google} onClick={loginWithGoogle}>
              <img src={ContinueGoogle} alt="Continue with Google" />
            </div>
            <div className={LoginStyle.continue_facebook} onClick={ loginWithFacebook}>
              <img src={ContinueFacebook} alt="Continue with Facebook" />
            </div>
          </div>
          <div className={LoginStyle.have_an_account_text}>
            Have an account? <NavLink to="/SignUp">Sign up</NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
