import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Col, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import manUser from "../../assets/images/man-user.png";
import SidebarStyle from "../../assets/css/Sidebar.module.css";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import SideNavBar from "../Createbooksidebar/Createbooksidebar";
import useApi from "../../hook/useApi";
import { setFrontMatter } from "../../redux/slice/frontMatterSlice";
import { storeFrontDetails } from "../../redux/slice/frontDetailSlice";
import { useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import notificationImage from "../../assets/images/notification-img.png";
import headerStyle from "../../assets/css/header.module.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hook/Auth";
import { toast } from "react-toastify";
export default function FrontMatter() {
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();
  const userDetailsdata = JSON.parse(
    localStorage.getItem("userDetails") || "{}"
  );
  const authorid = userDetailsdata?.user?._id;
  const params = useParams();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const frontdata = useSelector((state) => state?.frontdetails);
  const bookId = useSelector((state) => state.createbook.data?.data?._id);
  const frontmatter = useSelector((state) => state?.frontmatter?.data);
  const matterDetails = useSelector((state) => state?.frontdetails?.data || {});
  const { fetchData } = useApi();
  const [loadingpage, setLoadingPage] = useState(false);
  const [formData, setFormData] = useState({
    Title: matterDetails[0]?.Title || "",
    description: matterDetails[0]?.description || "",
  });
  const id =
    frontdata && frontdata?.data && frontdata?.data?.length > 0
      ? frontdata?.data[0]?._id
      : null;

  const FirstName = userDetailsdata?.user?.FirstName;
  const LastName = userDetailsdata?.user?.LastName;

  useEffect(() => {
    setLoadingPage(true);
    setTimeout(() => {
      setFormData({
        Title: matterDetails[0]?.Title || "",
        description: matterDetails[0]?.description || "",
      });
      setLoadingPage(false);
    }, 100);
  }, [matterDetails]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (id) {
        const putresponse = await fetchData(
          "PUT",
          `api/update/front-back-matter/${id}`,
          {
            ...formData,
            BookId: params?.id,
            authorId: authorid,
            type: "frontmatter",
          },
          {
            "Content-Type": "application/json",
          },
          setFrontMatter,
          () => {},
          "PUT"
        );
      } else {
        const response = await fetchData(
          "POST",
          "api/front-back/matter",
          {
            ...formData,
            type: "frontmatter",
            BookId: params?.id,
            authorId: authorid,
          },
          {
            "Content-Type": "application/json",
          },
          setFrontMatter,
          () => {},
          "POST"
        );
        if (response?.data?._id) {
          let data = [{ ...response?.data }];
          dispatch(
            storeFrontDetails({
              data: data,
              error: null,
              loading: false,
            })
          );
        }
      }
      const bookdata = await fetchData(
        "GET",
        `api/book/${params?.id}`,
        null,
        {},
        null,
        () => {},
        "GET"
      );
      dispatch(
        setFrontMatter({
          data: bookdata.frontmatter,
          loading: false,
          error: null,
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };
  const handleeditprofile = () => {
    navigate("/editprofilepage");
  };
  const handleeditbookhistory = (e) => {
    if (!params?.frontchapterid) {
      toast.error("please select frontmatter");
    } else {
      navigate(`/frontmatterhistory/${params?.id}/${params?.frontchapterid}`);
    }
  };
  const handleformat = () => {
    navigate(`/chapterformat/${params?.id}`);
  };

  return (
    <div>
      <SideNavBar />
      <div className={SidebarStyle.book_section}>
        <div className={SidebarStyle.nav_section}>
          <div className={SidebarStyle.nav_item}>FrontMatter</div>

          <div
            className="header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 20px",
            }}
          >
            <img src={notificationImage} />
            <div
              className="avatar"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "#ccc",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            >
              <img src={manUser} />
            </div>

            <div className="user-info" style={{ display: "inline-block" }}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  className={headerStyle.welcome}
                  style={{
                    marginRight: "10px",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Welcome, {FirstName} {LastName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#"
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontFamily: "sans-serif",
                    }}
                    onClick={handleeditprofile}
                  >
                    Edit Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={handleLogout}
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Logout
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/dashboard"
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Dashboard
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Delete Account
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {loadingpage ? (
          <div>{"Loading..."}</div>
        ) : (
          <>
            <div className={createnewbookStyle.book_details_from}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row
                  className={`mb-12 ${createnewbookStyle.book_details_select}`}
                >
                  <Col md="10" style={{ marginLeft: "50px" }}>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter your book title"
                      name="Title"
                      value={formData.Title}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                <Row
                  className={`mb-12 ${createnewbookStyle.book_details_select}`}
                >
                  <Col md="10" style={{ marginLeft: "50px", marginTop: "0px" }}>
                    <ReactQuill
                      theme="snow"
                      value={formData.description}
                      onChange={(value) =>
                        setFormData({ ...formData, description: value })
                      }
                      style={{ height: 200 }}
                    />
                  </Col>
                </Row>

                <div
                  style={{ marginBottom: "50px", marginLeft: "50px" }}
                  className={createnewbookStyle.save_draft_btns_section}
                >
                  <div className={createnewbookStyle.next_cancel_btns}>
                    <Button
                      type="submit"
                      className={createnewbookStyle.finish_btn}
                      disabled={loading}
                    >
                      {loading ? (
                        <div className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                          </div>
                        </div>
                      ) : (
                        "Save Draft"
                      )}
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleeditbookhistory(e);
                      }}
                      type="submit"
                      className={createnewbookStyle.finish_btn}
                    >
                      View Edit History
                    </Button>
                    <Button
                      onClick={handleformat}
                      style={{ backgroundColor: "green", color: "white" }}
                      type="submit"
                      className={createnewbookStyle.finish_btn}
                    >
                      Finish
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
