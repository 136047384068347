import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    clickBook : null
}

export const  bookdetailSlice = createSlice({
    name : "bookdetail",
    initialState ,
    reducers : {
        setClickBook : (state , action) =>{
            state.clickBook = action.payload
        }
    }
})

export default bookdetailSlice.reducer
export const {setClickBook} = bookdetailSlice.actions