import {createSlice} from '@reduxjs/toolkit'


const initialState = {
    data : [],
    selectedBook:[],
    error : null,
    loading : false
}

export const bookListSlice = createSlice({
    name : "latestbook",
    initialState,
    reducers :{
        setLatestBook :(state , action) =>{
            state.data = action.payload?.data;
            state.loading = action.payload?.loading;
            state.error =  action.payload?.error;
        },
        setSelectedBook: (state, action) => {
            state.selectedBook = action.payload;
          },
    }
})

export const {setLatestBook,setSelectedBook} = bookListSlice.actions
export default bookListSlice.reducer;