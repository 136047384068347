import React from "react";
import Sidebar from "../common/Sidebar";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import aioptions from '../../assets/css/aioptions.module.css'
import CommonCard from "../common/CommonCard";
import vector from "../../assets/images/vector.png"
import backtoschool from "../../assets/images/Back To School Outline Cerated Outline (1).png"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
function Aioption(){
  const [optionsai , setOptionsai] = useState({
    auto_pilot : false,
    co_pilot : false,
  })
const navigate = useNavigate()
const params = useParams()
 const handlenextbutton = ()=>{
    if(optionsai?.auto_pilot){
      navigate(`/autopilot`)
    }else if(optionsai?.co_pilot){
      navigate(`/createbookwithcopilot`)
    }else{
      toast.error("Please select one option");
    }
  }
  const handlecanclebutton = ()=> {
    navigate(-1)
   }
  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}>
              <h2>Create New Book</h2>
              <p>Create a book & start adding chapters and characters</p>
            </div>
            <div className={aioptions.cards}>
             <CommonCard isradioshow={true}  height="192px" width="220px"
               active={optionsai?.auto_pilot} 
               callBack={(e,val)=>{
                 setOptionsai({
                     auto_pilot : true,
                     co_pilot : false,
                 })
               }}
               name = "auto_pilot"
             >
             <div className={aioptions.imageparadiv}>
               <img src={vector} className={aioptions.imagecard} />
              <p className={aioptions.paragraph}>AutoPilot</p>
            </div>
             </CommonCard>
               <CommonCard  isradioshow={true}  height="192px" width="220px" 
                active={optionsai?.co_pilot} 
                name="co_pilot"
                callBack={(e , val)=>{
                   setOptionsai({
                    auto_pilot:false,
                    co_pilot:true,
                   })
                }}
             >
             <div className={aioptions.imageparadiv}>
               <img src={backtoschool} className={aioptions.imagecard} />
              <p className={aioptions.paragraph}>Co-pilot</p>
            </div>
              </CommonCard> 
             </div>
             <div className={aioptions.uldiv}>
                <ul className={aioptions.ul}>
                    <li>Begin by entering the main idea or theme of your book. This foundational step sets the direction for your project.</li>
                    <li>you need help brainstorming a title, the AI can suggest a list of potential titles based on your topic. It can also refine an existing title to make it more compelling and marketable.</li>
                    <li>View an organized display of your chapters along with their titles, subtitles, and brief outlines. This visualization helps you understand the flow and structure of your book.</li>
                    <li>The Co-Pilot Mode is an innovative feature designed to assist authors in the book creation process by leveraging AI technology. This mode provides a step-by-step guide, making it easier to transform your ideas into a structured and polished book.</li>
                </ul>
             </div>
             <div className={aioptions.buttonsdiv}>
                <button className={aioptions.nextbutton} onClick={()=>handlenextbutton()}>Next</button>
                <button className={aioptions.canclebutton} onClick={()=>handlecanclebutton()}>Cancel</button>
             </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Aioption;
