import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  error: null,
  loading: false,
};

export const createnewbookwithai = createSlice({
  name: "createnewbookwithai",
  initialState,
  reducers: {
    setcreatenewbookwithai: (state, action) => {
      state.data = action.payload?.data;
      state.loading = action.payload?.loading;
      state.error = action.payload?.error;
    },
  },
});

export const { setcreatenewbookwithai } = createnewbookwithai.actions;
export default createnewbookwithai.reducer;
