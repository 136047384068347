import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import sentinvitationsStyle from "../../assets/css/sentinvitations.module.css";
import Sidebar from "../common/Sidebar";
import { setmyBook } from "../../redux/slice/myBookSlice";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../hook/useApi";
import { useNavigate } from "react-router-dom";
function SentInvitation() {
  const [validated, setValidated] = useState(false);
  const [books, setBooks] = useState([]);
  const [author, setAuthor] = useState([]);
  const [bookid, setBookid] = useState("");
  const [selectauthor, setSelectauthor] = useState([]);
  const [role, setRole] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedbook, setSelectedbook] = useState(useSelector((state) => state?.myBookSlice?.user?.books));
  const { fetchData } = useApi();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const userDetailsdata = JSON.parse(
    localStorage.getItem("userDetails") || "{}"
  );
  const userID = userDetailsdata?.user?._id;
  const navigate = useNavigate()
  const payload = {
    bookId: bookid,
    collaboratorEmail: selectauthor,
    Role: role,
    message: message,
  };
 const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false){
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    setLoading(true);
    try {
      const response = await fetchData(
        "POST",
        `api/send-invitation`,
        payload,
        {
          "Content-Type": "application/json",
        },
        null,
        () => {
          console.log("seninviattion");
        },
        "POST"
      );
      const data = await response.json();
      console.log(data);
       }catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {
    const fetchmyBooks = async () => {
      try {
        const response = await fetchData(
          "GET",
          `api/book-list/${userID}?page=1&limit=5`
        );
      setBooks(response?.books);
      } catch (error) {
        console.log(error, "errorrr");
      }
    };
    fetchmyBooks();

    const fetchmyauthor = async () => {
      try {
        const response = await fetchData("GET", `api/users-list`, null, {
          Authorization: `Bearer ${userDetails?.token}`,
        });
        const emails = response.data.map((user) => user.Email);
        setAuthor(emails);
      } catch (error) {
        console.log(error, "error");
      }
    };
    fetchmyauthor();
  }, []);

  const handlebookselect = (bookTitle, bookId) => {
    dispatch(setmyBook(bookTitle));
    setSelectedbook(bookTitle);
    setBookid(bookId);
  };

  const handleauthor = (event) => {
    setSelectauthor(event.target.value);
  };
  const handlerole = (e) => {
    setRole(e.target.value);
  };
  const handlemessage = (e) => {
    setMessage(e.target.value);
  };
 const handlecancle= ()=>{
   navigate(-1)
 }
  return (
    <>
      <div className="common-container">
        <div className={sentinvitationsStyle.change_password_page}>
          <Sidebar />
          <div className={sentinvitationsStyle.change_password_right}>
            <div className={sentinvitationsStyle.mybook_top_section}>
              <h2>Sent Invitations</h2>
            </div>
            <div className={sentinvitationsStyle.invitation_from}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row
                  className={`mb-12 ${sentinvitationsStyle.book_details_select}`}
                >
                  <Col md="6">
                    <Form.Label>Select Book</Form.Label>
                    <Form.Select
                      aria-label="Fiction"
                  
                      onChange={(e) => {
                        const selectedBookId = e.target.value;
                        const selectedBookTitle = e.target.options[e.target.selectedIndex].text;
                        handlebookselect(selectedBookTitle, selectedBookId);
                      }}
                    >
                      <option key={"default"} value="">
                        Select Book
                      </option>
                      {Array.isArray &&
                        books.map((book) => (
                          <option
                             key={book.id}
                             value={book._id}
                             selected={book._id === selectedbook}
                          >
                            {book.title}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col md="6">
                    <Form.Label>Select Author</Form.Label>
                    <Form.Select onChange={handleauthor} value={selectauthor}>
                    <option key={"default"} value="">
                    Select Author
                      </option>
                      {Array.isArray(author) &&
                        author?.map((item) => item && <option>{item}</option>)}
                    </Form.Select>
                  </Col>
                </Row>
                <Row
                  className={`mb-12 ${sentinvitationsStyle.book_details_select}`}
                >
                  <Col md="12">
                    <Form.Label>Role Assign</Form.Label>
                    <Form.Select
                      aria-label="Fiction"
                      value={role}
                      onChange={handlerole}
                    >
                      <option>Fiction</option>
                      <option value="Editor">Editor</option>
                      <option value="Read">Read</option>
                      <option value="Write">Write</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className={`mb-12 ${sentinvitationsStyle.contactus_fild}`}>
                  <Form.Label>Message</Form.Label>
                  <Form.Group as={Col} md="12">
                    <Form.Control
                      as="textarea"
                      placeholder="Your Message"
                      rows={3}
                      value={message}
                      onChange={handlemessage}
                    />
                  </Form.Group>
                </Row>
                <div className={sentinvitationsStyle.submit_cancel_btn}>
                  <Button
                    className="submit_btn"
                    type="submit"
                    disabled={loading}
                    style={{
                      backgroundColor: loading ? "grey" : "#f15c5c",
                      cursor: loading ? "not-allowed" : "pointer",
                      color: loading ? "white" : "black",
                      opacity: loading ? 0.5 : 1,
                    }}
                  >
                    {loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                  <Button className="cancel_btn" type="button" onClick={handlecancle}>
                    Cancel
                  </Button>
                </div>
              
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SentInvitation