import mybookStyle from "../../assets/css/mybook.module.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "../common/Sidebar";
import createnewbookStyle from "../../assets/css/createnewbook.module.css";
import addbookdetails from "../../assets/css/addBookDetailsCopilot.module.css";
import CommonCard from "../common/CommonCard";
import aioptions from "../../assets/css/aioptions.module.css";
import Button from "react-bootstrap/Button";
import Envelop from "../../assets/images/Envelop.png";
import { Modal } from "react-bootstrap";
import useApi from "../../hook/useApi";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

function Autopilotone(){
  const aidata = useSelector((state) => state?.aisuggetionSlice?.data?.data);
  const bookinfo = useSelector((state) =>state?.createnewbookwithai?.data);
  const { fetchData } = useApi();
  const [aisuggetion, setAisuggetion] = useState(null); 
  const navigate = useNavigate();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const[loading, setLoading]=useState(false)

  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    description: "",
  });

  const handleMyBook = () =>{
    navigate("/mybooks");
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (aidata && aisuggetion !== null){
      setFormData({
        ...aidata[aisuggetion],
      });
    }
  }, [aidata, aisuggetion]);

  const handleBack = () =>{
    setAisuggetion(null);
    navigate(`/autopilot`);
  };

  const toggleSuggestion = (index) =>{
    setAisuggetion(index);
  };

  const handleSubmit = async () =>{
    if (aisuggetion !== null) {
      setLoading(true)
      const combinedData = {
        ...bookinfo,
        ...aidata[aisuggetion],
      };
     const response = await fetchData("POST", `api/create-book`, combinedData);
     setLoading(false)
         setOpen(true);
    } else {
      toast.error("Please select a suggestion");
    }
  };

  return (
    <>
      <div className="common-container">
        <div className={createnewbookStyle.change_password_page}>
          <Sidebar />
          <div className={createnewbookStyle.change_password_right}>
            <div className={createnewbookStyle.book_details_top_title}>
              <h2>Create Book with Autopilot</h2>
              <p style={{ color: " #5E5E5E" }}>
                Let our AI technology assist you in generating a complete book
                effortlessly. Just provide some initial details, and our AI will
                handle the rest, delivering a ready-to-use book in minutes.
              </p>
            </div>
            <div className={addbookdetails.mainframe}>
              <h4 className={addbookdetails.h4tag}>AI Suggestions</h4>
            <div className={addbookdetails.cardmapdiv}>
                {Array.isArray(aidata) &&
                  aidata.map((data, index) => (
                    <CommonCard
                      key={index}
                      isradioshow={true}
                      width="auto"
                      height="200px"
                      marginBottom="20px"
                      active={aisuggetion === index}
                      callBack={() => toggleSuggestion(index)}
                      className=""
                    >
                      <div className={addbookdetails.maininputdiv}>
                        <h4 className={addbookdetails.title}>Book title</h4>
                        <input
                        readOnly
                          required
                          name="title"
                          value={aisuggetion === index ? formData.title : data.title}
                          onChange={handleInputChange}
                          type="text"
                          className={addbookdetails.inputbox}
                        ></input>

                        <h4
                          className={addbookdetails.title}
                          style={{ marginTop: "20px" }}
                        >
                          Sub title
                        </h4>
                        <input
                          required
                          readOnly
                          name="subtitle"
                          value={aisuggetion === index ? formData.subtitle : data.subtitle}
                          onChange={handleInputChange}
                          type="text"
                          className={addbookdetails.inputbox}
                        ></input>
                      </div>
                    </CommonCard>
                  ))}
                <div className={aioptions.buttonsdiv}>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className={aioptions.nextbutton}
                  >
                   {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
                  </button>
                  <button
                    onClick={handleBack}
                    className={aioptions.canclebutton}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={open} onHide={() => setOpen(false)} centered>
        <div className={mybookStyle.copilot_popup_content}>
          <img src={Envelop} alt="Envelop" />
          <div className={mybookStyle.copilot_pop_up_text}>
            Your book is being created. You will receive an email once it is
            ready.
          </div>
          <div className={mybookStyle.copilot_popup_btns}>
            <Button
              onClick={handleMyBook}
              className={mybookStyle.popup_ok_btn}
              variant="secondary"
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Autopilotone;

